import classNames from 'classnames';
import { Button } from 'components';
import React, { useState } from 'react';
import { AiOutlineLineChart, AiOutlineRight } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { FaLongArrowAltRight, FaArrowRight } from 'react-icons/fa';
import { X } from 'react-feather';

import { createAlertsData } from '../constants/alertsConstants';
import { alertType, alertsList } from '../utils';

const AlertsCreateList = () => {
  const [selectedAlert, setSelectedAlert] = useState('Metric');
  const navigate = useNavigate();

  const handleHover = (alert: string) => {
    setSelectedAlert(alert);
  };

  const onClickNewAlert = (alert: string) => {
    if (alert === 'Logs') {
      navigate(
        `/alerts/create?ruleType=${encodeURI(
          JSON.stringify({ value: 'logs' }),
        )}`,
      );
    } else if (alert === 'APM') {
      navigate(
        `/alerts/create?ruleType=${encodeURI(
          JSON.stringify({ value: 'apm' }),
        )}`,
      );
    } else {
      navigate(
        `/alerts/create?ruleType=${encodeURI(
          JSON.stringify({ value: 'metrics' }),
        )}&alertType=${encodeURI(
          JSON.stringify({ value: `${alertType[alert]}` }),
        )}`,
      );
    }
  };

  return (
    <div className="px-12 py-4">
      <div className="flex justify-between pt-2">
        <div className="ml-2 flex items-center">
          <div
            onClick={() => {
              navigate('/alerts');
              close();
            }}
            className="alerts__create__back__text"
          >
            Alert List
          </div>
          <AiOutlineRight size={18} className="mx-2" />
          <div className="alerts__create__text">Create Alert</div>
        </div>
        <Button
          onClick={() => {
            navigate('/alerts');
            close();
          }}
          className="mr-5"
          variant="ghost-destructive"
          size="icon"
        >
          <X className="" size={28} />
        </Button>
      </div>
      <div className="flex ">
        <div
          className="alerts__list-container min-w-[260px]"
          data-testid="alerts-list-container"
        >
          {alertsList.map((item) => (
            <div
              className={classNames({
                'flex cursor-pointer items-center justify-between py-2 px-2 hover:bg-interaction-primary hover:text-white text-primary font-semibold':
                  true,
                'bg-interaction-primary text-white rounded-sm':
                  selectedAlert === item,
              })}
              key={item}
              onClick={() => onClickNewAlert(item)}
              onMouseEnter={() => handleHover(item)}
            >
              <div className="flex">
                <AiOutlineLineChart size={24} />
                <div className="text-sm">{item}</div>
              </div>
              <div className="flex">
                {selectedAlert === item && <FaArrowRight size={16} />}
              </div>
            </div>
          ))}
        </div>
        <div className="flex-3 flex min-w-[500px] flex-col items-center px-20">
          <div className="flex max-w-lg items-center justify-center py-8 text-3xl font-bold">
            {createAlertsData[selectedAlert] &&
              createAlertsData[selectedAlert].heading}
          </div>
          <div className="alerts__create__image__container">
            <img
              src={`${
                createAlertsData[selectedAlert] &&
                createAlertsData[selectedAlert].leftImageUrl
              }`}
              className="alerts__image__style"
            />
            <FaLongArrowAltRight
              size={50}
              className="alert__create__right__arrow"
            />
            <div className="alerts__kloudfuse__logo">K</div>
            <FaLongArrowAltRight
              size={50}
              className="alert__create__right__arrow"
            />
            <img
              src={`${
                createAlertsData[selectedAlert] &&
                createAlertsData[selectedAlert].rightImageUrl
              }`}
              className="alerts__image__style"
            />
          </div>

          <div className="alerts__create__box__subheading">
            {createAlertsData[selectedAlert] &&
              createAlertsData[selectedAlert].description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertsCreateList;
