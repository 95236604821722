import { AutocompleteV2 } from 'components';
import { useRequest } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { IoIosWarning } from 'react-icons/io';
import useDebouncedEffect from 'use-debounced-effect';
import useRumState from './hooks/useRumState';
import getApplicationFilter from './requests/getApplicationFilter';

const RumApplicationFilter = ({
  rumState,
}: {
  rumState: ReturnType<typeof useRumState>;
}) => {
  const { applicationFilter, setApplicationFilter } = rumState;
  const applicationFilterRequest = useRequest(getApplicationFilter);

  useDebouncedEffect(
    () => {
      applicationFilterRequest.call();
    },
    {
      ignoreInitialCall: false,
      timeout: 100,
    },
    [],
  );

  const applicationFilterOptions = useMemo(() => {
    return applicationFilterRequest.result?.map((application) => ({
      label: application.name,
      value: application.id,
    }));
  }, [applicationFilterRequest.result]);

  useEffect(() => {
    if (
      applicationFilterRequest.result &&
      applicationFilterRequest.result.length &&
      !applicationFilter
    ) {
      rumState.setApplicationFilter(applicationFilterRequest.result[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationFilterRequest.result]);

  return (
    <div className="rum__application-filter ml-2.5 mb-2.5 h-auto flex-col items-stretch">
      <div className="button-group__item button-group__item--label min-h-6	">
        {applicationFilterRequest.error ? (
          <div className="flex gap-[4px] w-full justify-start pr-[14px]">
            <IoIosWarning
              className="overlay-message__icon-and-message__icon"
              size={16}
            />
            <div className="text-red-500">
              {applicationFilterRequest.error?.message}
            </div>
          </div>
        ) : (
          <>Application Filter</>
        )}
      </div>
      <AutocompleteV2
        className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value ms-2"
        isLoading={applicationFilterRequest.isLoading}
        onChange={(value) => {
          setApplicationFilter(value as string);
        }}
        options={applicationFilterOptions}
        value={applicationFilter}
      />
    </div>
  );
};

export default RumApplicationFilter;
