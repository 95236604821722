import dayjs from 'dayjs';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { IoIosWarning } from 'react-icons/io';
import { getStatusAndErrorBudget, transformSLOPromql } from 'utils';
import { useCreateSLOState } from '../hooks';
import { TooltipTrigger } from 'components/TooltipTrigger';
import SLOChart from './SLOChart';
import { promqlQuery } from 'requests';
import useRequest from 'hooks/useRequest';

const SLOCreateStatusInfo = ({
  createSLOState,
}: {
  createSLOState: ReturnType<typeof useCreateSLOState>;
}): ReactElement => {
  const { getFormValue, dryRunPromQls } = createSLOState;
  const objective = getFormValue('objective');
  const [statusAndErrorBudget, setStatusAndErrorBudget] = useState(null);
  const promqlQueryRequest = useRequest(promqlQuery, false);
  const promQls = useMemo(() => {
    if (
      !dryRunPromQls ||
      !dryRunPromQls?.sliBadEventsPromQuery ||
      !dryRunPromQls?.sliTotalEventsPromQuery
    )
      return [];
    const numeratorPromQls = transformSLOPromql({
      promql: dryRunPromQls?.sliBadEventsPromQuery,
      slo: dryRunPromQls,
    });

    const denominatorPromQls = transformSLOPromql({
      promql: dryRunPromQls?.sliTotalEventsPromQuery,
      slo: dryRunPromQls,
    });

    return [numeratorPromQls, denominatorPromQls];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dryRunPromQls]);

  const calculateBudget = async () => {
    if (
      !dryRunPromQls ||
      !dryRunPromQls?.sliBadEventsPromQuery ||
      !dryRunPromQls?.sliTotalEventsPromQuery
    )
      return;

    const endTimeUnix = dayjs().unix();
    const startTimeUnix = dayjs()
      .subtract(30 * 24 * 60 * 60, 'seconds')
      .unix();

    const promQlsForRequest = promQls.map((promQl) =>
      promQl.replace(/\[1d\]/g, '[30d]'),
    );
    const dataset = await promqlQueryRequest.call({
      date: { startTimeUnix, endTimeUnix },
      promqlQueries: promQlsForRequest,
    });
    const budget = getStatusAndErrorBudget(dataset, dryRunPromQls.objective);
    setStatusAndErrorBudget(budget);
  };

  useEffect(() => {
    calculateBudget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dryRunPromQls]);

  return (
    <div className="slo__create__result-info">
      {promQls.length === 2 && (
        <>
          <SLOChart
            promQl={`${promQls[1]}  -  ${promQls[0]}`}
            legendFormat={['Good']}
            stroke="#14a000"
          />
          <SLOChart
            promQl={promQls[0]}
            legendFormat={['Bad']}
            stroke="#9e0142"
          />
        </>
      )}
      <div className="slo__create__result-info__result">
        <div>
          <div className="slo__create__result-info__title">STATUS</div>
          <div className="slo__create__result-info__value">
            {statusAndErrorBudget ? (
              <div
                className="slo__create__result-info__value"
                style={{ color: statusAndErrorBudget.statusColor }}
              >
                {statusAndErrorBudget.status}
              </div>
            ) : (
              <TooltipTrigger
                className="slo__create__result-info__value-error"
                tooltip={
                  !objective ? 'Objective is required' : 'Status value is zero'
                }
              >
                <IoIosWarning />
              </TooltipTrigger>
            )}
          </div>
        </div>
        <div>
          <div className="slo__create__result-info__title">
            Remaining Error budget
          </div>
          {statusAndErrorBudget ? (
            <div
              className="slo__create__result-info__value"
              style={{ color: statusAndErrorBudget.errorBudgetColor }}
            >
              {statusAndErrorBudget.errorBudget}&nbsp;
            </div>
          ) : (
            <TooltipTrigger
              className="slo__create__result-info__value-error"
              tooltip={
                !objective ? 'Objective is required' : 'Error budget is zero'
              }
            >
              <IoIosWarning />
            </TooltipTrigger>
          )}
        </div>
      </div>
    </div>
  );
};

export default SLOCreateStatusInfo;
