import { FilterType, Operator, SelectedFacetValueFilter } from 'hooks';

type Args = {
  isServiceFromDatabasesList: boolean;
  property: string;
  serviceHash: string;
  value: string;
};

const getFiltersForTraces = ({
  isServiceFromDatabasesList,
  property,
  serviceHash,
  value,
}: Args): SelectedFacetValueFilter[] => {
  const serviceFacet = isServiceFromDatabasesList
    ? 'kf_database_service_hash'
    : 'service_hash';

  if (property === 'client_service_hash' || property === 'service_hash') {
    return [
      {
        type: FilterType.selectedFacetValue,
        value: {
          facet: serviceFacet,
          operator: Operator.equal,
          values: {
            [value]: 1,
          },
        },
      },
    ];
  }

  return [
    {
      type: FilterType.selectedFacetValue,
      value: {
        facet: serviceFacet,
        operator: Operator.equal,
        values: {
          [serviceHash]: 1,
        },
      },
    },
    {
      type: FilterType.selectedFacetValue,
      value: {
        facet: property,
        operator: Operator.equal,
        values: {
          [value]: 1,
        },
      },
    },
  ];
};

export default getFiltersForTraces;
