import type { AutocompleteOption, Focusable } from 'components';
import {
  AutocompleteV2,
  Button,
  Input,
  MultiselectV2,
  PopoverTriggerV2,
  TooltipTrigger,
  useToaster,
} from 'components';
import { useMetricsQueryStateV2 } from 'hooks';
import React, { ReactElement, useMemo, useRef } from 'react';
import { MdFunctions } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import {
  ExplorerQueryProps,
  FunctionName,
  VectorTypes,
} from 'types/MetricsQueryBuilder';
import {
  AGGREGATE_FUNCTIONS,
  AGGREGATE_OPTIONS,
  AGGREGATE_OPTIONS_COMPACT,
  functionsNames,
} from 'utils/MetricsQueryBuilder';

import MetricsQueryBuilderFunctionsPanel from './MetricsQueryBuilderFunctionsPanel';

const EXCLUDED_AGGREGATE_OPTIONS = ['count', 'stdvar', 'stddev', 'quantile'];

const getAggregateOptions = (paramValue: string): AutocompleteOption[] => {
  if (!EXCLUDED_AGGREGATE_OPTIONS.includes(paramValue)) {
    return AGGREGATE_OPTIONS_COMPACT;
  }

  const excludedOptions = AGGREGATE_OPTIONS.filter(
    (option) => option.value === paramValue,
  );

  return [...AGGREGATE_OPTIONS_COMPACT, ...excludedOptions];
};

const MetricsQueryBuilderFunctions = ({
  blockedFunctionsCategories = [],
  blockedFunctionsNames,
  query,
  queryIndex,
  metricsQueryState,
}: {
  blockedFunctionsCategories?: string[];
  blockedFunctionsNames?: Array<FunctionName>;
  query: ExplorerQueryProps;
  queryIndex: number;
  metricsQueryState: ReturnType<typeof useMetricsQueryStateV2>;
}): ReactElement => {
  const { addToast } = useToaster();
  const searchInputRef = useRef<Focusable | null>(null);
  const {
    addFunction,
    removeFunction,
    updateFunction,
    labelsList,
    loadLabelList,
  } = metricsQueryState;

  const matcher = `${query.metric}{}`;
  const labelOptions = useMemo(() => {
    if (!labelsList[matcher]) return { options: [], isLoading: false };

    const newOptions = labelsList[matcher].options.map((option) => ({
      ...option,
      value: option.label,
    }));

    return {
      options: newOptions,
      isLoading: labelsList[matcher].isLoading,
    };
  }, [matcher, labelsList]);

  const onLabelOpen = () => {
    if (labelOptions?.options?.length) {
      return;
    }
    loadLabelList([], query.metric, -1);
  };

  return (
    <>
      {query.functions.map((fn, fnIndex) => {
        return (
          <React.Fragment key={fnIndex}>
            <div className="button-group" key={fnIndex}>
              <div className="button-group__item button-group__item--unpadded">
                <div className="metrics__function-builder__item__name">
                  {AGGREGATE_FUNCTIONS.includes(fn.name)
                    ? 'aggregation'
                    : fn.name}
                </div>
                <div
                  className="metrics__function-builder__item__params"
                  data-testid="function-builder-params"
                >
                  {fn.params &&
                    fn.params.map((param, paramIndex) => {
                      if (param.type === 'text' || param.type === 'number') {
                        return (
                          <Input
                            className="metrics__function-builder__item__params__input"
                            key={paramIndex}
                            max={param.max}
                            min={param.min}
                            onChange={(val) => {
                              if (param.validator) {
                                const isValid = param.validator(val);
                                if (Boolean(isValid)) {
                                  addToast({ text: isValid, status: 'error' });
                                  return;
                                }
                              }
                              updateFunction(
                                queryIndex,
                                fnIndex,
                                paramIndex,
                                val,
                              );
                            }}
                            placeholder={param.name}
                            type={param.type}
                            value={param.value}
                            size="2"
                            step={param.step}
                            required={param.required}
                          />
                        );
                      }
                      if (param.type === 'select') {
                        return (
                          <AutocompleteV2
                            className="autocomplete-container--no-border autocomplete__fixed-height-30"
                            key={paramIndex}
                            onChange={(val) =>
                              updateFunction(
                                queryIndex,
                                fnIndex,
                                paramIndex,
                                val,
                              )
                            }
                            options={
                              AGGREGATE_FUNCTIONS.includes(fn.name)
                                ? getAggregateOptions(param.value)
                                : param.options
                            }
                            value={param.value}
                          />
                        );
                      }
                      if (param.type === 'multi-select') {
                        return (
                          <MultiselectV2
                            className="autocomplete-container--no-border autocomplete__fixed-height-30"
                            key={paramIndex}
                            onChange={(val) =>
                              updateFunction(
                                queryIndex,
                                fnIndex,
                                paramIndex,
                                val,
                              )
                            }
                            options={
                              AGGREGATE_FUNCTIONS.includes(fn.name)
                                ? labelOptions?.options
                                : param.options
                            }
                            value={param.value}
                            isLoading={labelOptions?.isLoading}
                            onMenuOpen={() =>
                              AGGREGATE_FUNCTIONS.includes(fn.name)
                                ? onLabelOpen()
                                : null
                            }
                          />
                        );
                      }
                    })}
                </div>
                <TooltipTrigger tooltip="Remove function">
                  <Button
                    className="metrics__function-builder__item__close h-full"
                    variant="ghost"
                    size="sm"
                  >
                    <IoMdClose
                      onClick={() => removeFunction(queryIndex, fnIndex)}
                      size={18}
                    />
                  </Button>
                </TooltipTrigger>
              </div>
            </div>
            <div className="search__button-group__divider">
              <div />
            </div>
          </React.Fragment>
        );
      })}
      <PopoverTriggerV2
        popover={({ close }) => (
          <MetricsQueryBuilderFunctionsPanel
            blockedFunctionsCategories={blockedFunctionsCategories}
            blockedFunctionsNames={blockedFunctionsNames}
            functionsNames={functionsNames}
            onFunctionClick={(
              functionName: string,
              vectorType: VectorTypes,
            ) => {
              addFunction(queryIndex, functionName, vectorType);
              close();
            }}
            searchInputRef={searchInputRef}
          />
        )}
        offsetY={-4}
      >
        <div className="button-group">
          <div
            ref={searchInputRef}
            className="button-group__item button-group__item--unpadded"
          >
            <TooltipTrigger className="h-full" tooltip="Add functions">
              <Button className="h-full" variant="icon" size="sm">
                <MdFunctions size={18} />
              </Button>
            </TooltipTrigger>
          </div>
        </div>
      </PopoverTriggerV2>
    </>
  );
};

export default MetricsQueryBuilderFunctions;
