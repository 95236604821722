import React from 'react';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import RumActionsWithErrorsTable from './RumActionsWithErrorsTable';
import RumErrorGroupTable from './RumErrorGroupTable';
import RumErrorsTopView from './RumErrorsTopView';
import RumErrorsByVersion from './RumErrorsByVersion';
import RumTotalErrors from './RumTotalErrors';

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};

const RumAnalyzeErrors = ({ rumPageState }: Props) => {
  return (
    <div className="rum-performance__errors_grid">
      <div className="rum-performance__errors-top">
        <div className="rum-performance__errors-item rum-performance__errors-item--top flex-1">
          <RumErrorsByVersion rumPageState={rumPageState} />
        </div>
        <div className="rum-performance__errors-item rum-performance__errors-item--top flex-1">
          <RumTotalErrors rumPageState={rumPageState} />
        </div>
        <div className="rum-performance__errors-item rum-performance__errors-item--top flex-1">
          <RumErrorsTopView
            rumPageState={rumPageState}
            className="rum-performance__errors-group"
          />
        </div>
      </div>
      <div className="rum-performance__errors-bottom">
        <div className="rum-performance__errors-item rum-performance__errors-item--bottom">
          <div className="rum__performance-subtitle">
            Top Actions Triggering Errors
          </div>
          <RumActionsWithErrorsTable rumPageState={rumPageState} />
        </div>
        <div className="rum-performance__errors-item rum-performance__errors-item--bottom">
          <div className="rum__performance-subtitle">Ongoing Issues</div>
          <RumErrorGroupTable rumState={rumPageState.rumState} onlyTop10 />
        </div>
      </div>
    </div>
  );
};

export default RumAnalyzeErrors;
