import { keys } from 'lodash';
import Highlighter from 'react-highlight-words';
import React, { useMemo, useState, useCallback } from 'react';

import { SearchablePropertyValueSelector } from 'components';
import { FunctionName, FunctionCategory, FunctionInfo } from 'types';
import { FunctionNamesProps, VectorTypes } from 'types/MetricsQueryBuilder';
import {
  getFilteredFunctionInfoByFunctionNameThenFunctionCategory,
  doesFunctionInfoMatchSearchTerm,
} from './utils';

const FunctionInfoPreview = ({
  functionInfo,
  wordToHighlight,
}: {
  functionInfo: FunctionInfo;
  wordToHighlight: string;
}) => {
  return (
    <>
      <div className="metrics__query-builder__functions-panel__item__category__title">
        <Highlighter
          textToHighlight={functionInfo.shortName}
          searchWords={[wordToHighlight]}
        />
      </div>
      <div className="metrics__query-builder__functions-panel__item__category__description">
        <Highlighter
          textToHighlight={functionInfo.description}
          searchWords={[wordToHighlight]}
        />
      </div>
      <div className="metrics__query-builder__functions-panel__item__category__description__additional">
        {
          <ul>
            <li>
              <Highlighter
                textToHighlight={functionInfo.algorithm || ''}
                searchWords={[wordToHighlight]}
              />
            </li>
            <li>
              {
                <Highlighter
                  textToHighlight={functionInfo.tolerance || ''}
                  searchWords={[wordToHighlight]}
                />
              }
            </li>
          </ul>
        }
      </div>
      <div className="metrics__query-builder__functions-panel__item__category__image">
        <img src={functionInfo.imageUrl} />
      </div>
    </>
  );
};

const EMPTY_ARRAY: [] = [];
const MetricsQueryBuilderFunctionsPanel = ({
  blockedFunctionsCategories = EMPTY_ARRAY,
  blockedFunctionsNames = EMPTY_ARRAY,
  functionsNames,
  onFunctionClick,
  searchInputRef,
}: {
  blockedFunctionsCategories?: Array<FunctionCategory>;
  blockedFunctionsNames?: Array<FunctionName>;
  functionsNames: FunctionNamesProps[];
  onFunctionClick: (
    functionName: FunctionName,
    vectorType: VectorTypes,
  ) => void;
  searchInputRef: React.MutableRefObject<HTMLInputElement>;
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredFunctionInfoByFunctionNameThenFunctionCategory = useMemo(
    () =>
      getFilteredFunctionInfoByFunctionNameThenFunctionCategory({
        functionInfos: functionsNames,
        searchTerm,
        blockedCategories: blockedFunctionsCategories,
        blockedNames: blockedFunctionsNames,
      }),
    [
      blockedFunctionsCategories,
      blockedFunctionsNames,
      functionsNames,
      searchTerm,
    ],
  );

  const getFunctionNamesForFunctionCategory = useCallback(
    (functionCategory: FunctionCategory): Array<FunctionName> =>
      keys(
        filteredFunctionInfoByFunctionNameThenFunctionCategory[
          functionCategory
        ],
      ),
    [filteredFunctionInfoByFunctionNameThenFunctionCategory],
  );

  const doesFunctionMatchSearchTerm = useCallback(
    (
      functionCategory: FunctionCategory,
      functionName: FunctionName,
      searchTerm: string,
    ): boolean =>
      doesFunctionInfoMatchSearchTerm(
        filteredFunctionInfoByFunctionNameThenFunctionCategory[
          functionCategory
        ][functionName],
        searchTerm,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredFunctionInfoByFunctionNameThenFunctionCategory, searchTerm],
  );

  const functionCategories: Array<FunctionCategory> = useMemo(
    () => keys(filteredFunctionInfoByFunctionNameThenFunctionCategory),
    [filteredFunctionInfoByFunctionNameThenFunctionCategory],
  );

  const renderPreview = useCallback(
    (functionCategory: FunctionCategory, functionName: FunctionName) => (
      <>
        {filteredFunctionInfoByFunctionNameThenFunctionCategory[
          functionCategory
        ]?.[functionName] && (
          <FunctionInfoPreview
            functionInfo={
              filteredFunctionInfoByFunctionNameThenFunctionCategory[
                functionCategory
              ][functionName]
            }
            wordToHighlight={searchTerm}
          />
        )}
      </>
    ),
    [filteredFunctionInfoByFunctionNameThenFunctionCategory, searchTerm],
  );

  const onFunctionSelect = useCallback(
    (functionCategory: FunctionCategory, functionName: FunctionName) => {
      const { shortName, vectorType } =
        filteredFunctionInfoByFunctionNameThenFunctionCategory[
          functionCategory
        ][functionName];
      onFunctionClick(shortName, vectorType);
    },
    [filteredFunctionInfoByFunctionNameThenFunctionCategory, onFunctionClick],
  );

  return (
    <div className="metrics__query-builder__functions-panel">
      <SearchablePropertyValueSelector
        properties={functionCategories}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        getValues={getFunctionNamesForFunctionCategory}
        renderPreview={renderPreview}
        onValueSelect={onFunctionSelect}
        doesMatchSearchTerm={doesFunctionMatchSearchTerm}
        renderPropertyLabel={(functionCategory: FunctionCategory) => (
          <Highlighter
            textToHighlight={functionCategory}
            searchWords={[searchTerm]}
          />
        )}
        renderValueLabel={(_, functionName) => (
          <Highlighter
            textToHighlight={functionName}
            searchWords={[searchTerm]}
          />
        )}
        searchInputRef={searchInputRef}
        shouldScrollValuesPaneToBottom={true}
      />
    </div>
  );
};

export default MetricsQueryBuilderFunctionsPanel;
