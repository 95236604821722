import { Table, useTableSort } from 'components/Table';
import React, { ReactElement } from 'react';

const AnalyticsChartTable = ({
  data,
  columns,
  doNotSplitKeysOfAnalyticTable,
}: {
  data: Record<string, unknown>[];
  columns: Array<{ key: string; label: string }>;
  doNotSplitKeysOfAnalyticTable?: boolean;
}): ReactElement => {
  const tableSort = useTableSort({ columns: columns || [], rows: data || [] });
  if (!data || !Array.isArray(data)) return null;

  return (
    <div className="table__analytics__container">
      <Table
        className="table__analytics"
        columns={columns}
        rows={data}
        doNotSplitKeysOfTable={doNotSplitKeysOfAnalyticTable}
        externalTableSort={tableSort}
        isSortingEnabled
      />
    </div>
  );
};

export default AnalyticsChartTable;
