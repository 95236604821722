import {
  Button,
  FiltersOnEnterArgs,
  Search,
  ShowSidebarTooltipButton,
  useLeftSidebarState,
} from 'components';
import {
  FacetRegexFilter,
  Filter,
  FilterType,
  Operator,
  SelectedRangeFilter,
  SelectedFacetValueFilter,
  useRequest,
  useSearches,
  useSearchFormulas,
} from 'hooks';
import { startCase } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { getFacetKey, getRange, parsePartialSearchQuery } from 'utils';
import { numericOperatorBitmap } from 'kfuse-constants';
import RumSearchVisualize from './RumSearchVisualize';
import useRumState from '../hooks/useRumState';
import isRangeFacet, { RangeFacetsMap } from '../utils/isRangeFacet';
import getTags from './getTags';
import RumQuerySearch from '../RumAnalytics/RumQuerySearch';
import RumSearchInputPanel from './RumSearchInputPanel';
import { RumErrorTab, RumEventType, RumTab } from '../types';
import classNames from 'classnames';
import { sortingEnabledColumns } from '../utils';

type Props = {
  leftSidebarState: ReturnType<typeof useLeftSidebarState>;
  placeholder: string;
  searches: ReturnType<typeof useSearches>['searches'];
  searchesFormulas?: ReturnType<typeof useSearchFormulas>;
  rumLabelNamesRequest: ReturnType<typeof useRequest>;
  rumState: ReturnType<typeof useRumState>;
  rumTab: RumTab;
};

const getOptions = (rumLabelNamesRequest: ReturnType<typeof useRequest>) => {
  if (!rumLabelNamesRequest.result) {
    return {
      groupByOptions: [] as { label: string; value: string }[],
      measureOptions: [] as { label: string; value: string }[],
    };
  }

  const labelOptions = [...((rumLabelNamesRequest.result as string[]) || [])]
    .sort()
    .map((label) => ({
      label: label,
      value: label,
    }));

  const groupByOptions = [
    { label: 'Everything', value: '*' },
    ...labelOptions.filter(
      (item) =>
        !isRangeFacet(item.value) &&
        sortingEnabledColumns[item.value as keyof typeof sortingEnabledColumns],
    ),
  ];
  const measureOptions = [
    { label: 'All ', value: null },
    ...labelOptions.filter(
      (item) =>
        sortingEnabledColumns[item.value as keyof typeof sortingEnabledColumns],
    ),
  ];

  return {
    groupByOptions,
    measureOptions,
  };
};

const RumSearch = ({
  leftSidebarState,
  placeholder,
  searches,
  searchesFormulas,
  rumLabelNamesRequest,
  rumState,
  rumTab,
}: Props) => {
  const labelNames: string[] = useMemo(
    () => ((rumLabelNamesRequest.result || []) as string[]).sort(),
    [rumLabelNamesRequest.result],
  );

  const {
    applicationFilter,
    dateState,
    eventType,
    errorTab,
    filtersState,
    idSearch,
    setErrorTab,
    setIdSearch,
    selectedFacetValuesByNameState,
  } = rumState;

  const [date] = dateState;

  const { groupByOptions, measureOptions } = useMemo(
    () => getOptions(rumLabelNamesRequest),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rumLabelNamesRequest.result],
  );

  const tags = useMemo(
    () =>
      getTags({
        filtersState,
        idSearch,
        setIdSearch,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rumState.state],
  );

  const onEnter = ({
    index,
    meta = {},
    replace,
    typed,
  }: FiltersOnEnterArgs) => {
    const parsedSearch = parsePartialSearchQuery(typed, false);
    if (parsedSearch) {
      const { facetName, operator, operatorIndex, value } = parsedSearch;

      const shouldReplace = replace && typeof index === 'number';
      const replaceFilter = (filter: Filter) => {
        filtersState.replaceByIndex(filter, index);
      };
      const applyFilter = shouldReplace ? replaceFilter : filtersState.add;
      if (facetName && operator) {
        if (operator === Operator.equal || operator === Operator.notEqual) {
          const facetValues = value
            .split(' OR ')
            .map((v) => v.trim())
            .filter((v) => v);

          const values = facetValues.reduce(
            (obj, facetValue) => ({
              ...obj,
              [facetValue]: 1,
            }),
            {},
          );

          const existingFilterWithSameFacetIndex = filtersState.state.findIndex(
            (filter) =>
              filter.type === FilterType.selectedFacetValue &&
              filter.value.facet === facetName,
          );

          const shouldReplaceExistingFilter =
            !replace && existingFilterWithSameFacetIndex > -1;

          const existingFilter = shouldReplaceExistingFilter
            ? (filtersState.state[
                existingFilterWithSameFacetIndex
              ] as SelectedFacetValueFilter)
            : null;

          const existingFilterValues =
            existingFilter && existingFilter.value.operator === operator
              ? existingFilter.value.values
              : {};

          const filter: SelectedFacetValueFilter = {
            type: FilterType.selectedFacetValue,
            value: {
              facet: facetName,
              operator,
              values: {
                ...existingFilterValues,
                ...values,
              },
            },
          };

          if (shouldReplaceExistingFilter) {
            filtersState.replaceByIndex(
              filter,
              existingFilterWithSameFacetIndex,
            );
          } else {
            applyFilter(filter);
          }

          return;
        }

        if (numericOperatorBitmap[operator]) {
          const { facetName: rangeFacetName, ...range } = getRange({
            firstOperator: operator,
            firstOperatorIndex: operatorIndex,
            typed,
            useNs: false,
          });
          const filter: SelectedRangeFilter = {
            type: FilterType.selectedRange,
            value: {
              facet: getFacetKey({
                component: '',
                ...meta,
                name: rangeFacetName,
                type: RangeFacetsMap[
                  rangeFacetName as keyof typeof RangeFacetsMap
                ],
              }),
              ...range,
            },
          };

          applyFilter(filter);
          return;
        }

        if (operator === Operator.regex || operator === Operator.notRegex) {
          const filter: FacetRegexFilter = {
            type: FilterType.facetRegex,
            value: {
              isEqual: operator === Operator.regex,
              name: facetName,
              value,
            },
          };
          applyFilter(filter);
          return;
        }
      }
    }

    setIdSearch(typed);
  };

  useEffect(() => {
    const activeFormulas = searchesFormulas?.formulas?.filter(
      (formula) => formula.isActive,
    );
    const activeQueries = searches
      ?.map((query: { state: any }) => query.state)
      ?.filter((query: { isActive: any }) => query.isActive);
    const countOfActiveQueriesOrFormulas =
      activeFormulas?.length + activeQueries?.length;

    if (countOfActiveQueriesOrFormulas < 1) {
      searches[0].selectOnlySingeQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searches, searchesFormulas]);

  return (
    <div className="rum-search">
      <div className="rum-search__top flex flex-row items-start">
        {leftSidebarState.width === 0 ? (
          <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
        ) : null}
        {rumTab === RumTab.list && (
          <Search
            onEnter={onEnter}
            placeholder={placeholder}
            renderTypedPanel={({
              close,
              editIndex,
              onValueSelect,
              setTyped,
              typed,
            }) => {
              return (
                <RumSearchInputPanel
                  applicationFilter={applicationFilter}
                  close={close}
                  date={date}
                  editIndex={editIndex}
                  eventType={eventType}
                  labelNames={labelNames}
                  onEnter={onEnter}
                  onValueSelect={onValueSelect}
                  selectedFacetValuesByNameState={
                    selectedFacetValuesByNameState
                  }
                  setTyped={setTyped}
                  tags={tags}
                  typed={typed}
                />
              );
            }}
            shouldUseReplace
            tags={tags}
          />
        )}
        {rumTab !== RumTab.list && (
          <div className="max-height-50-vh rum-search__query-builder overflow-auto">
            {searches.map((search, i) => (
              <RumQuerySearch
                getRange={getRange}
                groupByOptions={groupByOptions}
                index={i}
                key={i}
                labelNames={labelNames}
                measureOptions={measureOptions}
                placeholder={placeholder}
                search={search}
                searches={searches}
                rumTab={rumTab}
                rumState={rumState}
                searchesFormulas={searchesFormulas}
              />
            ))}
          </div>
        )}
      </div>

      {
        <RumSearchVisualize
          eventType={eventType}
          rumState={rumState}
          rumTab={rumTab}
        />
      }
      {rumTab === RumTab.list && eventType === RumEventType.ERROR && (
        <div>
          <div className="rum-search__visualize button-group">
            {[RumErrorTab.ERROR, RumErrorTab.ERROR_GROUP].map((tab, index) => (
              <Button
                variant="secondary"
                className={classNames({
                  'rum-error-group': true,
                  'button-group__item': true,
                  'rum-error-group--active': tab === errorTab,
                  'rum-error-group__first-item': index === 0,
                  'rum-error-group__last-item': index !== 0,
                })}
                key={tab}
                onClick={() => setErrorTab(tab)}
              >
                {startCase(tab)}
              </Button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RumSearch;
