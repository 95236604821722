import { parseFiltersFromLogsState } from 'hooks/useFiltersState';
import { defaultLogsQuery } from 'utils';
import { LogsMetricQueryProps } from 'types/LogsMetricsQueryBuilder';

import { AlertType, RuleProps, RuleType } from '../types';

/**
 * Alert must have only one query and two conditions
 * Condtion must be reduce, math and threshold
 */
const checkAlertBasicLogic = (row: RuleProps): boolean | string => {
  const { ruleData, annotations } = row;
  const isAlertBasicLogic = ruleData.length === 3 || ruleData.length === 4;
  if (!isAlertBasicLogic)
    return 'Alert cant be modified. rule format is not valid.';

  const isRuleType = annotations?.ruleType;
  if (!isRuleType) return 'Alert cant be modified. rule type is not valid.';

  const isRuleTypeValid = Object.values(RuleType).includes(
    annotations.ruleType,
  );
  if (!isRuleTypeValid) {
    return 'Alert cant be modified. rule type is not supported.';
  }

  const allConditions = ruleData?.map((data) => data.model.type || 'query');
  const supportedConditions = ['query', 'reduce', 'math', 'threshold'];
  const isSupportedConditions = allConditions.every((condition) =>
    supportedConditions.includes(condition),
  );

  if (!isSupportedConditions) {
    return 'Alert cant be modified. rule format is not supported.';
  }

  return true;
};

export const editMetricAlert = (
  row: RuleProps,
  addToast: (val: { text: string; status: string }) => void,
): string => {
  const alertRuleSanity = checkAlertBasicLogic(row);
  if (typeof alertRuleSanity === 'string') {
    addToast({ text: alertRuleSanity, status: 'warn' });
    return;
  }

  const encodeRuleTypeURI = encodeURIComponent(
    JSON.stringify({ value: RuleType.METRICS }),
  );
  const encodeAlertTypeURI = encodeURIComponent(
    JSON.stringify({
      value: row.annotations?.alertType || AlertType.THRESHOLD,
    }),
  );

  return `/alerts/create?ruleType=${encodeRuleTypeURI}&alertType=${encodeAlertTypeURI}`;
};

export const editSLOAlert = (row: RuleProps): string => {
  const encodeRuleTypeURI = encodeURIComponent(
    JSON.stringify({ value: RuleType.SLO }),
  );

  const sloAlertData = {
    annotations: row.annotations,
    labels: row.labels,
    name: row.name,
    contactPointLabels: row.contactPointLabels,
    group: row.group,
    groupFile: row.groupFile,
    tags: row.tags,
    uid: row.uid,
  };
  const sloAlertDataURI = encodeURIComponent(JSON.stringify(sloAlertData));
  return `/alerts/create?ruleType=${encodeRuleTypeURI}&sloAlertData=${sloAlertDataURI}`;
};

export const editLogAlert = (
  row: RuleProps,
  addToast: (val: { text: string; status: string }) => void,
): string => {
  const alertRuleSanity = checkAlertBasicLogic(row);
  if (typeof alertRuleSanity === 'string') {
    addToast({ text: alertRuleSanity, status: 'warn' });
    return;
  }

  const parsedExtraData = JSON.parse(row.annotations?.extraData || '{}');
  const encodeRuleTypeURI = encodeURIComponent(
    JSON.stringify({ value: RuleType.LOGS }),
  );

  if (!row.annotations?.ruleType) {
    row.annotations.ruleType = RuleType.LOGS;
  }

  const { logQL } = row.annotations;
  if (parsedExtraData.logQL || logQL) {
    const query = defaultLogsQuery;
    query.logql = parsedExtraData.logQL || logQL;
    const encodedQuery = encodeURIComponent(JSON.stringify([query]));
    return `/alerts/create?ruleType=${encodeRuleTypeURI}&filter=${encodedQuery}`;
  }

  const parsedQueries =
    (parsedExtraData?.queries as LogsMetricQueryProps[]) || [];

  const queries = parsedQueries.map((query) => {
    if (Array.isArray(query.filters)) {
      return query;
    }
    const filters = parseFiltersFromLogsState(query.filters);
    return { ...query, filters };
  });

  if (parsedExtraData?.queryType === 'query') {
    return `/alerts/create?ruleType=${encodeRuleTypeURI}&LogsMetricsQueries=${encodeURIComponent(
      JSON.stringify(queries),
    )}`;
  }

  return `/alerts/create?ruleType=${encodeRuleTypeURI}&LogsMetricsQueries=${encodeURIComponent(
    JSON.stringify(queries),
  )}&LogsMetricsFormulas=${encodeURIComponent(
    JSON.stringify(parsedExtraData?.formulas || []),
  )}`;
};

export const editAPMAlert = (
  row: RuleProps,
  addToast: (val: { text: string; status: string }) => void,
): string => {
  const alertRuleSanity = checkAlertBasicLogic(row);
  if (typeof alertRuleSanity === 'string') {
    addToast({ text: alertRuleSanity, status: 'warn' });
    return;
  }

  const encodeRuleTypeURI = encodeURIComponent(
    JSON.stringify({ value: RuleType.APM }),
  );

  return `/alerts/create?ruleType=${encodeRuleTypeURI}`;
};
