import isObject from 'lodash.isobject';
import {
  DashboardPanelProps,
  DashboardPanelType,
  DashboardTemplateValueProps,
  DateSelection,
  LogsMetricsAPIRequestMultiProps,
  QueryLangType,
} from 'types';
import {
  DataFrame,
  getLogQLWithMetaToLoad,
  getRollupByVisualization,
  logsDataTransformer,
  timeseriesDataTransformer,
  logqlDashboardDataTransformer,
  sortAndLimitTimeseries,
  kfuseqlDataTransformer,
} from 'utils';

import { getActivePromqlQuery } from './widget-utils';
import { replaceLogQLVariables } from './common-utils';
import { getQueryBuilderTransformedLogQl } from '../DashboardEdit/utils';

const MILISECONDS_IN_A_SECOND = 1000;

export const getQueryLangType = (panel: DashboardPanelProps) => {
  try {
    const kAnnotations = panel?.fieldConfig?.defaults?.custom?.kAnnotations;

    if (typeof kAnnotations === 'string') {
      const kAnnotationsObj = JSON.parse(kAnnotations);
      if (
        kAnnotationsObj &&
        isObject(kAnnotationsObj) &&
        kAnnotationsObj.queryLangType === QueryLangType.KFUSEQL
      ) {
        return QueryLangType.KFUSEQL;
      }
    }
  } catch (error) {
    return QueryLangType.KFUSEQL;
  }
  return QueryLangType.KFUSEQL;
};

export const getLogqlWithQuery = ({
  panel,
  templateValuesWithRepeated,
  panelDate,
  defaultChartTypes,
}: {
  panel: DashboardPanelProps;
  templateValuesWithRepeated: DashboardTemplateValueProps;
  panelDate: DateSelection;
  defaultChartTypes: string[];
}): {
  logqlWithMeta?: LogsMetricsAPIRequestMultiProps['logqlWithMeta'];
  logQlQueries?: LogsMetricsAPIRequestMultiProps['logQlQueries'];
  mainTransformer?: LogsMetricsAPIRequestMultiProps['mainTransformer'];
  queryLangType: string;
} => {
  const defaultStep =
    getRollupByVisualization(panelDate, defaultChartTypes[0]) *
    MILISECONDS_IN_A_SECOND;
  const query = getQueryBuilderTransformedLogQl(panel);
  const queryLangType = getQueryLangType(panel);

  if (
    query &&
    query.queries.length > 0 &&
    panel.type === DashboardPanelType.TIMESERIES
  ) {
    const logQLWithMetaToLoad = getLogQLWithMetaToLoad({
      customerFilter: query.customerFilter,
      queries: query.queries,
      formulas: query.formulas,
      date: panelDate,
      dataFormat: panel.type,
      formulaOnly: false,
      instant: false,
      queryLangType,
    });

    const logQLWithMetaToLoadTransformer = logQLWithMetaToLoad.map((logQL) => {
      const transformer = logsDataTransformer();
      if (queryLangType === QueryLangType.KFUSEQL) {
        transformer[0] = {
          id: 'kfuseqlDataTransformer',
          func: kfuseqlDataTransformer,
        };
      }

      transformer.pop(); // remove the last transformer
      if (logQL.limit) {
        transformer.splice(2, 0, {
          id: 'sortAndLimitTimeseries',
          func: (dataFrame: DataFrame) =>
            sortAndLimitTimeseries(dataFrame, logQL.limit),
        });
      }
      return { ...logQL, transformer };
    });
    const mainTransformer = [
      {
        id: 'logqlDashboardDataTransformer',
        func: logqlDashboardDataTransformer,
      },
      {
        id: 'timeseriesDataTransformer',
        func: timeseriesDataTransformer,
      },
    ];

    return {
      logqlWithMeta: logQLWithMetaToLoadTransformer,
      mainTransformer,
      queryLangType,
    };
  }

  const transformedLogQls = getActivePromqlQuery(panel.targets).map(
    (target) => ({
      logQL: replaceLogQLVariables(target.expr, templateValuesWithRepeated),
      queryKey: `query_${target.refId.toLowerCase()}`,
      step: defaultStep,
    }),
  );

  return { logQlQueries: transformedLogQls, queryLangType };
};
