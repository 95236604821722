import { toNumber } from 'lodash';
import { SLOProps } from 'types';
import { onPromiseError } from 'utils';

import queryTraceService from './queryTraceService';

type Arg = {
  name: string;
  description: string;
  latencyThreshold: number;
  type: string;
  service: { name: string; hash: string };
  objective: number;
  contactPoints: string[];
  sloId: string;
  matcher: string;
};

const editSlo = async (payload: Arg): Promise<SLOProps> => {
  const {
    name,
    matcher,
    description,
    latencyThreshold,
    type,
    service,
    objective,
    contactPoints,
    sloId,
  } = payload;

  return queryTraceService<SLOProps, 'editSLO'>(`
   {
    editSLO(
        sloId: "${sloId}",
        sloParams: 
          {name: "${name}", 
            description: "${description}",
            type: ${type},
            ${
              type === 'Latency'
                ? `latencyThreshold : ${toNumber(latencyThreshold)},`
                : ''
            }
            service: {name: "${service.name}", hash: "${service.hash}"},
            objective: ${objective},
            contactPoints: ${JSON.stringify(contactPoints)},
            matchers: "${matcher.replace(/"/g, '\\"')}",
          }
      ) {
        id
      }
    }
    `).then((data) => data.editSLO || null, onPromiseError);
};

export default editSlo;
