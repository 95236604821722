import { yupResolver } from '@hookform/resolvers/yup';
import { useRequest } from 'hooks';
import {
  AutocompleteOption,
  AutocompleteV2,
  Button,
  InputWithValidatorV2,
  Loader,
  MultiselectV2,
  useToaster,
} from 'components';
import {
  CloudLabels,
  CoreLabels,
  KubernetesLabels,
  delimiter,
} from 'kfuse-constants';
import React, { ReactElement, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useForm, Controller } from 'react-hook-form';
import { saveLogsLogQLMetrics } from 'requests';
import { LogsMetricQueryProps, RequestResult } from 'types';

import {
  saveLogsAnalyticsValidator,
  getGroupedLabelAndFacet,
  getLabelAndFacetOnly,
} from './utils';

const createOptions = (labels) =>
  labels.map((label) => ({
    label: `${label.component}:${label.name}`,
    value: `${label.component}${delimiter}${label.name}${delimiter}${label.type}`,
  }));

const coreCloudAndKubernetesOptions = createOptions([
  ...CoreLabels,
  ...CloudLabels,
  ...KubernetesLabels,
]);

type SaveMetricFormProps = {
  name: string;
  labels: string[];
  metricType: string;
};

const LogsAnalyticsSaveMetrics = ({
  closeModal,
  logqlText,
  query,
}: {
  closeModal: () => void;
  logqlText?: string;
  query?: LogsMetricQueryProps;
}): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const saveLogsLogQLMetricsRequest = useRequest(saveLogsLogQLMetrics);
  const { addToast } = useToaster();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SaveMetricFormProps>({
    resolver: yupResolver(saveLogsAnalyticsValidator()),
    defaultValues: { name: '', labels: [], metricType: '' },
  });

  const saveMetrics = (payload: any, request: RequestResult) => {
    setIsLoading(true);
    request
      .call(payload)
      .then((saveMetricResponse: any) => {
        if (saveMetricResponse) {
          addToast({ status: 'success', text: 'Metric saved successfully.' });
          closeModal();
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveLogsAnalyticsMetrics = (data: SaveMetricFormProps) => {
    const payload = {
      labels: !query?.showInput ? getLabelAndFacetOnly(data.labels) : undefined,
      name: data.name,
      metricType: data.metricType,
      logQlExpr: logqlText,
    };

    saveMetrics(payload, saveLogsLogQLMetricsRequest);
  };

  useEffect(() => {
    if (!query) return;
    const { rangeAggregateGrouping } = query;
    if (rangeAggregateGrouping.length > 0) {
      const labels = getGroupedLabelAndFacet(rangeAggregateGrouping);
      setValue('labels', labels);
    } else {
      const allLabelsValues = coreCloudAndKubernetesOptions.map(
        (option: AutocompleteOption) => option.value,
      );
      const allLabels = getGroupedLabelAndFacet(allLabelsValues);
      setValue('labels', allLabels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="logs__analytics__save-metrics">
      <div className="logs__analytics__save-metrics__header">
        <div className="logs__analytics__save-metrics__header__title">
          Save metric
        </div>
        <div
          className="logs__analytics__save-metrics__header__close"
          onClick={closeModal}
        >
          <X />
        </div>
      </div>
      <Loader isLoading={isLoading}>
        <form onSubmit={handleSubmit(onSaveLogsAnalyticsMetrics)}>
          <div className="logs__analytics__save-metrics__name">
            <label>Give metric a name</label>
            <InputWithValidatorV2
              {...register('name')}
              name="name"
              placeholder="Give metric a name"
              title="Give metric a name"
              type="text"
              errorText={errors.name?.message}
            />
          </div>
          <div className="logs__analytics__save-metrics__name">
            <label>Choose metric type</label>
            <Controller
              control={control}
              name="metricType"
              render={({ field }) => (
                <AutocompleteV2
                  {...field}
                  options={[
                    { label: 'Counter', value: 'COUNTER' },
                    { label: 'Gauge', value: 'GAUGE' },
                  ]}
                />
              )}
            />
            {errors.metricType && (
              <div className="text--red">{errors.metricType?.message}</div>
            )}
          </div>
          {!query?.showInput && (
            <div className="logs__analytics__save-metrics__group-by">
              <label>Add or remove label</label>
              <Controller
                control={control}
                name="labels"
                render={({ field }) => (
                  <MultiselectV2
                    {...field}
                    placeholder="Add or remove label"
                    options={coreCloudAndKubernetesOptions}
                  />
                )}
              />
            </div>
          )}
          <div className="logs__analytics__save-metrics--save-button">
            <Button type="submit" variant="default" size="sm">
              Save Metric
            </Button>
          </div>
        </form>
      </Loader>
    </div>
  );
};

export default LogsAnalyticsSaveMetrics;
