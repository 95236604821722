import * as yup from 'yup';
import {
  DashboardPanelProps,
  DashboardPanelType,
  DateSelection,
  SelectedFacetValuesByName,
  Span,
} from 'types';

import { formatDiffNs, isSpanRoot, resolveColorFromMap } from 'utils';
import { SpanNode } from './types';
import { blackListedLabelsBitmap } from 'kfuse-constants';
import { Filter } from 'hooks';

export const ALLOW_ONLY_TWO_ACTIVE_SEARCHES = [DashboardPanelType.HEATMAP];

export const ALLOW_ONLY_ONE_ACTIVE_SEARCH = [
  DashboardPanelType.TOP_LIST,
  DashboardPanelType.TABLE,
  DashboardPanelType.PIECHART,
];

export const getTimeMs = ({ date, lastTimestamp }) => {
  const { startTimeUnix, endTimeUnix } = date;
  if (lastTimestamp) {
    const endTimeMs = Math.round(lastTimestamp / 1000000);
    const startTimeMs = Math.max(
      endTimeMs - (endTimeUnix - startTimeUnix) * 1000000,
      startTimeUnix * 1000,
    );
    return {
      startTimeMs,
      endTimeMs,
    };
  }

  return {
    endTimeMs: endTimeUnix * 1000,
    startTimeMs: startTimeUnix * 1000,
  };
};

export const getTimeParameter = (date: DateSelection) => {
  const { endTimeUnix, startTimeUnix } = date;
  const diffInSeconds = endTimeUnix - startTimeUnix;
  if (diffInSeconds > 60 * 5) {
    return `${diffInSeconds}s`;
  }

  return `5m`;
};

type FindSpanNodeChildrenArgs = {
  attribute: string;
  colorsByHostname: { [key: string]: string };
  colorsByServiceName: { [key: string]: string };
  parentSpanId: string;
  spanNode: SpanNode;
  spans: Span[];
};

const findSpanNodeChildren = ({
  attribute,
  colorsByHostname,
  colorsByServiceName,
  parentSpanId,
  spanNode,
  spans,
}: FindSpanNodeChildrenArgs): void => {
  spanNode.children = spans
    .filter((span) => span.parentSpanId === parentSpanId)
    .map((span) =>
      makeSpanNode({
        attribute,
        colorsByHostname,
        colorsByServiceName,
        span,
        spans,
      }),
    );
};

type MakeSpanNodeArgs = {
  attribute: string;
  colorsByHostname: { [key: string]: string };
  colorsByServiceName: { [key: string]: string };
  span: Span;
  spans: Span[];
};

const makeSpanNode = ({
  attribute,
  colorsByHostname,
  colorsByServiceName,
  span,
  spans,
}: MakeSpanNodeArgs): SpanNode => {
  const { attributes, serviceName, startTimeNs, endTimeNs } = span;
  const { hostname } = attributes;
  const backgroundColor =
    attribute === 'service'
      ? resolveColorFromMap(colorsByServiceName, serviceName)
      : colorsByHostname[hostname];

  const time = formatDiffNs(startTimeNs, endTimeNs);

  const spanNode = {
    backgroundColor,
    children: [] as SpanNode[],
    name: `${span.name} (${time})`,
    spanId: span.spanId,
    value: Math.max(endTimeNs - startTimeNs, 1),
  };

  findSpanNodeChildren({
    attribute,
    colorsByHostname,
    colorsByServiceName,
    parentSpanId: span.spanId,
    spanNode,
    spans,
  });

  return spanNode;
};

type Args = {
  attribute: string;
  colorsByHostname: { [key: string]: string };
  colorsByServiceName: { [key: string]: string };
  spans: Span[];
};

export const makeSpanTree = ({
  attribute,
  colorsByHostname,
  colorsByServiceName,
  spans,
}: Args): SpanNode => {
  const parentSpanIndex = spans.length === 1 ? 0 : spans.findIndex(isSpanRoot);
  if (parentSpanIndex > -1) {
    const parentSpan = spans[parentSpanIndex];

    const spanNode = makeSpanNode({
      attribute,
      colorsByHostname,
      colorsByServiceName,
      span: parentSpan,
      spans,
    });
    return spanNode;
  }

  return null;
};

export const getTraceDashboardExportPanel = (
  expr: string,
): DashboardPanelProps => {
  return {
    options: {
      legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
      tooltip: { mode: 'single', sort: 'none' },
    },
    targets: [
      {
        datasource: { type: 'loki', uid: '' },
        editorMode: 'code',
        expr,
        key: 'Q-09b106c9-2f31-4591-b382-480e5236903f-0',
        queryType: 'range',
        refId: 'A',
      },
    ],
    type: DashboardPanelType.TRACES_ANALYTICS,
  };
};

export const saveTraceAnalyticsValidator = () => {
  return yup.object({
    name: yup
      .string()
      .required('Metric name is required')
      .min(3, 'Metric name must be at least 3 characters')
      .max(512, 'Metric name must be less than 512 characters')
      .matches(
        /^[a-zA-Z0-9_]+$/,
        'Metric name must be alphanumeric and underscore',
      ),
  });
};

export const getClientSelectedFacetValuesByName = (
  selectedFacetValuesByName: SelectedFacetValuesByName,
) =>
  Object.keys(selectedFacetValuesByName).reduce(
    (obj, name) => ({
      ...obj,
      [!blackListedLabelsBitmap[name] ? `client_${name}` : name]:
        selectedFacetValuesByName[name],
    }),
    {},
  );

export const areFiltersEqual = ({
  filters1,
  filters2,
}: {
  filters1: Filter[];
  filters2: Filter[];
}): boolean => {
  if (filters1.length !== filters2.length) {
    return false;
  }

  return filters1.every((filter, index) => {
    const filter2 = filters2[index];
    return JSON.stringify(filter) === JSON.stringify(filter2);
  });
};

export const isTraceAnalyticsView = (pathName: string) => {
  return [
    '/apm/traces/timeseries',
    '/traces/analytics/toplist',
    '/traces/analytics/table',
    '/traces/analytics/piechart',
  ].includes(pathName);
};
