import {
  ChartGridV2,
  CursorStateProvider,
  useLeftSidebarState,
} from 'components';
import { useRequest } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { getServerlessFunctions } from 'requests';
import { DateSelection } from 'types';
import useServerlessState from './hooks/useServerlessState';
import { chartingPalette } from 'kfuse-constants';
import ServerlessSidebarWrapper from './ServerlessSidebarWrapper';
import ServerlessHeader from './ServerlessHeader';
import ServerlessRightSidebarWrapper from './ServerlessRightSidebarWrapper';
import ServerlessTableWrapper from './ServerlessTableWrapper';
import { queryRangeStep } from './utils';
import { multipleQueryRangeWithLabels } from 'utils/chartGrid';
import { getQueryForServerlessMetric } from './hooks/utils';

type Args = {
  colorsByFunctionName: { [key: string]: string };
  date: DateSelection;
  selectedFacetValuesByName?: any;
  serverlessMetrics: string[];
};

const getRows = ({
  colorsByFunctionName,
  date,
  serverlessMetrics,
  selectedFacetValuesByName,
}: Args) => {
  const options = serverlessMetrics.map((metric) => ({
    label: metric.replace('aws_lambda_', '').replace(/_/g, ' '),
    value: metric,
  }));

  const colorMap = colorsByFunctionName;
  const step = `${queryRangeStep(date)}s`;

  return [
    [
      {
        initialKey: 'aws_lambda_invocations',
        charts: options.map((option) => ({
          key: option.value,
          chartType: 'line',
          colorMap,
          label: option.label,
          legendTableColumns: ['key', 'min', 'max', 'avg'],
          libraryType: 'uplot',
          onSelection: () => {},
          query: multipleQueryRangeWithLabels(
            [
              () =>
                getQueryForServerlessMetric({
                  param: option.value,
                  step,
                  selectedFacetValuesByName,
                }),
            ],
            [['{{function_arn}}']],
          ),
        })),
      },
      {
        initialKey: 'aws_lambda_errors',
        charts: options.map((option) => ({
          key: option.value,
          chartType: 'line',
          colorMap,
          label: option.label,
          legendTableColumns: ['key', 'min', 'max', 'avg'],
          libraryType: 'uplot',
          onSelection: () => {},
          query: multipleQueryRangeWithLabels(
            [
              () =>
                getQueryForServerlessMetric({
                  param: option.value,
                  step,
                  selectedFacetValuesByName,
                }),
            ],
            [['{{function_arn}}']],
          ),
        })),
      },
      {
        initialKey: 'aws_lambda_duration',
        charts: options.map((option) => ({
          key: option.value,
          chartType: 'line',
          colorMap,
          label: option.label,
          legendTableColumns: ['key', 'min', 'max', 'avg'],
          libraryType: 'uplot',
          onSelection: () => {},
          query: multipleQueryRangeWithLabels(
            [
              () =>
                getQueryForServerlessMetric({
                  param: option.value,
                  step,
                  selectedFacetValuesByName,
                }),
            ],
            [['{{function_arn}}']],
          ),
        })),
      },
    ],
  ];
};

const Serverless = () => {
  const [activeServerlessFunction, setActiveServerlessFunction] =
    useState<Record<string, string>>(null);

  const leftSidebarState = useLeftSidebarState('serverless');

  const serverlessState = useServerlessState({});
  const { date, filterState, getServerlessMetricsRequest } = serverlessState;

  const sidebarFilterState = filterState.getMethodAndStateByFilterKey(
    'sidebarFilters',
    'map',
  );

  const getServerlessFunctionsRequest = useRequest(getServerlessFunctions);

  const colorsByFunctionName = useMemo(
    () =>
      Object.values(getServerlessFunctionsRequest.result || [])
        .map((item) => item.FunctionArn)
        .sort()
        .reduce(
          (obj, functionName, i) => ({
            ...obj,
            [functionName]: chartingPalette[i % chartingPalette.length],
          }),
          {},
        ),
    [getServerlessFunctionsRequest.result],
  );

  useEffect(() => {
    getServerlessFunctionsRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serverlessMetrics = useMemo(
    () => getServerlessMetricsRequest.result || [],
    [getServerlessMetricsRequest.result],
  );

  const selectedFacetValuesByName = useMemo(
    () => ({
      ...filterState.filter.sidebarFilters,
    }),
    [filterState.filter],
  );

  const rows = useMemo(() => {
    return getRows({
      colorsByFunctionName,
      date,
      serverlessMetrics,
      selectedFacetValuesByName,
    });
  }, [
    colorsByFunctionName,
    date,
    serverlessMetrics,
    selectedFacetValuesByName,
  ]);

  return (
    <div className="serverless">
      <CursorStateProvider>
        <ServerlessSidebarWrapper
          serverlessState={serverlessState}
          leftSidebarState={leftSidebarState}
        />
        <div className="serverless__main">
          <ServerlessHeader
            serverlessState={serverlessState}
            leftSidebarState={leftSidebarState}
          />
          <div className="serverless__main__divider" />
          <div
            className="serverless__main__charts"
            data-testid="serverless-charts"
          >
            {Boolean(serverlessMetrics.length) && (
              <ChartGridV2.ChartGrid date={date} rows={rows} />
            )}
          </div>
          <div className="serverless__main__divider" />
          <ServerlessTableWrapper
            colorsByFunctionName={colorsByFunctionName}
            getServerlessFunctionsRequest={getServerlessFunctionsRequest}
            setActiveServerlessFunction={setActiveServerlessFunction}
            serverlessState={serverlessState}
          />
        </div>
        <ServerlessRightSidebarWrapper
          activeServerlessFunction={activeServerlessFunction}
          colorsByFunctionName={colorsByFunctionName}
          date={date}
          sidebarFilterState={sidebarFilterState}
          setActiveServerlessFunction={setActiveServerlessFunction}
          getServerlessFunctionsRequest={getServerlessFunctionsRequest}
        />
      </CursorStateProvider>
    </div>
  );
};

export default Serverless;
