import { SLOQueryTemplates } from 'screens/SLO/types';
import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';

const getSLOQueryTemplates = async (
  type: string,
): Promise<SLOQueryTemplates> => {
  return queryTraceService<SLOQueryTemplates, 'getSLOQueryTemplates'>(`
    {
        getSLOQueryTemplates(
            sloType: ${type}
        ) {
            sliBadEventsPromQuery
            sliTotalEventsPromQuery
            sliBadEventsPromRecQuery
            sliTotalEventsPromRecQuery
            sloBudgetRemaining
            sloCurrentBurnRate
            sloStatusQuery
      }
    }
    `).then((data) => data.getSLOQueryTemplates || null, onPromiseError);
};

export default getSLOQueryTemplates;
