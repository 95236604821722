import { LogsMetricsAPIRequestMultiProps } from 'types';

import getLogMetricsResultWithKfuseQl from './getLogMetricsResultWithKfuseQl';

const getLogMetricsResultWithKfuseQlMultiple = async ({
  date,
  instant = false,
  logqlWithMeta,
  mainTransformer,
}: LogsMetricsAPIRequestMultiProps) => {
  if (logqlWithMeta) {
    const data = await Promise.all(
      logqlWithMeta.map(({ logql, meta, transformer }) =>
        getLogMetricsResultWithKfuseQl({
          date,
          instant,
          kfuseQl: logql,
          meta,
          transformer,
        }),
      ),
    );
    if (mainTransformer) {
      const transformed = mainTransformer.reduce(
        (prevData, item) => item.func(prevData),
        data,
      );
      return transformed;
    }
    return data;
  }
};

export default getLogMetricsResultWithKfuseQlMultiple;
