import { DashboardPanelProps } from 'types/Dashboard';
import {
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
} from 'types/LogsMetricsQueryBuilder';
import { defaultLogsQuery, logsQueryDecodeTransform } from 'utils';

export const getQueryBuilderTransformedLogQl = (
  panel: DashboardPanelProps,
): {
  customerFilter?: { key: string; value: string };
  formulas: LogsMetricForumlaProps[];
  queries: LogsMetricQueryProps[];
} | null => {
  const queries: LogsMetricQueryProps[] = [];
  const formulas: LogsMetricForumlaProps[] = [];
  if (!panel) {
    queries.push({ ...defaultLogsQuery, queryKey: 'a' });
    return { formulas, queries };
  }

  const { targets, fieldConfig } = panel;
  const kAnnotations = fieldConfig?.defaults?.custom?.kAnnotations as string;
  if (!kAnnotations) {
    targets.forEach((target) => {
      const query = target.expr;
      queries.push({
        ...defaultLogsQuery,
        logql: query,
        queryKey: `${target.refId.toLowerCase()}`,
        showInput: true,
      });
    });
    return { formulas, queries };
  }

  const transformed = logsQueryDecodeTransform(kAnnotations);
  return transformed;
};
