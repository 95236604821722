import { Button, ChipWithLabel, Loader } from 'components';
import convert from 'convert';
import dayjs from 'dayjs';
import { useRequest, useTracesState } from 'hooks';
import { timeFormats } from 'kfuse-constants';
import React, { useEffect, useRef, useState } from 'react';
import { AreaSelectionProps, DateSelection } from 'types';
import { colors } from './constants';
import { getCompareAttributeCounts } from './requests';
import TracesHeatmapChartsGridItem from './TracesHeatmapChartsGridItem';
import { HeatmapResult } from './types';

const convertValue = (ms: number) => {
  const { quantity, unit } = convert(ms, 'ms').to('best');
  if (unit === 'ms' || unit === 'ns') {
    return `${quantity.toFixed(0)}${unit}`;
  }

  const toFixed = quantity === Number(quantity.toFixed(0)) ? 0 : 2;
  return `${quantity.toFixed(toFixed)}${unit}`;
};

type Args = {
  areaSelection: AreaSelectionProps;
  clearSelection: VoidFunction;
  customerFilter: { key: string; value: string };
  date: DateSelection;
  heatmapResult: HeatmapResult;
  tracesState: ReturnType<typeof useTracesState>;
  traceIdSearch: string;
};

const TracesHeatmapCharts = ({
  areaSelection,
  clearSelection,
  customerFilter,
  date,
  heatmapResult,
  tracesState,
  traceIdSearch,
}: Args) => {
  const {
    selectedFacetValuesByNameState,
    selectedFacetRangeByNameState,
    keyExistsState,
    spanFilters,
    facetRegexState,
  } = tracesState;

  const { spanFilter } = spanFilters;
  const { startTime, endTime, startValue, endValue } = areaSelection;
  const hoveredNameState = useState(null);

  const compareAttributeCountsRequest = useRequest(getCompareAttributeCounts);

  const elementRef = useRef(null);
  useEffect(() => {
    elementRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, [areaSelection]);

  useEffect(() => {
    compareAttributeCountsRequest.call({
      areaSelection,
      date,
      customerFilter,
      facetRegex: facetRegexState.state,
      keyExists: keyExistsState.state,
      selectedFacetRangeByName: selectedFacetRangeByNameState.state,
      selectedFacetValuesByName: selectedFacetValuesByNameState.state,
      spanFilter,
      traceIdSearch,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaSelection, heatmapResult]);

  const compareAttributeCounts = compareAttributeCountsRequest.result || [];

  return (
    <div ref={elementRef}>
      <div className="traces__heatmap__charts__header">
        <div className="traces__heatmap__charts__header__left">
          <ChipWithLabel
            color={colors.selection}
            label={`Selection is data between ${dayjs.unix(startTime).format(timeFormats.dateAtTimeSeconds)} - ${dayjs.unix(endTime).format(timeFormats.dateAtTimeSeconds)} where duration is ${convertValue(startValue)} - ${convertValue(endValue)}`}
          />
          <ChipWithLabel
            color={colors.baseline}
            label={`Baseline is all other events in the query`}
          />
        </div>
        <Button variant="default" onClick={clearSelection} size="sm">
          Clear Selection
        </Button>
      </div>
      <Loader
        className="traces__heatmap__charts__grid"
        isLoading={compareAttributeCountsRequest.isLoading}
      >
        {compareAttributeCounts.map((compareAttributeCount, i) => (
          <TracesHeatmapChartsGridItem
            compareAttributeCount={compareAttributeCount}
            key={i}
            hoveredNameState={hoveredNameState}
            tracesState={tracesState}
          />
        ))}
      </Loader>
    </div>
  );
};

export default TracesHeatmapCharts;
