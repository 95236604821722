import React from 'react';
import { AiOutlineLineChart } from 'react-icons/ai';
import { CiViewList } from 'react-icons/ci';
import {
  BsFillFileTextFill,
  BsFilterLeft,
  BsPieChartFill,
  BsTable,
} from 'react-icons/bs';
import { GiHoneycomb } from 'react-icons/gi';
import { MdTableRows } from 'react-icons/md';
import { RiLayoutMasonryFill } from 'react-icons/ri';
import { DashboardPanelType, WidgetProps, WidgetItemProps } from 'types';

export const SUPPORTED_PANELS = [
  DashboardPanelType.TIMESERIES,
  DashboardPanelType.STAT,
  DashboardPanelType.TABLE,
  DashboardPanelType.HEATMAP,
  DashboardPanelType.PIECHART,
  DashboardPanelType.TREEMAP,
  DashboardPanelType.TEXT,
  DashboardPanelType.GROUP,
  DashboardPanelType.PLACEHOLDER,
  DashboardPanelType.GRAFANA_POLYSTAT_PANEL,
];

export const SUPPORTED_EDIT_PANELS = [
  DashboardPanelType.TIMESERIES,
  DashboardPanelType.TOP_LIST,
  DashboardPanelType.TABLE,
  DashboardPanelType.PIECHART,
  DashboardPanelType.GRAFANA_POLYSTAT_PANEL,
  DashboardPanelType.LOGS,
  DashboardPanelType.TEXT,
  DashboardPanelType.ROW,
];

export const GRAPH_PANELS = [
  DashboardPanelType.TIMESERIES,
  DashboardPanelType.STAT,
  DashboardPanelType.TABLE,
  DashboardPanelType.HEATMAP,
  DashboardPanelType.PIECHART,
  DashboardPanelType.TREEMAP,
  DashboardPanelType.GRAFANA_POLYSTAT_PANEL,
  DashboardPanelType.TOP_LIST,
];

export const GraphWidgetList: WidgetItemProps[] = [
  {
    name: DashboardPanelType.TIMESERIES,
    label: 'Timeseries',
    icon: <AiOutlineLineChart />,
  },
  {
    name: DashboardPanelType.TOP_LIST,
    label: 'Top List',
    icon: <BsFilterLeft />,
  },
  { name: DashboardPanelType.TABLE, label: 'Table', icon: <BsTable /> },
  {
    name: DashboardPanelType.PIECHART,
    label: 'Pie Chart',
    icon: <BsPieChartFill />,
  },
  {
    name: DashboardPanelType.GRAFANA_POLYSTAT_PANEL,
    label: 'Host map',
    icon: <GiHoneycomb />,
  },
];

export const GraphWidgetEventList: WidgetItemProps[] = [
  {
    name: DashboardPanelType.LOGS,
    label: 'Log Events',
    icon: <CiViewList />,
  },
];

const TextAndEmbedWidgetList: WidgetItemProps[] = [
  {
    name: DashboardPanelType.TEXT,
    label: 'Text',
    icon: <BsFillFileTextFill />,
  },
  {
    name: DashboardPanelType.ROW,
    label: 'Row',
    icon: <MdTableRows />,
  },
];

export const widgetList: WidgetProps[] = [
  {
    name: 'Graph',
    label: 'Graph',
    list: GraphWidgetList,
  },
  {
    name: 'ListandStreams',
    label: 'List and Streams',
    list: GraphWidgetEventList,
  },
  {
    name: 'TextandEmbed',
    label: 'Text and Embed',
    list: TextAndEmbedWidgetList,
  },
];
