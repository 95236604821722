import {
  useFacetRegexState,
  useRequest,
  useSelectedFacetRangeByNameState,
  useSelectedFacetValuesByNameState,
  useSortState,
} from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { DateSelection } from 'types';
import rumAggregateTable from '../requests/rumAggregateTable';

type Args = {
  applicationFilter: string;
  date: DateSelection;
  facetRegexState: ReturnType<typeof useFacetRegexState>;
  rumTableSortState?: ReturnType<typeof useSortState>;
  selectedFacetRangeByNameState?: ReturnType<
    typeof useSelectedFacetRangeByNameState
  >;
  selectedFacetValuesByNameState?: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  aggregationFunction: any;
};

const useAggregateTableRequest = ({
  applicationFilter,
  date,
  facetRegexState,
  rumTableSortState,
  selectedFacetRangeByNameState,
  selectedFacetValuesByNameState,
  aggregationFunction,
}: Args) => {
  const request = useRequest(rumAggregateTable);
  const [result, setResult] = useState([]);

  const call = () => {
    const { startTimeUnix, endTimeUnix } = date;
    request
      .call({
        applicationFilter,
        startTimeUnix,
        facetRegex: facetRegexState?.state,
        endTimeUnix,
        selectedFacetRangeByName: selectedFacetRangeByNameState.state,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        aggregateFunction: aggregationFunction,
      })
      .then((nextResult) => {
        if (nextResult && nextResult.length) {
          setResult(nextResult);
        }
      });
  };

  useEffect(() => {
    setResult([]);
    call();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicationFilter,
    date,
    selectedFacetRangeByNameState.state,
    selectedFacetValuesByNameState.state,
    rumTableSortState?.state,
    aggregationFunction,
  ]);

  return {
    call,
    isLoading: request.isLoading,
    result,
  };
};

export default useAggregateTableRequest;
