import { SelectedFacetValuesByName, ValueCount } from 'types';
import {
  filterListWithSelectedSidebar,
  mergeTwoSidebarCountsValues,
} from 'utils';

import { ArchiveInfoProps } from '../types';

export const LogsHydrationArchivesFacet = [
  { name: 'inconfig', label: 'In Config', forceExpanded: false },
  { name: 'ins3', label: 'In S3', forceExpanded: false },
];

const getCountForHydrationJobListSidebar = (
  archives: ArchiveInfoProps[],
): {
  inconfig: { [key: string]: number };
  ins3: { [key: string]: number };
} => {
  const inconfig: { [key: string]: number } = {};
  const ins3: { [key: string]: number } = {};

  archives.forEach((archive, idx) => {
    if (inconfig[archive.inconfig] === undefined) {
      inconfig[archive.inconfig] = 1;
    } else {
      inconfig[archive.inconfig] += 1;
    }

    if (ins3[archive.ins3] === undefined) {
      ins3[archive.ins3] = 1;
    } else {
      ins3[archive.ins3] += 1;
    }
  });

  return { inconfig, ins3 };
};

export const filterHydrationArchives = (
  archives: ArchiveInfoProps[],
  selectedFacetValuesByName: SelectedFacetValuesByName,
): {
  formattedArchives: ArchiveInfoProps[];
  inconfig: ValueCount[];
  ins3: ValueCount[];
} => {
  const filtered = filterListWithSelectedSidebar(
    archives,
    selectedFacetValuesByName,
  );

  const countFromRules = getCountForHydrationJobListSidebar(archives);
  const countFromFiltered = getCountForHydrationJobListSidebar(filtered);
  const mergedCount = mergeTwoSidebarCountsValues(
    countFromRules,
    countFromFiltered,
  );

  const { inconfig, ins3 } = mergedCount;

  return {
    formattedArchives: filtered,
    inconfig: Object.keys(inconfig).map((key) => ({
      value: key,
      count: inconfig[key],
    })),
    ins3: Object.keys(ins3).map((key) => ({
      value: key,
      count: ins3[key],
    })),
  };
};
