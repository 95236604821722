import { dateTimeFormat } from 'kfuse-constants/dateTimeFormat';
import { DashboardPanelType, DashboardStreamType } from 'types/Dashboard';
import { sortAndLimitTimeseriesMetrics } from 'utils/DataTransformer';

export const defaultTransformations = ({
  dataFormat,
  stream,
}: {
  dataFormat: DashboardPanelType;
  stream: DashboardStreamType;
}) => {
  const transformations = [
    { id: 'merge', options: null },
    {
      id: 'formatTime',
      options: {
        outputFormat: dateTimeFormat,
        timeField: 'Time',
        useTimezone: false,
      },
    },
    {
      id: 'organize',
      options: {
        excludeByName: { Time: false },
        renameByName: {},
        indexByName: { Time: 0 },
      },
    },
  ];

  if (
    dataFormat === DashboardPanelType.PIECHART ||
    dataFormat === DashboardPanelType.TOP_LIST ||
    dataFormat === DashboardPanelType.GRAFANA_POLYSTAT_PANEL
  ) {
    if (stream === DashboardStreamType.METRIC) {
      transformations.unshift({
        id: 'sortAndLimitTimeseries',
        func: (dataFrame) =>
          sortAndLimitTimeseriesMetrics(dataFrame, { direction: 'topk' }),
      });
    }

    const organizeTransformation = transformations.find(
      (transformation) => transformation.id === 'organize',
    );
    if (organizeTransformation) {
      organizeTransformation.options.excludeByName = {
        ...organizeTransformation.options.excludeByName,
        Time: true,
      };
    }
  }

  if (dataFormat === DashboardPanelType.PIECHART) {
    transformations.push({ id: 'toPieChart', options: null });
  }

  if (dataFormat === DashboardPanelType.TOP_LIST) {
    transformations.push({ id: 'toTopList', options: null });
  }

  if (dataFormat === DashboardPanelType.GRAFANA_POLYSTAT_PANEL) {
    transformations.push({ id: 'toHostmap', options: null });
  }

  return transformations;
};
