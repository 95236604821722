import { colorsByAlertState, dateTimeFormat } from 'kfuse-constants';
import { Loader } from 'components';
import React, { ReactElement, useState } from 'react';

import { ConditionProps } from '../AlertsCreateCondition';
import { useAlertsState } from '../hooks';
import { AlertsMetricsParsedProps, RuleProps } from '../types';
import dayjs from 'dayjs';
import AlertsDetailsHeader from './AlertsDetailsHeader';
import AlertsDetailsPropertiesInfo from './AlertsDetailsPropertiesInfo';
import AlertsDetailsPropertiesExpression from './AlertsDetailsPropertiesExpression';
import { Separator } from 'components/shadcn';

const AlertsDetailsProperties = ({
  alertsState,
  condition,
  disableAlertEditAndDelete,
  parsedMetadata,
  properties,
}: {
  alertsState: ReturnType<typeof useAlertsState>;
  condition: ConditionProps;
  disableAlertEditAndDelete?: boolean;
  parsedMetadata: AlertsMetricsParsedProps;
  properties: RuleProps;
}): ReactElement => {
  const [rule, setRule] = useState<RuleProps>(properties);
  const { isLoading } = alertsState;

  return (
    <div>
      <Loader isLoading={isLoading}>
        <AlertsDetailsHeader
          alertsState={alertsState}
          disableAlertEditAndDelete={disableAlertEditAndDelete}
          properties={properties}
          rule={rule}
          setRule={setRule}
        />
      </Loader>
      <div className="alerts__details__properties__summary box-shadow">
        <div className="alerts__details__subheader">
          <div className="text-lg font-semibold">Properties</div>
          <div className="flex--align-items-center flex">
            {rule.muteEndAt && (
              <div className="text--weight-bold pr-4">
                Muted until - {dayjs(rule.muteEndAt).format(dateTimeFormat)}
              </div>
            )}
            <div className="flex items-baseline">
              <div
                className="chip alerts__list__status-chip"
                style={{ backgroundColor: colorsByAlertState[rule.status] }}
              >
                {rule.status}
              </div>
            </div>
          </div>
        </div>
        <div className="flex pt-2">
          <AlertsDetailsPropertiesInfo
            condition={condition}
            parsedMetadata={parsedMetadata}
            rule={rule}
          />
          <Separator orientation="vertical" className="h-auto" />
          <AlertsDetailsPropertiesExpression
            parsedMetadata={parsedMetadata}
            rule={rule}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertsDetailsProperties;
