import classnames from 'classnames';
import { PopoverTriggerV2, PopoverPosition, useThemeContext } from 'components';
import { useFeatureFlag } from 'configcat-react';
import { useAlertsPageStateContext, useRumPageStateContext } from 'context';
import { useConfigContext } from 'context';
import { useAuth, useUrlSearchParams } from 'hooks';
import React, { ReactElement, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { User, UserRole } from 'types/user';
import HeaderLogo from '../../images/HeaderLogo.png';
import HeaderPanel from './HeaderPanel';
import HeaderUserPanel from './HeaderUserPanel';
import {
  adminSubmenu,
  advancedServiceMonitoringFilter,
  alertsSubmenu,
  apmSubmenu,
  dashboardSubmenu,
  grafanaSubmenu,
  infrastructureSubmenu,
  kfuseSubmenu,
  logsSubmenu,
  metricsSubmenu,
  helpSubmenu,
  eventsSubmenu,
  rumSubMenu,
} from './utils';
import HeaderCustomNavLink from './HeaderCustomNavLink';
import { Row } from '../../components/layouts/Flex';
import LeftNavBar from 'components/Drawer/LeftNavBar';
import { useWindowSize } from 'react-use';
import { FaUserCircle } from 'react-icons/fa';

const getLinks = ({
  alertsPageState,
  enableLogsSaveMetrics,
  enableTraceSaveMetrics,
  hideInfrastructure,
  isAdmin,
  rumPageState,
  urlSearchParams,
  workbooks,
  showASM,
  showRUM,
  showServerless,
  showKfuseProfiler,
  showHeatmap,
}: {
  alertsPageState: ReturnType<typeof useAlertsPageStateContext>;
  enableLogsSaveMetrics: boolean;
  enableTraceSaveMetrics: boolean;
  hideInfrastructure: boolean;
  isAdmin: boolean;
  rumPageState: ReturnType<typeof useRumPageStateContext>;
  urlSearchParams: URLSearchParams;
  workbooks: any[];
  showASM?: boolean;
  showRUM?: boolean;
  showServerless: boolean;
  showKfuseProfiler: boolean;
  showHeatmap: boolean;
}) =>
  [
    {
      key: 'apm',
      label: 'APM',
      panel: (
        <HeaderPanel
          items={apmSubmenu(
            enableTraceSaveMetrics,
            alertsPageState,
            showHeatmap,
          )}
          urlSearchParams={urlSearchParams}
        />
      ),
      route: `/apm/services`,
      subItemsFunc: apmSubmenu(
        enableTraceSaveMetrics,
        alertsPageState,
        showHeatmap,
      ),
      isActiveFor: {
        '/apm/traces/timeseries': true,
        '/apm/traces/toplist': true,
        '/apm/traces/table': true,
        '/apm/traces/piechart': true,
      },
    },
    {
      key: 'metrics',
      label: 'Metrics',
      panel: <HeaderPanel items={metricsSubmenu} />,
      route: '/metrics',
      subItemsFunc: metricsSubmenu,
    },
    {
      key: 'logs',
      label: 'Logs',
      panel: (
        <HeaderPanel
          items={logsSubmenu(workbooks, enableLogsSaveMetrics, alertsPageState)}
        />
      ),
      route: `/logs`,
      subItemsFunc: logsSubmenu(
        workbooks,
        enableLogsSaveMetrics,
        alertsPageState,
      ),
    },
    {
      key: 'Events',
      label: 'Events',
      panel: <HeaderPanel items={eventsSubmenu} />,
      route: '/events/list',
      subItemsFunc: eventsSubmenu,
    },
    {
      key: 'Alerts',
      label: 'Alerts',
      panel: <HeaderPanel items={alertsSubmenu} />,
      route: '/alerts',
      subItemsFunc: alertsSubmenu,
    },
    {
      key: 'grafana-metrics',
      label: 'Grafana',
      panel: <HeaderPanel items={grafanaSubmenu(showKfuseProfiler)} />,
      route: '/grafana-metrics',
      subItemsFunc: grafanaSubmenu(showKfuseProfiler),
    },
    {
      key: 'dashboard',
      label: 'Dashboards',
      route: '/dashboards/lists',
      panel: <HeaderPanel items={dashboardSubmenu} />,
      subItemsFunc: dashboardSubmenu,
    },
    hideInfrastructure
      ? null
      : {
          key: 'infrastructure',
          label: 'Infrastructure',
          panel: <HeaderPanel items={infrastructureSubmenu(showServerless)} />,
          route: '/kubernetes',
          subItemsFunc: infrastructureSubmenu(showServerless),
        },
    showASM
      ? {
          key: 'advanced-service-monitoring',
          label: 'ASM',
          route: `/advanced-service-monitoring?${advancedServiceMonitoringFilter}`,
        }
      : null,
    {
      key: 'admin',
      label: 'Admin',
      panel: <HeaderPanel items={() => adminSubmenu(isAdmin)} />,
      route: isAdmin ? '/admin/users' : '/admin/folders',
      subItemsFunc: () => adminSubmenu(isAdmin),
    },
    showRUM
      ? {
          key: 'rum',
          label: 'RUM',
          panel: <HeaderPanel items={() => rumSubMenu(rumPageState)} />,
          route: '/rum/list',
          subItemsFunc: () => rumSubMenu(rumPageState),
          isActiveFor: {
            '/rum/list': true,
            '/rum/toplist': true,
            '/rum/table': true,
            '/rum/piechart': true,
            '/rum/geomap': true,
            '/rum/treemap': true,
            '/rum/performance-monitoring/performance': true,
            '/rum/performance-monitoring/errors': true,
          },
        }
      : null,
  ].filter(Boolean);

const getLinksRight = ({ hideControlPlane }: { hideControlPlane: boolean }) =>
  [
    hideControlPlane
      ? null
      : {
          key: 'control-plane',
          label: 'Control Plane',
          panel: <HeaderPanel items={kfuseSubmenu} className="right-0" />,
          route: '/kfuse/overview',
          subItemsFunc: kfuseSubmenu,
        },
    {
      key: 'help',
      label: 'Help',
      panel: <HeaderPanel items={helpSubmenu} className="right-0" />,
      route: '',
      subItemsFunc: helpSubmenu,
    },
  ].filter(Boolean);

type Props = {
  auth: ReturnType<typeof useAuth>;
  workbooks: any[];
};

const Header = ({ auth, workbooks }: Props): ReactElement => {
  const { value: showHeatmap, loading: showHeatmapLoading } = useFeatureFlag(
    'heatmap',
    false,
  );

  const configJson = useConfigContext();
  const showRUM = configJson?.rum?.enabled;
  const { darkModeEnabled } = useThemeContext();
  const { value: showServerless } = useFeatureFlag('serverless', false);
  const { value: showASM } = useFeatureFlag('showasm', false);

  const { value: enableSaveMetrics } = useFeatureFlag(
    'enableSaveMetrics',
    false,
  );
  const { value: enableTraceSaveMetrics } = useFeatureFlag(
    'enableTraceSaveMetrics',
    false,
  );
  const { value: showKfuseProfiler } = useFeatureFlag(
    'showKfuseProfiler',
    false,
  );
  const { value: hideInfrastructureForViewer } = useFeatureFlag(
    'hideinfrastructureforviewer',
    false,
  );
  const { value: hideControlPlaneForViewer } = useFeatureFlag(
    'hidecontrolplaneforviewer',
    false,
  );

  const urlSearchParams = useUrlSearchParams();
  const user = auth?.user as User;
  const alertsPageState = useAlertsPageStateContext();
  const rumPageState = useRumPageStateContext();
  const screenSize = useWindowSize();
  const isSmallScreen = screenSize.width < 1255;

  const isAdmin = user?.role === UserRole.ADMIN;
  const isViewer = user?.role === UserRole.VIEWER;

  const hideControlPlane = hideControlPlaneForViewer && isViewer;
  const hideInfrastructure = hideInfrastructureForViewer && isViewer;

  return (
    <Row className="header">
      <NavLink className="header__home" to="/">
        <img className="size-8 w-8" src={HeaderLogo} alt="K" />
      </NavLink>
      {isSmallScreen ? (
        <LeftNavBar
          isSmallScreen={isSmallScreen}
          workbooks={workbooks}
          user={user}
          enableSaveMetrics={enableSaveMetrics}
          enableTraceSaveMetrics={enableTraceSaveMetrics}
          showServerless={showServerless}
          showASM={showASM}
          showRUM={showRUM}
          showKfuseProfiler={showKfuseProfiler}
          getLinks={getLinks}
          getLinksRight={getLinksRight}
        />
      ) : (
        <>
          <Row className="header__nav">
            {getLinks({
              alertsPageState,
              enableLogsSaveMetrics: enableSaveMetrics,
              enableTraceSaveMetrics: enableTraceSaveMetrics,
              hideInfrastructure,
              isAdmin,
              rumPageState,
              urlSearchParams,
              workbooks,
              showServerless,
              showASM,
              showRUM,
              showKfuseProfiler,
              showHeatmap,
            }).map((link) => {
              const emailDisablingAdmin = 'noreply@kloudfuse.com';
              const userEmailIsDisablingAdmin =
                user.email === emailDisablingAdmin;
              const routeContainingAdmin = link.route.includes('/admin');
              const displayTab = !(
                routeContainingAdmin && userEmailIsDisablingAdmin
              );

              return (
                displayTab && (
                  <div
                    key={link.key}
                    className={classnames('header__nav__item')}
                  >
                    <HeaderCustomNavLink link={link} />
                    {link.panel && <div>{link.panel}</div>}
                  </div>
                )
              );
            })}
          </Row>
          <Row className="header__nav__right">
            {getLinksRight({ hideControlPlane }).map((link) => (
              <div key={link.key} className={classnames('header__nav__item')}>
                <HeaderCustomNavLink link={link} />
                {link.panel && <div>{link.panel}</div>}
              </div>
            ))}
          </Row>
        </>
      )}
      <PopoverTriggerV2
        className="header__user"
        popover={(props) => <HeaderUserPanel {...props} auth={auth} />}
        position={PopoverPosition.BOTTOM_RIGHT}
      >
        {user?.imageUrl ? (
          <img
            className="header__user__avatar"
            referrerPolicy="no-referrer"
            src={user.imageUrl}
            alt="User Avatar"
          />
        ) : (
          <FaUserCircle size={24} color={darkModeEnabled ? '#eee' : '#ddd'} />
        )}
      </PopoverTriggerV2>
    </Row>
  );
};

export default Header;
