import {
  Button,
  CompactTooltipContainerV2,
  DashboardExport,
  HeatmapRenderer,
  Loader,
  OverlayMessage,
  OverlayMessageProps,
  TooltipTrigger,
  useModalsContext,
} from 'components';
import convert from 'convert';
import {
  FilterType,
  Operator,
  SearchState,
  useScrollListener,
  useToggle,
  useTracesState,
} from 'hooks';
import ResizeObserver from 'rc-resize-observer';
import { default as React, useEffect, useMemo, useState } from 'react';
import { BiExport } from 'react-icons/bi';
import { AreaSelectionProps, DateSelection } from 'types';
import {
  buildHeatmapPath,
  getHeatmapCountFills,
  heatmapColorPalette,
} from 'utils';
import {
  getHeatmapDashboardExportPanel,
  getHeatmapDashboardExportQuery,
} from './dashboardExportUtils';
import TracesHeatmapCharts from './TracesHeatmapCharts';
import { HeatmapResult } from './types';
import { getAreaSelection } from './utils';

type Props = {
  date: DateSelection;
  customerFilter: { key: string; value: string };
  traceIdSearch: string;
  tracesState: ReturnType<typeof useTracesState>;
  search: SearchState;
  heatmapResult: HeatmapResult;
  title?: string;
  isLoading: boolean;
  heatmapsContainerRef: React.MutableRefObject<HTMLDivElement>;
  heatmapError: {
    getHeatmapData: { message: string };
  };
};

const NUM_X_AXIS_SPLITS = 300;
const NUM_Y_AXIS_SPLITS = 100;

const TracesHeatmap = ({
  customerFilter,
  date,
  tracesState,
  traceIdSearch,
  search,
  title,
  heatmapResult,
  isLoading,
  heatmapsContainerRef,
  heatmapError,
}: Props) => {
  const { spanFilters } = tracesState;
  const { spanFilter } = spanFilters;
  const modals = useModalsContext();
  const { searchBarState } = search;
  const [chartWidth, setChartWidth] = useState(1100);
  const [areaSelection, setAreaSelection] = useState<AreaSelectionProps | null>(
    null,
  );
  const [bubbleUpAreaSelection, setBubbleUpAreaSelection] =
    useState<AreaSelectionProps>(null);

  const showContextMenuToggle = useToggle();

  const isRunQueriesMessageDisplayed = useMemo(() => {
    const isMessageDisplayed = !heatmapResult && !heatmapError?.getHeatmapData;

    return isMessageDisplayed;
  }, [heatmapResult, heatmapError]);

  useEffect(() => {
    setAreaSelection(null);
    setBubbleUpAreaSelection(null);
    showContextMenuToggle.off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heatmapResult]);

  useScrollListener({
    onScroll: () => {
      showContextMenuToggle.off();
      setAreaSelection(null);
    },
    scrollRef: heatmapsContainerRef,
  });

  const chartData = useMemo(() => {
    if (!heatmapResult) return null;

    return {
      data: [null, heatmapResult.data || []],
      series: [
        {
          label: 'Latency',
          paths: buildHeatmapPath({
            disp: {
              fill: {
                lookup: heatmapColorPalette,
                values: getHeatmapCountFills,
              },
            },
          }),
          facets: [
            { scale: 'x', auto: true, sorted: 1 },
            { scale: 'y', auto: true },
          ],
        },
      ],
      minValue: heatmapResult.minValue,
      maxValue: heatmapResult.maxValue,
      uniqueTimestamps: heatmapResult.uniqueTimestamps,
    };
  }, [heatmapResult]);

  const viewSpans = ({
    areaSelection,
  }: {
    areaSelection: AreaSelectionProps;
  }) => {
    const startTimeUnix = Math.ceil(
      Math.min(areaSelection.startTime, areaSelection.endTime),
    );
    const endTimeUnix = Math.floor(
      Math.max(areaSelection.startTime, areaSelection.endTime),
    );
    const dateURI = encodeURIComponent(
      JSON.stringify({ startTimeUnix, endTimeUnix }),
    );

    const customerFilterURI = encodeURIComponent(
      JSON.stringify(customerFilter),
    );
    const firstFacetLimit = areaSelection.endValue / 1e6;
    const secondFacetLimit = areaSelection.startValue / 1e6;
    const upperFacetLimit = Math.ceil(
      Math.max(firstFacetLimit, secondFacetLimit),
    );
    const lowerFacetLimit = Math.floor(
      Math.min(firstFacetLimit, secondFacetLimit),
    );
    const facetRange = {
      lower: lowerFacetLimit,
      upper: upperFacetLimit,
    };

    const tracesFilters = [
      {
        disabled: false,
        type: FilterType.selectedRange,
        value: {
          facet: 'duration',
          isLowerInclusive: true,
          isUpperInclusive: true,
          ...facetRange,
        },
      },
      ...searchBarState?.filters
        ?.filter((filter) => filter.type !== FilterType.selectedRange)
        .filter((filter) => filter !== undefined),
    ];
    const tracesFiltersURI = encodeURIComponent(JSON.stringify(tracesFilters));
    const spanFiltersURI = encodeURIComponent(JSON.stringify(spanFilter));
    const targetUrl = `${window.location.origin}/#/apm/traces/list?apmDate=${dateURI}&tracesFilters=${tracesFiltersURI}&tracesSpanFilter=${spanFiltersURI}&customerFilter=${customerFilterURI}`;
    window.open(targetUrl, '_blank');
  };

  const onExportHeatmapToDashboard = () => {
    const expr = getHeatmapDashboardExportQuery({
      customerFilter,
      numAttributeBuckets: NUM_Y_AXIS_SPLITS,
      numTimeBuckets: NUM_X_AXIS_SPLITS,
      ParentSpanIdFilter: '0000000000000000',
      query: search.state,
    });

    modals.push(
      <DashboardExport
        closeModal={modals.pop}
        date={date}
        panel={getHeatmapDashboardExportPanel(expr)}
      />,
    );
  };

  const unitAdjustBubbleSelection = useMemo(() => {
    if (bubbleUpAreaSelection && heatmapResult.unit) {
      const { unit } = heatmapResult;

      return {
        ...bubbleUpAreaSelection,
        startValue: convert(bubbleUpAreaSelection.startValue, unit).to('ms'),
        endValue: convert(bubbleUpAreaSelection.endValue, unit).to('ms'),
      };
    }

    return null;
  }, [bubbleUpAreaSelection, heatmapResult]);

  const overlayMessageProps: OverlayMessageProps = heatmapError?.getHeatmapData
    ?.message
    ? {
        isActive: true,
        iconName: 'warning',
        message: <>{heatmapError?.getHeatmapData?.message}</>,
      }
    : { isActive: false };

  return (
    <div className="traces__heatmap relative px-[20px]">
      {title && (
        <div className="w-full whitespace-nowrap text-[11px] font-medium">
          {title}
        </div>
      )}
      <div className="flex justify-end gap-2">
        <Button
          className="ml-1"
          variant="icon-outline"
          size="sm"
          onClick={onExportHeatmapToDashboard}
        >
          <TooltipTrigger tooltip="Export Heatmap to Dashboard">
            <BiExport />
          </TooltipTrigger>
        </Button>
        {areaSelection && (
          <Button
            variant="default"
            onClick={() => setAreaSelection(null)}
            size="sm"
          >
            Clear Selection
          </Button>
        )}
      </div>

      <OverlayMessage {...overlayMessageProps}>
        <ResizeObserver onResize={(size) => setChartWidth(size.width)}>
          <Loader isLoading={isLoading}>
            {isRunQueriesMessageDisplayed && (
              <div className="w-full px-[24px] text-[11px] font-medium">
                Add filters and run query
              </div>
            )}

            {heatmapResult && (
              <HeatmapRenderer
                areaSelection={areaSelection || bubbleUpAreaSelection}
                chartData={chartData}
                size={{ width: chartWidth, height: 460 }}
                layoutType="explore"
                unit={heatmapResult.unit}
                numXAxisSplits={NUM_X_AXIS_SPLITS}
                numYAxisSplits={NUM_Y_AXIS_SPLITS}
                onAreaSelectionStart={showContextMenuToggle.off}
                onAreaSelectionChange={(values) => {
                  if (values === null) {
                    showContextMenuToggle.off();
                  } else {
                    showContextMenuToggle.on();
                    setAreaSelection(values);
                  }
                }}
                renderSelectionMenu={(areaSelection, position) => {
                  if (showContextMenuToggle.value) {
                    return (
                      <CompactTooltipContainerV2
                        coords={position}
                        onOutsideClick={close}
                      >
                        <div className="flex flex-col gap-2 rounded-md border bg-background px-2 py-4">
                          <div className="pb-2 text-sm">
                            Drag or resize to change the selection
                          </div>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setBubbleUpAreaSelection(
                                getAreaSelection(areaSelection),
                              );
                              setAreaSelection(null);
                              close();
                              showContextMenuToggle.off();
                            }}
                          >
                            Analyze with K-Lens
                          </Button>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              viewSpans({ areaSelection });
                            }}
                          >
                            View spans
                          </Button>
                          <Button
                            onClick={() => {
                              setAreaSelection(null);
                              close();
                              showContextMenuToggle.off();
                            }}
                            variant="outline"
                            size="sm"
                          >
                            Cancel
                          </Button>
                        </div>
                      </CompactTooltipContainerV2>
                    );
                  }

                  return null;
                }}
              />
            )}
          </Loader>
        </ResizeObserver>
      </OverlayMessage>

      {unitAdjustBubbleSelection ? (
        <TracesHeatmapCharts
          areaSelection={unitAdjustBubbleSelection}
          customerFilter={customerFilter}
          clearSelection={() => {
            setBubbleUpAreaSelection(null);
          }}
          date={date}
          heatmapResult={heatmapResult}
          tracesState={tracesState}
          traceIdSearch={traceIdSearch}
        />
      ) : null}
    </div>
  );
};

export default TracesHeatmap;
