import { SearchState } from 'hooks';
import { DashboardPanelProps, DashboardPanelType } from 'types/Dashboard';
import { SpanFilter } from 'types';

export const getHeatmapDashboardExportQuery = ({
  customerFilter,
  numAttributeBuckets,
  numTimeBuckets,
  ParentSpanIdFilter,
  query,
  spanFilter,
  traceIdSearch,
}: {
  customerFilter?: { key: string; value: string };
  numAttributeBuckets: number;
  numTimeBuckets: number;
  ParentSpanIdFilter?: string;
  query: SearchState;
  spanFilter?: SpanFilter;
  traceIdSearch?: string;
}): string => {
  const queryExpr = {
    customerFilter,
    numAttributeBuckets,
    numTimeBuckets,
    ParentSpanIdFilter,
    query,
    spanFilter,
    traceIdSearch,
  };

  return JSON.stringify(queryExpr);
};

export const getHeatmapDashboardExportPanel = (
  expr: string,
): DashboardPanelProps => {
  return {
    options: {
      legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
      tooltip: { mode: 'single', sort: 'none' },
    },
    targets: [
      {
        datasource: { type: 'loki', uid: '' },
        editorMode: 'code',
        expr,
        key: 'Q-09b106c9-2f31-4591-b382-480e5236903f-0',
        queryType: 'range',
        refId: 'A',
      },
    ],
    type: DashboardPanelType.HEATMAP,
  };
};
