import { SearchState } from 'hooks/useSearch';
import { DashboardPanelType, DateSelection } from 'types';
import {
  DataFrameMeta,
  DataTransformerConfig,
  getRollupByVisualization,
  onPromiseError,
} from 'utils';
import { getMappedGroupByLabels } from 'screens/Events/utils';
import rumDataTransformer from 'utils/DataTransformer/rumDataTransformer';
import getRUMTopViewWithHighestError from './getRUMTopViewWithHighestError';
import { getLegacyFiltersFromFiltersState } from 'hooks/useFiltersState';
import { RumEvent, RumEventType } from '../types';

type Args = {
  dataFormat?: DashboardPanelType;
  date: DateSelection;
  instant?: boolean;
  queries: SearchState[];
  transformer: DataTransformerConfig[];
  eventType: RumEventType;
};

const fetchRumErrorsSeries = async ({
  dataFormat,
  date,
  queries,
  eventType,
}: Args): Promise<{ data: RumEvent[]; cursor: string }[]> => {
  const datasetsQueries = await Promise.all(
    queries.map(async (query) => {
      const searchBarState = query.searchBarState;
      const isInstant = dataFormat === 'timeseries';
      const baseTransformer = rumDataTransformer(!isInstant);

      if (!searchBarState) return undefined;

      const { groupBys, limitToValue, rollUpInSeconds } = query;
      const metricName =
        query.operation === 'distinctcount' ? 'count' : query.operation;

      const meta: DataFrameMeta = {
        aggregate: metricName,
        refId: query.queryKey,
        step: rollUpInSeconds || getRollupByVisualization(date, 'bar'),
        type: dataFormat,
        metricName: metricName,
        labels: getMappedGroupByLabels(groupBys),
        unit: 'number',
      };

      const {
        facetRegex,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
      } = getLegacyFiltersFromFiltersState(query.searchBarState.filters);

      const data = await getRUMTopViewWithHighestError({
        date,
        rollupSeconds: rollUpInSeconds || getRollupByVisualization(date, 'bar'),
        groupByLimit: limitToValue,
        transformer: baseTransformer as DataTransformerConfig[],
        meta,
        facetRegex,
        selectedFacetValuesByName: selectedFacetValuesByName,
        selectedFacetRangeByName: selectedFacetRangeByName,
        eventType,
      }).catch(onPromiseError);
      return data;
    }),
  );
  const datasets = [...datasetsQueries];
  return datasets;
};
export default fetchRumErrorsSeries;
