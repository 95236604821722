import SizeObserver from 'components/SizeObserver';
import React, { ReactElement, useEffect } from 'react';
import ReactGridLayout from 'react-grid-layout';
import {
  DateSelection,
  DashboardPanelProps,
  DashboardPanelType,
  DashboardStreamType,
  DashboardTemplateValueProps,
} from 'types';

import DashboardEditLogEvents from './DashboardEditLogEvents';
import DashboardEditLogs from './DashboardEditLogs';
import DashboardEditMetrics from './DashboardEditMetrics';
import DashboardEditText from './DashboardEditText';
import DashboardEditRows from './DashboardEditRows';
import { useDashboardEditState } from './hooks';
import { DashboardUpdateEditedPanelProps } from '../types';
import { GRAPH_PANELS } from '../utils';

const DashboardEdit = ({
  close,
  date,
  itemLayout,
  panelType,
  panel,
  panelIndex,
  nestedIndex,
  streamType,
  templateValues = {},
  updateEditedPanel,
}: {
  close: () => void;
  date: DateSelection;
  itemLayout?: ReactGridLayout.Layout;
  panelType: DashboardPanelType;
  panel: DashboardPanelProps;
  panelIndex: number;
  nestedIndex?: string;
  streamType?: DashboardStreamType;
  templateValues?: DashboardTemplateValueProps;
  updateEditedPanel: (val: DashboardUpdateEditedPanelProps) => void;
}): ReactElement => {
  const dashboardEditState = useDashboardEditState({
    close,
    date,
    nestedIndex,
    panelIndex,
    streamType,
    updateEditedPanel,
  });
  const { editPanel, stream, initEditPanel } = dashboardEditState;

  useEffect(() => {
    initEditPanel({ itemLayout, panel, panelType });
  }, [initEditPanel, itemLayout, panel, panelType]);

  if (panelType === DashboardPanelType.ROW) {
    return (
      <DashboardEditRows
        close={close}
        dashboardEditState={dashboardEditState}
        panel={panel}
        templateValues={templateValues}
      />
    );
  }

  return (
    <div className="dashboard__panel-modal">
      <SizeObserver>
        {({ width }) => (
          <>
            {GRAPH_PANELS.find((val) => val === panelType) && (
              <>
                {stream === DashboardStreamType.METRIC && (
                  <DashboardEditMetrics
                    baseWidth={width}
                    close={close}
                    dashboardEditState={dashboardEditState}
                    panel={panel}
                    panelType={editPanel.type || panelType}
                    templateValues={templateValues}
                  />
                )}
                {stream === DashboardStreamType.LOG && (
                  <DashboardEditLogs
                    baseWidth={width}
                    close={close}
                    dashboardEditState={dashboardEditState}
                    panel={panel}
                    panelType={editPanel.type || panelType}
                    templateValues={templateValues}
                  />
                )}
              </>
            )}
            {panelType === DashboardPanelType.LOGS && (
              <DashboardEditLogEvents
                baseWidth={width}
                close={close}
                dashboardEditState={dashboardEditState}
                key={panel?.targets[0]?.expr}
                panel={panel}
                panelType={editPanel.type || panelType}
                templateValues={templateValues}
              />
            )}
            {panelType === DashboardPanelType.TEXT && (
              <DashboardEditText
                baseWidth={width}
                close={close}
                dashboardEditState={dashboardEditState}
                panel={panel}
                panelType={editPanel.type || panelType}
              />
            )}
          </>
        )}
      </SizeObserver>
    </div>
  );
};

export default DashboardEdit;
