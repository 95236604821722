import { Heatmap, Loader } from 'components';
import React, { ReactElement, useEffect } from 'react';
import { DashboardPanelComponentProps, DashboardPanelType } from 'types';

import { DashboardPanelNoData } from '../components';
import { useDashboardDataLoader } from '../hooks';
import { checkIfDataNotAvailable, isPanelForecastTimeseries } from '../utils';
import { Forecast, GaugeGraph, Timeseries } from '../Widgets/Graph';

const DashboardPanelChart = ({
  baseWidth,
  dashboardState,
  dashboardTemplateState,
  isInView,
  nestedIndex,
  panel,
  panelIndex,
  panelSize,
}: DashboardPanelComponentProps): ReactElement => {
  const { templateValues } = dashboardTemplateState;
  const { fieldConfig } = panel;

  const dashboardDataLoader = useDashboardDataLoader({
    baseWidth,
    dashboardState,
    dashboardTemplateState,
    isInView,
    nestedIndex,
    panelIndex,
    templateValues,
    type: 'timeseries',
  });
  const isForecast = isPanelForecastTimeseries(fieldConfig?.defaults?.mappings);

  return (
    <Loader isLoading={dashboardDataLoader.isLoading} sizes="small">
      {panel.type === DashboardPanelType.TIMESERIES &&
        dashboardDataLoader.result &&
        isForecast && (
          <Forecast
            dashboardState={dashboardState}
            dashboardDataLoader={dashboardDataLoader}
            panel={panel}
            panelSize={panelSize}
          />
        )}
      {panel.type === DashboardPanelType.TIMESERIES &&
        dashboardDataLoader.result &&
        !isForecast && (
          <Timeseries
            dashboardState={dashboardState}
            dashboardDataLoader={dashboardDataLoader}
            isInView={isInView}
            panel={panel}
            panelSize={panelSize}
          />
        )}
      {panel.type === DashboardPanelType.HEATMAP && (
        <>
          {!checkIfDataNotAvailable(dashboardDataLoader.result) ? (
            <Heatmap
              chartHeight={panelSize.heightContainer}
              chartWidth={panelSize.width}
              grafanaData={
                dashboardDataLoader.result || { data: [], series: [] }
              }
            />
          ) : (
            <DashboardPanelNoData panelSize={panelSize} />
          )}
        </>
      )}
      {panel.type === DashboardPanelType.GAUGE && (
        <GaugeGraph
          dashboardDataLoader={dashboardDataLoader}
          panel={panel}
          panelSize={panelSize}
        />
      )}
    </Loader>
  );
};

export default DashboardPanelChart;
