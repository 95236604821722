import { SelectV2 } from 'components';
import { SelectOption } from 'components/SelectV2';
import { useSearch } from 'hooks';
import { logsRollupOptionsByRange } from 'kfuse-constants';
import React, { useMemo } from 'react';
import { DateSelection, Operation } from 'types';
import { getRollupByVisualization, getRollupToSecond } from 'utils';
import { RumTab } from '../types';
import RumMultiGroup from './RumMultiGroup';
import RumMultiAggregate from './RumMultiAggregate';

export const mapAggregationFunction = (field: string) => {
  const keysToMap: { [key: string]: string } = {
    median: 'percentile50',
    p50: 'percentile50',
    p75: 'percentile75',
    p90: 'percentile90',
    p95: 'percentile95',
    p99: 'percentile99',
  };

  return keysToMap[field] || field;
};

const operationOptions: SelectOption[] = Object.values(Operation)
  .filter(
    (operation) =>
      operation !== Operation.distinctcount && operation !== Operation.heatmap,
  )
  .map((operation) => ({
    label: operation,
    value: mapAggregationFunction(operation),
  }));

type Props = {
  date: DateSelection;
  groupByOptions: SelectOption[];
  measureOptions: SelectOption[];
  search: ReturnType<typeof useSearch>;
  searchesCount: number;
  searchIndex: number;
  rumTab: RumTab;
};

const RumSearchGrouper = ({
  date,
  groupByOptions,
  measureOptions,
  search,
  rumTab,
}: Props) => {
  const { advancedGroupBys, changeRollUpInSeconds, rollUpInSeconds } = search;

  const rumRollupOptionsAuto = useMemo(() => {
    if (rumTab === RumTab.list) {
      return [];
    }
    const rollUpOptions = logsRollupOptionsByRange(date, '1s');
    const autoRollUpInSeconds = getRollupByVisualization(date, 'bar');
    const isAutoExistIndex = rollUpOptions.findIndex(
      (item) => getRollupToSecond(item.value) === autoRollUpInSeconds,
    );
    if (isAutoExistIndex > -1) {
      rollUpOptions[isAutoExistIndex] = {
        ...rollUpOptions[isAutoExistIndex],
        label: `${rollUpOptions[isAutoExistIndex].label} (auto)`,
        value: null,
      };
    }

    return rollUpOptions;
  }, [date, rumTab]);

  if (rumTab === RumTab.list) {
    return null;
  }

  return (
    <div className="rum-search__grouper">
      <div className="rum-search__button-group flex flex-row flex-wrap items-center">
        <RumMultiAggregate
          measureOptions={measureOptions}
          operationOptions={operationOptions}
          search={search}
          rumTab={rumTab}
        />
        <div className="rum-search__button-group__divider flex flex-row items-center">
          <div />
        </div>
        <RumMultiGroup
          addAdvancedGroupBy={search.addAdvancedGroupBy}
          advancedGroupBys={advancedGroupBys}
          changeAdvancedGroupBys={search.changeAdvancedGroupBys}
          groupByOptions={groupByOptions}
          measureOptions={measureOptions}
          operationOptions={operationOptions}
          rumTab={rumTab}
        />
        <div className="rum-search__button-group__divider flex flex-row items-center">
          <div />
        </div>
        <div className="button-group">
          <div className="button-group__item  button-group__item--label">
            roll up every
          </div>
          <div className="button-group__item">
            <SelectV2.Select
              onChange={changeRollUpInSeconds}
              options={rumRollupOptionsAuto}
              value={rollUpInSeconds}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RumSearchGrouper;
