import {
  useTableSearch,
  useTableSort,
  usePaginator,
  TableSearch,
  Table,
  Paginator,
  TooltipTrigger,
  Loader,
  Button,
  ConfirmationModal,
  useModalsContext,
  useToaster,
} from 'components';
import { useRequest } from 'hooks';
import React, { useEffect, useState } from 'react';
import { MdDelete as DeleteIcon } from 'react-icons/md';

import PolicyConfigManagementAdd from './PolicyConfigManagementAdd';
import {
  getPolicyConfigList,
  deletePolicyConfig,
  createPolicyToGroup,
} from './requests';
import { PolicyConfigProps } from './types';

const policyConfigColumns = (
  onDeletePolicyConfig: (policy: PolicyConfigProps) => void,
) => [
  { label: 'Policy Name', key: 'policy' },
  { label: 'Group Name', key: 'group' },
  {
    label: '',
    key: 'actions',
    renderCell: ({ row }: { row: PolicyConfigProps }) => {
      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': '41px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider">
              <div className="alerts__contacts__table__actions">
                <div
                  className="table__cell__actions__item--red"
                  onClick={() => onDeletePolicyConfig(row)}
                >
                  <TooltipTrigger tooltip="Delete">
                    <DeleteIcon
                      className="alerts__contacts__table__actions__icon--delete"
                      size={18}
                    />
                  </TooltipTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

const emptyArr = [];
const PolicyConfigManagement = () => {
  const { addToast } = useToaster();
  const modal = useModalsContext();
  const policyConfigListRequest = useRequest(getPolicyConfigList);
  const deletePolicyConfigRequest = useRequest(deletePolicyConfig);
  const createPolicyToGroupRequest = useRequest(createPolicyToGroup);

  const [policyConfig, setPolicyConfig] = useState<PolicyConfigProps>(null);

  useEffect(() => {
    policyConfigListRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeletePolicyConfig = (row: PolicyConfigProps) => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={
          <span>
            Are you sure you want to delete{' '}
            <span className="font-bold">{row.policy}</span> policy? This action
            cannot be undone.
          </span>
        }
        dataTestId="delete-group-confirmation-modal"
        onCancel={() => modal.pop()}
        onConfirm={() => {
          deletePolicyConfigRequest
            .call({ groupId: row.group_id, policyId: row.policy_id })
            .then(() => {
              modal.pop();
              policyConfigListRequest.call();
            });
        }}
        title="Delete Policy Config"
      />,
    );
  };

  const onSavePolicyConfig = () => {
    if (!policyConfig.policy) {
      addToast({ text: 'Please select the policy', status: 'error' });
      return;
    }
    if (!policyConfig.group) {
      addToast({ text: 'Please select the group', status: 'error' });
      return;
    }
    createPolicyToGroupRequest
      .call(policyConfig.policy, policyConfig.group)
      .then(() => {
        addToast({
          text: 'Policy Config created successfully',
          status: 'success',
        });
        setPolicyConfig(null);
        policyConfigListRequest.call();
      });
  };

  const columns = policyConfigColumns(onDeletePolicyConfig);
  const policiesConfig = policyConfigListRequest.result || emptyArr;
  const tableSearch = useTableSearch({ rows: policiesConfig });
  const tableSort = useTableSort({ columns, rows: tableSearch.searchedRows });
  const paginator = usePaginator({
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  return (
    <div className="mx-auto max-w-[1200px] py-4">
      <div className="flex items-center justify-between pb-2">
        <h2 className="text-lg font-semibold">Policy Config Management</h2>
        <Button
          variant="default"
          size="sm"
          onClick={() => setPolicyConfig({ policy: '', group: '' })}
        >
          Add New Policy Config
        </Button>
      </div>
      {policyConfig && (
        <PolicyConfigManagementAdd
          createPolicyToGroupRequest={createPolicyToGroupRequest}
          onSavePolicyConfig={onSavePolicyConfig}
          policyConfig={policyConfig}
          setPolicyConfig={setPolicyConfig}
        />
      )}
      <Loader isLoading={policyConfigListRequest.isLoading}>
        <TableSearch
          className="dashboard__list__search"
          placeholder="Search Policy Config..."
          tableSearch={tableSearch}
          dataTestId="dashboard-list-search"
        />
        <Table
          className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
          columns={columns}
          dataTestId="alerts-list-table"
          externalTableSort={tableSort}
          isSortingEnabled
          rows={paginator.paginatedRows}
        />
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
      </Loader>
    </div>
  );
};

export default PolicyConfigManagement;
