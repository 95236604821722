import { toNumber } from 'lodash';
import { SLOProps } from 'types';
import { onPromiseError } from 'utils';

import queryTraceService from './queryTraceService';

type Arg = {
  name: string;
  description: string;
  type: string;
  service: { name: string; hash: string };
  objective: number;
  contactPoints: string[];
  latencyThreshold?: number;
  matcher: string;
};

const createSlo = async (payload: Arg): Promise<SLOProps> => {
  const {
    name,
    description,
    type,
    service,
    objective,
    contactPoints,
    latencyThreshold,
    matcher,
  } = payload;

  return queryTraceService<SLOProps, 'createSLO'>(`
   {
      createSLO(
        sloParams: 
          {name: "${name}", 
            description: "${description}",
            type: ${type},
            ${
              type === 'Latency'
                ? `latencyThreshold : ${toNumber(latencyThreshold)},`
                : ''
            }
            service: {name: "${service.name}", hash: "${service.hash}"},
            objective: ${toNumber(objective)},
            contactPoints: ${JSON.stringify(contactPoints)},
            matchers: "${matcher.replace(/"/g, '\\"')}",
          }
      ) {
        id
      }
    }
    `).then((data) => data.createSLO || null, onPromiseError);
};

export default createSlo;
