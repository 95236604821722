import {
  QueryCombinedStatusProps,
  QueryDataProps,
  LogsMetricsAPIRequestMultiProps,
} from 'types';
import { combineLogsChartData, combineLogsChartDataInstant } from 'utils';

import getLogMetricsTimeSeriesLogQL from './getLogMetricsTimeSeriesLogQL';
import getLogMetricsTimeSeriesLogQLV2 from './getLogMetricsTimeSeriesLogQLV2';

const getLogMetricsTimeSeriesLogQLMultiple = async ({
  date,
  format = 'timeseries',
  flatten = false,
  instant = false,
  logQlQueries,
  logqlWithMeta,
  mainTransformer,
  width,
}: LogsMetricsAPIRequestMultiProps) => {
  if (logqlWithMeta) {
    const data = await Promise.all(
      logqlWithMeta.map(({ logql, meta, transformer }) =>
        getLogMetricsTimeSeriesLogQLV2({
          date,
          instant,
          logQL: logql,
          meta,
          transformer,
          width,
        }),
      ),
    );
    if (mainTransformer) {
      const transformed = mainTransformer.reduce(
        (prevData, item) => item.func(prevData),
        data,
      );
      return transformed;
    }
    return data;
  }

  return Promise.all(
    logQlQueries.map((logQlQuery) =>
      getLogMetricsTimeSeriesLogQL({
        date,
        instant,
        logQlQuery,
        width,
        format,
      }),
    ),
  ).then((data) => {
    const rangeKey = instant ? 'instant' : 'range';
    const dataWithQueryKey: QueryDataProps = {};
    const queriesStatus: QueryCombinedStatusProps[] = [];
    const formulaStatus: QueryCombinedStatusProps[] = [];
    data.map((datum, idx) => {
      const queryKey = logQlQueries[idx].queryKey;
      dataWithQueryKey[queryKey] = { [rangeKey]: datum, isLoading: false };
      if (queryKey.startsWith('query_')) {
        queriesStatus.push({
          queryKey: queryKey.split('_')[1],
          isActive: true,
        });
      }
      if (queryKey.startsWith('formula_')) {
        formulaStatus.push({
          queryKey: queryKey.split('_')[1],
          isActive: true,
        });
      }
    });

    if (flatten) {
      if (instant) {
        return combineLogsChartDataInstant(
          dataWithQueryKey,
          queriesStatus,
          formulaStatus,
        );
      } else {
        return combineLogsChartData(
          dataWithQueryKey,
          queriesStatus,
          formulaStatus,
        );
      }
    }

    return dataWithQueryKey;
  });
};

export default getLogMetricsTimeSeriesLogQLMultiple;
