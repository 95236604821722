import dayjs from 'dayjs';
import {
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  TimeSeries,
} from 'types';
import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { buildRumFilter } from './utils';
import { RumEventType } from '../types';
import { SearchState } from 'hooks';

type Args = {
  applicationFilter?: string;
  aggregation: string;
  aggregationField: string;
  facetRegex?: FacetRegexTerm[];
  date: DateSelection;
  advancedGroupBys?: SearchState['advancedGroupBys'];
  eventType: RumEventType;
  idSearch?: string;
  instant?: boolean;
  multiAggregations?: { measure: string | null; operation: string }[];
  rollUpSeconds: number;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
};

const aggregateRumTable = async ({
  applicationFilter,
  aggregation,
  aggregationField,
  date,
  facetRegex,
  advancedGroupBys = [],
  eventType,
  idSearch,
  instant = false,
  multiAggregations,
  rollUpSeconds,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
}: Args): Promise<TimeSeries[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const mappedOperations = multiAggregations?.map(
    (agg) => `{
      field: "${agg.measure || '*'}",
      aggregation: "${agg.measure ? agg.operation : 'count'}",
    }`,
  );

  return queryRumService<TimeSeries[], 'aggregateRumTimeSeries'>(`
  query {
    aggregateTable(
        eventType: ${eventType},
        timestamp: "${endTime.format()}",
        durationSecs: ${durationSecs},
       aggregates:[
        ${mappedOperations ? mappedOperations.join(',') : ''}
       ]
       filter: ${buildRumFilter({
         applicationFilter,
         facetRegex,
         idSearch,
         selectedFacetRangeByName,
         selectedFacetValuesByName,
       })},
       groupBy: ${
         advancedGroupBys.length > 0 && advancedGroupBys[0].by !== '*'
           ? `[${advancedGroupBys.map(
               (groupBy) =>
                 `{
                    field: "${groupBy.by}",
                    limit: ${groupBy.limitToValue},
                    sort: {
                      order: "${groupBy.limitTo}", 
                      aggregation: "${groupBy.sortAggregation ? groupBy.sortAggregation : 'count'}",
                      field: "${groupBy.sortField ? groupBy.sortField : '*'}" 
                    }
                  }`,
             )}
            ]`
           : '[]'
       }
      ) {
        dimensions
        aggregates
      }
  }
  `).then((data) => data?.aggregateTable || [], onPromiseError);
};

export default aggregateRumTable;
