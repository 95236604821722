import {
  AnalyticsChartFullscreenProps,
  AnalyticsChartQueryProps,
  TimeseriesExplorer,
  useAnalyticsChart,
  useModalsContext,
} from 'components';
import {
  SearchState,
  getLegacyFiltersFromFiltersState,
  useRequest,
  useSearches,
} from 'hooks';
import React, { useMemo } from 'react';
import { RumEventType, RumTab } from 'screens/Rum';
import useRumState from 'screens/Rum/hooks/useRumState';
import {
  ChartType,
  DashboardPanelType,
  DateSelection,
  FacetRegexTerm,
  QueryDataProps,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import aggregateRumTable from 'screens/Rum/requests/aggregateRumTable';
import aggregateRumTimeSeries from 'screens/Rum/requests/aggregateRumTimeSeries';
import { isDurationFacet } from 'screens/Rum/utils';
import {
  DataFrameMeta,
  DataTransformerConfig,
  sortAndLimitTimeseries,
} from 'utils/DataTransformer';
import { rumTimeSeriesDataTransformer } from 'utils/DataTransformer/rum';
import rumDataTransformer from 'utils/DataTransformer/rumDataTransformer';
import { getRollupByVisualization, getRollupToSecond } from 'utils/rollup';
import formatTableDuration from 'utils/DataTransformer/tableTransformer/formatTableDuration';

type Props = {
  searches: ReturnType<typeof useSearches>['searches'];
  rumState: ReturnType<typeof useRumState>;
  rumTab: RumTab;
};

const limitToMap: Record<string, 'topk' | 'bottomk'> = {
  top: 'topk',
  bottom: 'bottomk',
};

const fetchAnalytics = async ({
  applicationFilter,
  date,
  dataFormat,
  eventType,
  facetRegex,
  idSearch,
  instant,
  transformer,
  query,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
}: {
  applicationFilter?: string;
  date: DateSelection;
  dataFormat: DashboardPanelType;
  eventType: RumEventType;
  facetRegex: FacetRegexTerm[];
  idSearch: string;
  instant: boolean;
  transformer: DataTransformerConfig[];
  query: SearchState;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
}) => {
  const step = query.rollUpInSeconds
    ? getRollupToSecond(query.rollUpInSeconds as unknown as string)
    : getRollupByVisualization(date, 'bar');
  const params = {
    ...query,
    applicationFilter,
    aggregation: query.measure ? query.operation : 'count',
    aggregationField: query.measure || '*',
    multiAggregations: query.multiAggregations,
    date,
    eventType,
    facetRegex,
    idSearch,
    rollUpSeconds: step,
    selectedFacetRangeByName: selectedFacetRangeByName,
    selectedFacetValuesByName: selectedFacetValuesByName,
  };

  const metricName =
    query.operation === 'distinctcount' ? 'count' : query.operation;

  const metricNames =
    query.multiAggregations?.map((agg) => {
      const aggregation = agg.measure ? agg.operation : 'count';
      const field = agg.measure ? `( ${agg.measure} )` : '';
      return `${aggregation === 'distinctcount' ? 'count' : aggregation}${field}`;
    }) || [];

  const meta: DataFrameMeta = {
    refId: query.queryKey,
    step,
    type: dataFormat,
    labels: query.advancedGroupBys.map((g) => g.by),
    metricName,
    metricNames,
    aggregate: metricName,
    executedDate: date,
    queryType: 'query',
    unit: isDurationFacet(query?.multiAggregations?.[0]?.measure)
      ? 'ns'
      : 'number',
  };
  const rangeKey = instant ? 'instant' : 'range';
  const data =
    dataFormat === DashboardPanelType.TIMESERIES
      ? await aggregateRumTimeSeries({ ...params }).catch((e) => ({
          [rangeKey]: null,
          error: e,
          meta,
        }))
      : await aggregateRumTable({ ...params }).catch((e) => ({
          [rangeKey]: null,
          error: e,
          meta,
        }));
  if (data.error) return data;

  const initialData = { datasets: data, meta };

  const limit = {
    count: query.limitToValue,
    direction: limitToMap[query.limitTo],
  };

  const sortAndLimitTransformer = {
    id: 'sortAndLimitTimeseries',
    func: (dataFrame: any) => sortAndLimitTimeseries(dataFrame, limit),
  };
  const timeSeriesTransformer = {
    id: 'rumTimeSeriesDataTransformer',
    func: rumTimeSeriesDataTransformer,
  };

  const durationFormatterTransformer = {
    id: 'durationFormatter',
    func: (dataFrame: any) =>
      formatTableDuration({
        data: dataFrame,
        options: {
          isDurationField: isDurationFacet,
          fieldParser: (field: string) => {
            const match = field.match(/\(([^)]+)\)/);
            const result = match ? match[1]?.trim() : null;
            return result;
          },
        },
      }),
  };
  if (!instant) {
    transformer.splice(2, 1, timeSeriesTransformer);
  }
  if (instant && dataFormat === DashboardPanelType.TABLE) {
    transformer.splice(1, 0, durationFormatterTransformer);
  }
  const transformed = transformer.reduce(
    (acc: any, currentTransformer: { func: (arg0: any) => any }) => {
      return currentTransformer.func(acc);
    },
    initialData,
  );
  return transformed;
};

const useRumTimeseries = ({ searches, rumState, rumTab }: Props) => {
  const {
    applicationFilter,
    dateState,
    eventType,
    facetRegexState,
    filtersState,
    idSearch,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
  } = rumState;
  const modals = useModalsContext();

  const analyticsChart = useAnalyticsChart({
    date: dateState[0],
    forcedActiveVisualization: rumTab as unknown as DashboardPanelType,
    hideVisualizeToolBar: true,
    transformationConfig: {
      excludeColumns: {
        'Value #a':
          (rumTab as unknown as DashboardPanelType) ===
          DashboardPanelType.TABLE,
      },
    },
    supportedVisualizations: [
      DashboardPanelType.TIMESERIES,
      DashboardPanelType.TOP_LIST,
      DashboardPanelType.TABLE,
      DashboardPanelType.PIECHART,
    ],
  });
  const { activeVisualization } = analyticsChart;

  const aggregateTimeSeriesMultiple = useRequest(
    (args) => {
      return fetchAnalytics(args);
    },
    true,
    true,
  );

  const [date, setDate] = dateState;
  const facetRegex = facetRegexState?.state;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;

  const queries = searches.map((s) => s.state);

  const filter = useMemo(() => {
    return {
      applicationFilter,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
      selectedHcFacetValuesByName: {},
    };
  }, [applicationFilter, selectedFacetRangeByName, selectedFacetValuesByName]);

  const rangeKey = activeVisualization === 'timeseries' ? 'range' : 'instant';

  const onViewFullscreen = ({
    activeChart,
    chartQueries,
    chartFormulas,
    prevChartData,
    unit,
  }: AnalyticsChartFullscreenProps) => {
    modals.push(
      <TimeseriesExplorer
        activeQueries={[...chartQueries, ...chartFormulas]}
        activeChartType={activeChart as ChartType}
        chartData={prevChartData}
        date={date}
        queryType="rum-performance"
        onClose={modals.pop}
        unit={unit}
        eventType={eventType}
      />,
    );
  };

  const analyticsData = useMemo(() => {
    if (!aggregateTimeSeriesMultiple.result) {
      return { chartData: {}, chartQueries: [], formulaQueries: [] };
    }

    const datasets = aggregateTimeSeriesMultiple.result;
    const isLoading = aggregateTimeSeriesMultiple.isLoading;
    const chartData: QueryDataProps = {};
    const chartQueries: AnalyticsChartQueryProps[] = [];

    searches.forEach((search, idx) => {
      const queryId = `query_${search.queryKey}`;
      const dataset = datasets;

      if (!dataset) {
        chartData[queryId] = { [rangeKey]: null, isLoading, meta: null };
        return;
      }

      if (isLoading) {
        chartData[queryId] = { [rangeKey]: null, isLoading, meta: null };
        return;
      }

      if (activeVisualization !== dataset.meta?.type) {
        return;
      }

      if (dataset.error) {
        chartData[queryId] = {
          [rangeKey]: null,
          isLoading,
          error: dataset.error,
          meta: dataset.meta,
        };
        return;
      }

      chartData[queryId] = {
        [rangeKey]: dataset,
        isLoading,
        meta: dataset.meta,
      };
    });

    searches.forEach((search, idx) => {
      const query = search.state;
      const {
        facetRegex,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
      } = getLegacyFiltersFromFiltersState(filtersState.state);
      const filter = {
        applicationFilter,
        facetRegex,
        idSearch,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
      };
      chartQueries.push({
        direction: query.limitTo,
        filter,
        index: idx,
        isActive: query.isActive,
        type: 'query',
        query: query
          ? {
              ...query,
              searchBarState: {
                applicationFilter,
                idSearch,
                filters: filtersState.state,
              },
              aggregateFunction: query.measure ? query.operation : 'count',
              aggregateField: query.measure || '*',
            }
          : null,
        queryKey: query.queryKey,
        steps: query.rollUpInSeconds,
        vectorAggregate:
          (query?.multiAggregations?.[0]?.operation === 'distinctcount'
            ? 'count'
            : query?.multiAggregations?.[0]?.operation) || 'count',
        unit: isDurationFacet(query?.multiAggregations?.[0]?.measure)
          ? 'ns'
          : 'number',
      });
    });

    return {
      chartData,
      chartQueries,
      formulaQueries: [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    aggregateTimeSeriesMultiple.result,
    aggregateTimeSeriesMultiple.isLoading,
    searches,
    rangeKey,
    filter,
    activeVisualization,
    aggregateTimeSeriesMultiple?.error,
  ]);

  const groupBysForTable = useMemo(() => {
    const res: string[] = [];
    queries
      .filter((item) => item.isActive)
      .map((q) => {
        const gr: string[] = [];
        q.groupBys.map((g) => {
          g !== '*' && gr.push(g);
        });
        res.push(...gr);
      });

    return res;
  }, [queries]);

  const loadRumTimeseries = () => {
    const instant = activeVisualization !== 'timeseries';
    const baseTransformers = rumDataTransformer(instant);

    const preparedQueries = searches.map((s) => s.state);

    aggregateTimeSeriesMultiple.call({
      applicationFilter,
      date,
      eventType,
      dataFormat: activeVisualization,
      facetRegex,
      formulas: [],
      idSearch,
      instant,
      query: preparedQueries[0],
      transformer: baseTransformers,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    });
  };

  useDebouncedEffect(
    () => {
      loadRumTimeseries();
    },
    {
      timeout: 200,
      ignoreInitialCall: false,
    },
    [
      applicationFilter,
      activeVisualization,
      date,
      eventType,
      idSearch,
      searches,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    ],
  );

  return {
    activeVisualization,
    analyticsChart,
    analyticsData,
    groupBysForTable,
    onViewFullscreen,
  };
};

export default useRumTimeseries;
