import {
  ChartToolbar,
  TimeseriesRenderer,
  useCursorContextState,
  useThemeContext,
} from 'components';
import ResizeObserver from 'rc-resize-observer';
import React, { ReactElement, useMemo } from 'react';
import {
  combineRangeQueryData,
  getDefaultAnalyticsChartTypes,
  setDateRangeOnChartZoom,
} from 'utils';

import AnalyticsChartToolbar from './AnalyticsChartToolbar';
import { AnalyticsChartProps, AnalyticsChartQueryProps } from './types';
import useAnalyticsChart from './useAnalyticsChart';
import {
  hasChartAdvanceVisualization,
  mapChartTypeWithDashboard,
} from './utils';

const AnalyticsChartSingle = ({
  analyticsChart,
  chartData,
  defaultActiveChart,
  query,
  queries,
  rightToolbar,
  setDate,
  settingOptions,
}: {
  analyticsChart: ReturnType<typeof useAnalyticsChart>;
  chartData: AnalyticsChartProps['chartData'];
  defaultActiveChart?: AnalyticsChartProps['defaultActiveChart'];
  query: AnalyticsChartQueryProps;
  queries: AnalyticsChartQueryProps[];
  rightToolbar: AnalyticsChartProps['rightToolbar'];
  setDate: AnalyticsChartProps['setDate'];
  settingOptions: AnalyticsChartProps['settingOptions'];
}): ReactElement => {
  const { cursorState, setCursorState } = useCursorContextState();
  const { darkModeEnabled } = useThemeContext();

  const oneChartData = useMemo(() => {
    return combineRangeQueryData({
      formulas: query.type === 'formula' ? [query] : [],
      queries: query.type === 'query' ? [query] : [],
      queryData: chartData,
      darkModeEnabled,
      combineTimestamp: false,
    });
  }, [chartData, darkModeEnabled, query]);

  const { date, chartWidth, setChartWidth } = analyticsChart;
  const { isExportDisabled, isViewFullscreenDisabled, isSaveDisabled } = query;

  const defaultChartType = useMemo(() => {
    const dataPointLength = oneChartData?.data[1]?.filter(Boolean)?.length;
    const hasAdvanceVisualization = hasChartAdvanceVisualization([query]);
    return getDefaultAnalyticsChartTypes({
      hasAdvanceVisualization,
      dataPointLength,
    });
  }, [oneChartData?.data, query]);

  const hooks = useMemo(() => {
    return [
      {
        hook: (u) => setDateRangeOnChartZoom(u, setDate),
        type: 'setSelect',
      },
      ...(oneChartData.hooks || []),
    ];
  }, [setDate, oneChartData.hooks]);
  return (
    <>
      <ResizeObserver onResize={(e) => setChartWidth(e.width)}>
        <TimeseriesRenderer
          chartData={oneChartData}
          chartTypes={defaultChartType}
          bands={oneChartData.bands || []}
          chartKey={query.queryKey}
          cursorState={cursorState}
          date={date}
          defaultActiveChart={defaultActiveChart}
          hooks={hooks}
          isLoading={oneChartData.isLoading}
          legend={{ legendType: settingOptions.legendType }}
          renderToolbar={({
            activeChart,
            activeStroke,
            setActiveChart,
            setActiveStroke,
            unit,
          }) => (
            <ChartToolbar
              chartTypes={defaultChartType}
              activeChart={activeChart}
              activeStroke={activeStroke}
              setActiveChart={setActiveChart}
              setActiveStroke={setActiveStroke}
              toolbar={{
                leftToolbar: (
                  <div className="font-semibold">{`${query.type === 'query' ? 'Query:' : 'Formula:'}${query.queryKey}`}</div>
                ),
                rightToolbar: (
                  <AnalyticsChartToolbar
                    onSaveClick={
                      isSaveDisabled === true || !rightToolbar?.onSaveClick
                        ? null
                        : () => rightToolbar.onSaveClick(query)
                    }
                    onExportClick={
                      isExportDisabled === true || !rightToolbar?.onExportClick
                        ? null
                        : () =>
                            rightToolbar.onExportClick({
                              ...query,
                              drawStyle: mapChartTypeWithDashboard[activeChart],
                            })
                    }
                    onViewFullscreen={
                      isViewFullscreenDisabled === true ||
                      !rightToolbar?.onViewFullscreen
                        ? null
                        : () =>
                            rightToolbar.onViewFullscreen({
                              activeChart,
                              chartQueries:
                                query.type === 'query' ? [query] : [],
                              chartFormulas:
                                query.type === 'formula' ? [query] : [],
                              prevChartData: oneChartData,
                              unit,
                            })
                    }
                    onCreateAlert={
                      query.type === 'formula'
                        ? undefined
                        : rightToolbar?.onCreateAlert
                          ? () =>
                              rightToolbar.onCreateAlert({
                                chartQueries:
                                  query.type === 'query' ? [query] : queries,
                                chartFormulas:
                                  query.type === 'formula' ? [query] : [],
                              })
                          : null
                    }
                    saveButtonDisabledReasonIfDisabled={
                      rightToolbar?.saveButtonDisabledReasonIfDisabled
                    }
                  />
                ),
                toolbarMenuType: settingOptions.toolbarChartShowType,
              }}
            />
          )}
          size={{ width: chartWidth, height: 260 }}
          onCursorStateChange={setCursorState}
          tooltipType="compact"
          unit={query.unit}
        />
      </ResizeObserver>
    </>
  );
};

export default AnalyticsChartSingle;
