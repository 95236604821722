import classNames from 'classnames';
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { DashboardStreamType } from 'types/Dashboard';

const streamTypeOptions = [
  { label: 'Metrics', value: DashboardStreamType.METRIC },
  { label: 'Logs', value: DashboardStreamType.LOG },
];

const DashboardEditStreamType = ({
  stream,
  setStream,
}: {
  stream: DashboardStreamType;
  setStream: Dispatch<SetStateAction<DashboardStreamType>>;
}): ReactElement => {
  return (
    <div className="flex">
      <div className="button-group mt-2 mb-2">
        {streamTypeOptions.map(({ label, value }) => {
          return (
            <div
              className={classNames({
                'button-group__item': true,
                'button-group__item--active': stream === value,
              })}
              key={value}
            >
              <button
                className="flex flex--align-items-center"
                onClick={() => setStream(value)}
              >
                {label}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardEditStreamType;
