import { AutocompleteV2 } from 'components';
import Datepicker from 'composite/Datepicker';
import { logsTopBottomCountOptions } from 'kfuse-constants/logsAnalytics';
import React, { ReactElement } from 'react';
import { RefreshCw } from 'react-feather';
import { components } from 'react-select';
import { refreshDate } from 'utils';

import MetricsCardinalityLabel from './MetricsCardinalityLabel';
import MetricsCardinalityMetric from './MetricsCardinalityMetric';
import MetricsCardinalityTable from './MetricsCardinalityTable';
import useMetricsCardinality from './useMetricsCardinality';

const MetricsCardinality = (): ReactElement => {
  const metricsCardinality = useMetricsCardinality();
  const {
    cardinalityQuery,
    date,
    onDateChange,
    updateCardinalityQuery,
    setDate,
  } = metricsCardinality;

  const { limit, type } = cardinalityQuery;
  return (
    <div className="metrics-cardinality">
      <div className="flex flex--justify-content-between ">
        <div className="new-metrics__header__title">Metrics Cardinality</div>
        <div className="new-metrics__header__date-picker">
          <Datepicker
            className="logs__search__datepicker"
            hasStartedLiveTail={false}
            onChange={onDateChange}
            startLiveTail={null}
            quickRangeOptions={[
              { label: 'Last 5 minutes', from: 'now-5m', to: 'now' },
            ]}
            value={date}
          />
          <button
            className="new-metrics__header__refresh-button"
            onClick={() => refreshDate(date, setDate)}
          >
            <RefreshCw size={14} />
          </button>
        </div>
      </div>
      <div className="search__grouper search__button-group">
        <div className="button-group">
          <div className="button-group__item button-group__item--label">
            Show Cardinality
          </div>
          <div className="button-group__item button-group__item--value">
            <AutocompleteV2
              className="autocomplete-container--no-border autocomplete__fixed-height-30"
              components={{
                SingleValue: ({ ...prop }) => {
                  const propLabel = prop.selectProps.value.label;
                  return (
                    <components.SingleValue {...prop}>
                      of {propLabel}
                    </components.SingleValue>
                  );
                },
              }}
              isSearchable={false}
              options={[
                { label: 'label', value: 'label' },
                { label: 'metric', value: 'metric' },
              ]}
              onChange={(value: string) =>
                updateCardinalityQuery('type', value)
              }
              value={type}
            />
          </div>
        </div>
        <div className="search__button-group__divider">
          <div />
        </div>
        {type === 'label' && (
          <MetricsCardinalityLabel metricsCardinality={metricsCardinality} />
        )}
        {type === 'metric' && (
          <MetricsCardinalityMetric metricsCardinality={metricsCardinality} />
        )}
        <div className="button-group">
          <div className="button-group__item button-group__item--label">
            limit to
          </div>
          <div className="button-group__item button-group__item--value">
            <AutocompleteV2
              className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
              components={{ DropdownIndicator: null }}
              isSearchable={false}
              onChange={(val) =>
                updateCardinalityQuery('limit', { ...limit, direction: val })
              }
              options={[
                { label: 'top', value: 'topk' },
                { label: 'bottom', value: 'bottomk' },
              ]}
              value={limit.direction}
            />
          </div>
          <div className="button-group__item button-group__item--value">
            <AutocompleteV2
              className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
              components={{ DropdownIndicator: null }}
              isSearchable={false}
              onChange={(val) =>
                updateCardinalityQuery('limit', { ...limit, count: val })
              }
              options={[
                ...logsTopBottomCountOptions,
                { label: 'All', value: 'all' },
              ]}
              value={limit.count}
            />
          </div>
          {type === 'label' && (
            <>
              <div className="button-group__item button-group__item--label">
                by
              </div>
              <div className="button-group__item button-group__item--value">
                <AutocompleteV2
                  className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
                  components={{ DropdownIndicator: null }}
                  isSearchable={false}
                  onChange={(val) =>
                    updateCardinalityQuery('limit', { ...limit, byKey: val })
                  }
                  options={[
                    { label: 'Series Count', value: 'seriesCount' },
                    { label: 'Value Count', value: 'valueCount' },
                  ]}
                  value={limit.byKey}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <MetricsCardinalityTable metricsCardinality={metricsCardinality} />
    </div>
  );
};

export default MetricsCardinality;
