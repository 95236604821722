import { SearchState } from 'hooks/useSearch';
import { DashboardPanelType, DateSelection } from 'types';
import {
  DataFrame,
  DataFrameMeta,
  DataTransformerConfig,
  eventsDataTransformer,
  getRollupByVisualization,
  sortAndLimitTimeseries,
} from 'utils';
import aggregateTimeSeriesForEvent from './aggregateTimeSeriesForEvent';
import { getMappedGroupByLabels } from '../utils';

type Args = {
  dataFormat?: DashboardPanelType;
  date: DateSelection;
  instant?: boolean;
  queries: SearchState[];
  transformer: DataTransformerConfig[];
};

const limitToMap = {
  top: 'topk',
  bottom: 'bottomk',
};

const aggregateEventTimeSeries = async ({
  dataFormat,
  date,
  instant,
  queries,
  transformer,
}: Args) => {
  const rangeKey = instant ? 'instant' : 'range';
  const datasetsQueries = await Promise.all(
    queries.map(async (query) => {
      const searchBarState = query.searchBarState;
      const instant = dataFormat === 'timeseries';
      const baseTransformer = eventsDataTransformer(!instant);

      if (!searchBarState) return undefined;

      const { measure, groupBys, limitToValue, limitTo, rollUpInSeconds } =
        query;
      const metricName =
        query.operation === 'distinctcount' ? 'count' : query.operation;

      const meta: DataFrameMeta = {
        aggregate: metricName,
        refId: query.queryKey,
        step: rollUpInSeconds || getRollupByVisualization(date, 'bar'),
        type: dataFormat,
        metricName: metricName,
        executedDate: date,
        labels: getMappedGroupByLabels(groupBys),
        unit: 'number',
      };
      const data = await aggregateTimeSeriesForEvent({
        aggregation: query.operation,
        aggregationField: query.measure,
        date,
        groupBys,
        instant,
        rollUpSeconds: rollUpInSeconds,
        transformer: baseTransformer as DataTransformerConfig[],
        returnFormat: dataFormat,
        limitToValue,
        limitTo,
        measure,
        searchBarState,
      }).catch((e) => ({ [rangeKey]: null, error: e, meta }));
      if (data.error) return data;

      const initialData = { datasets: data, meta };
      const limit = {
        count: query.limitToValue,
        direction: limitToMap[query.limitTo],
      };

      const sortAndLimitTransformer = {
        id: 'sortAndLimitTimeseries',
        func: (dataFrame: DataFrame) =>
          sortAndLimitTimeseries(dataFrame, limit),
      };
      transformer.splice(1, 0, sortAndLimitTransformer);
      const reducedResult = transformer.reduce((acc, currentTransformer) => {
        const transformed = currentTransformer.func(acc);
        return transformed;
      }, initialData);
      return reducedResult;
    }),
  );

  const datasets = [...datasetsQueries];
  return datasets;
};

export default aggregateEventTimeSeries;
