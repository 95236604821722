import {
  logqlDataTransformer,
  logqlDataTransformerInstant,
} from './rangeTransformer';
import labelTransformer from './labelTransformer/labelTransformer';
import timeseriesDataTransformer from './visualTransformer/timeseriesDataTransformer';

const logsDataTransformer = (isInstant?: boolean) => {
  if (isInstant) {
    return [
      {
        id: 'prometheusToDataFrame',
        func: logqlDataTransformerInstant,
      },
      {
        id: 'transformToDisplayLabel',
        func: labelTransformer,
      },
    ];
  }

  return [
    {
      id: 'prometheusToDataFrame',
      func: logqlDataTransformer,
    },
    {
      id: 'transformToDisplayLabel',
      func: labelTransformer,
    },
    {
      id: 'transformToTimeseries',
      func: timeseriesDataTransformer,
    },
  ];
};

export default logsDataTransformer;
