import classNames from 'classnames';
import React, { ReactElement, useMemo, useState } from 'react';
import { RefreshCw } from 'react-feather';
import { transformSLOPromql } from 'utils';
import { SLOQueryTemplates } from '../types';
import SLOChart from '../SLOCreate/SLOChart';
import { v4 as uuidV4 } from 'uuid';
import { SLOProps } from 'types';

const SLODetailsHistoryChart = ({
  sloData,
  queryTemplatesForSLOType,
}: {
  sloData: SLOProps;
  queryTemplatesForSLOType: SLOQueryTemplates;
}): ReactElement => {
  const [isHostoricalData, setIsHistoricalData] = useState(false);
  const [reloadChartId, setReloadChartId] = useState(null);

  const getSLOHistoryPromql = () => {
    let numePromql = '';
    let denoPromql = '';
    if (isHostoricalData) {
      denoPromql = transformSLOPromql({
        promql: `${queryTemplatesForSLOType.sliTotalEventsPromQuery} - ${queryTemplatesForSLOType.sliBadEventsPromQuery}`,
        slo: sloData,
        window: '6h',
      });
      numePromql = transformSLOPromql({
        promql: queryTemplatesForSLOType.sliBadEventsPromQuery,
        slo: sloData,
        window: '6h',
      });
    } else {
      denoPromql = transformSLOPromql({
        promql: `${queryTemplatesForSLOType.sliTotalEventsPromRecQuery} - ${queryTemplatesForSLOType.sliBadEventsPromRecQuery}`,
        slo: sloData,
        window: '6h',
      });
      numePromql = transformSLOPromql({
        promql: `${queryTemplatesForSLOType.sliBadEventsPromRecQuery}`,
        slo: sloData,
        window: '6h',
      });
    }

    return [numePromql, denoPromql];
  };

  const promQls = useMemo(
    () => getSLOHistoryPromql(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sloData, isHostoricalData],
  );

  const handleReloadChart = () => {
    setReloadChartId(uuidV4());
  };

  return (
    <div className="slo__details__history-chart">
      <div className="slo__details__history-chart__toolbar">
        <button
          className={classNames({
            button: true,
            'button--blue': isHostoricalData,
          })}
          onClick={() => setIsHistoricalData(!isHostoricalData)}
        >
          Historical Data
        </button>
        <button
          className="new-metrics__header__refresh-button"
          onClick={handleReloadChart}
        >
          <RefreshCw size={14} />
        </button>
      </div>
      <SLOChart
        promQl={`${promQls[1]}`}
        reloadChartId={reloadChartId}
        layoutType="drawer-large"
        legendFormat={['Good']}
        stroke="#14a000"
      />
      <SLOChart
        promQl={`${promQls[0]}`}
        reloadChartId={reloadChartId}
        layoutType="drawer-large"
        legendFormat={['Bad']}
        stroke="#9e0142"
      />
    </div>
  );
};

export default SLODetailsHistoryChart;
