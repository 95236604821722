import classNames from 'classnames';
import { TooltipTrigger } from 'components';
import React, { ReactElement } from 'react';

const detectionList = [
  { label: 'Latency', value: 'latency' },
  { label: 'Availability', value: 'availability' },
];

const SLODetection = ({
  activeDetection,
  isEditMode,
  setActiveDetection,
}: {
  activeDetection: string;
  isEditMode?: boolean;
  setActiveDetection: (activeDetection: string) => void;
}): ReactElement => {
  return (
    <div>
      <TooltipTrigger
        tooltip={isEditMode ? 'Detection Method cannot be edited' : ''}
        className={classNames({
          'slo__type--disabled': isEditMode,
        })}
      >
        <div className="alerts__create__metrics__detection__tabs">
          {detectionList.map(({ label, value }) => (
            <button
              className={classNames({
                alerts__tabs__item: true,
                'alerts__tabs__item--active': value === activeDetection,
                'slo__type--disabled': isEditMode,
              })}
              key={label}
              disabled={isEditMode}
              onClick={() => setActiveDetection(value)}
            >
              {label}
            </button>
          ))}
        </div>
      </TooltipTrigger>
      <div className="alert__create__metrics__detaction_desciption">
        {/* {getDescriptionOfDetection(descDetails)} */}
      </div>
    </div>
  );
};

export default SLODetection;
