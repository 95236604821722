import { SearchState, useSearches } from 'hooks/index';
import useTracesState from 'hooks/useTracesState';
import React, { useEffect, useMemo } from 'react';
import { IoIosWarning } from 'react-icons/io';
import { DateSelection } from 'types/DateSelection';
import { RequestResult } from 'types/Request';
import TracesHeatmap from './TracesHeatmap';
import { Loader } from 'components';

type Props = {
  date: DateSelection;
  customerFilter: {
    key: string;
    value: string;
  };
  traceIdSearch: string;
  tracesState: ReturnType<typeof useTracesState>;
  searches: ReturnType<typeof useSearches>['searches'];
  heatmapRequest: RequestResult<any[], [params: any]>;
  heatmapError: { [key: string]: string };
  heatmapsContainerRef: React.MutableRefObject<HTMLDivElement>;
};

const TracesHeatmapContainer = ({
  customerFilter,
  date,
  tracesState,
  traceIdSearch,
  searches,
  heatmapRequest,
  heatmapError,
  heatmapsContainerRef,
}: Props) => {
  const filteredSearches = useMemo(() => {
    const filteredSearchesArr = searches?.filter(
      (search: SearchState): boolean => search.isActive,
    );
    return filteredSearchesArr;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searches]);

  useEffect(() => {
    searches[0].selectFirstTwoActiveQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-[16px]">
      <Loader isLoading={!heatmapRequest.result && heatmapRequest.isLoading}>
        <>
          {heatmapRequest?.result ? (
            <>
              {filteredSearches?.map((search: SearchState) => {
                const searchQueryKey = search?.queryKey;
                const errorMessage = !!heatmapError[searchQueryKey]
                  ? heatmapError[searchQueryKey]
                  : '';
                const heatmapResults = heatmapRequest?.result;
                const heatmapResultByQueryKey = heatmapResults?.find(
                  (res) => res.queryKey === searchQueryKey,
                );
                const heatmapResData = heatmapResultByQueryKey?.data;

                return (
                  <TracesHeatmap
                    title={
                      filteredSearches.length > 1
                        ? `Query:${search.queryKey}`
                        : ''
                    }
                    customerFilter={customerFilter}
                    date={date}
                    tracesState={tracesState}
                    traceIdSearch={traceIdSearch}
                    search={search}
                    key={search.queryKey}
                    isLoading={heatmapRequest.isLoading}
                    heatmapResult={heatmapResData}
                    heatmapsContainerRef={heatmapsContainerRef}
                    heatmapError={{
                      getHeatmapData: {
                        message: errorMessage,
                      },
                    }}
                  />
                );
              })}
            </>
          ) : (
            <div className="w-full px-[24px] text-[11px] font-medium">
              Add filters and run query
            </div>
          )}
        </>
      </Loader>
    </div>
  );
};

export default TracesHeatmapContainer;
