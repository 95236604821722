import { lowerCase } from 'lodash';
import { onPromiseError } from 'utils';
import { SLOProps } from 'types';
import {
  getStatusAndErrorBudgetInListView,
  transformSLOPromql,
} from 'utils/slos';

import getSloList from './getSloList';
import promqlQuery from './promqlQuery';

const getSloStatusByService = async ({
  serviceHash,
  queryTemplates,
}: {
  serviceHash: string;
  queryTemplates: any;
}): Promise<{
  ok: SLOProps[];
  breached: SLOProps[];
}> => {
  const sloList = await getSloList(serviceHash);

  const datasets = await Promise.all(
    sloList.map(async (slo: SLOProps) => {
      const sloAvailabiltyType = lowerCase(slo.type);

      const errorRatioPromQl = transformSLOPromql({
        promql: queryTemplates[sloAvailabiltyType]?.sloStatusQuery,
        slo,
        window: '30d',
      });

      const budgetPromQl = transformSLOPromql({
        promql: queryTemplates[sloAvailabiltyType]?.sloBudgetRemaining,
        slo,
        window: '30d',
      });

      return await promqlQuery({
        promqlQueries: [budgetPromQl, errorRatioPromQl],
      });
    }),
  );

  if (!datasets) {
    return onPromiseError('Failed to fetch SLO status by service');
  }

  const sloStatusByService: { ok: SLOProps[]; breached: SLOProps[] } = {
    ok: [],
    breached: [],
  };

  sloList.forEach((slo: SLOProps, index: number) => {
    const statusErrorBudget = getStatusAndErrorBudgetInListView(
      datasets[index],
      slo.objective,
    );

    if (statusErrorBudget) {
      slo.statusErrorBudget = statusErrorBudget;
      if (statusErrorBudget.statusColor === 'red') {
        sloStatusByService.breached.push(slo);
      } else {
        sloStatusByService.ok.push(slo);
      }
    }
  });
  return sloStatusByService;
};

export default getSloStatusByService;
