import dayjs from 'dayjs';
import { DateSelection, LogsState, TimeSeries } from 'types';
import {
  DataFrameMeta,
  DataTransformerConfig,
  getAdjustedStartAndEndTimeUnix,
  onPromiseError,
} from 'utils';

import query from './query';
import { buildQuery } from './utils';

type Args = {
  date: DateSelection;
  instant?: boolean;
  facetName?: string;
  logsState?: LogsState;
  meta: DataFrameMeta;
  normalizeFunction?: string;
  rangeAggregate: string;
  transformer: DataTransformerConfig[];
  vectorAggregate: string;
  vectorAggregateGrouping: string[];
};

const getLogMetricsTimeSeriesFacetExplorer = <T>({
  date,
  facetName,
  instant,
  logsState = {},
  meta,
  normalizeFunction,
  rangeAggregate,
  transformer,
  vectorAggregate,
  vectorAggregateGrouping = [],
}: Args): Promise<T> => {
  const { startTimeUnix, endTimeUnix } = date;
  const stepMs = meta.step * 1000;
  const adjusted = getAdjustedStartAndEndTimeUnix({
    date,
    step: `${stepMs}ms`,
  });
  const endTime = dayjs.unix(instant ? endTimeUnix : adjusted.endTimeUnix);

  const durationSecs = endTimeUnix - startTimeUnix;
  const durationMs = durationSecs * 1000;
  const vectorAggregateGroupingString = `${JSON.stringify(vectorAggregateGrouping)}`;
  const logQuery = buildQuery(logsState);

  meta.executedDate = adjusted;
  return query<TimeSeries[], 'getLogMetricsTimeSeries'>(`
    {
      getLogMetricsTimeSeries(
        durationMs: ${durationMs},
        stepMs: ${instant ? durationMs : stepMs}
        lookBackMs: ${stepMs}
        ${facetName ? `facetName: "${facetName}",` : ''}
         ${logQuery !== '{}' ? `logQuery: ${logQuery},` : ''}
        rangeAggregate: "${rangeAggregate}"
        ${normalizeFunction ? `facetNormalizeFunction: ${normalizeFunction}` : ''}
        vectorAggregate: "${vectorAggregate}"
        ${vectorAggregateGrouping.length ? `vectorAggregateGrouping: ${vectorAggregateGroupingString}` : ''}
        timestamp: "${endTime.format()}"
      ) {
        points {
          ts
          value
        }
        tags
      }
    }
  `).then((data) => {
    const initialData = { datasets: data.getLogMetricsTimeSeries, meta };
    const transformed = transformer.reduce(
      (prevData, item) => item.func(prevData),
      initialData,
    );
    return transformed as unknown as T;
  }, onPromiseError);
};

export default getLogMetricsTimeSeriesFacetExplorer;
