import { values, pick } from 'lodash';
import {
  FunctionInfo,
  FunctionCategory,
  FunctionInfoByFunctionNameThenFunctionCategory,
  FunctionName,
} from 'types/MetricsQueryBuilder';

function trimmedAndLowercased(string: string): string {
  return string.trim().toLowerCase();
}

export const extractValueFromSeries = (series: string) => {
  const value = series.match(/".*"/);
  return value ? value[0].replace(/"/g, '') : '';
};

export function doesFunctionInfoMatchSearchTerm(
  functionInfo: FunctionInfo,
  searchTerm: string,
): boolean {
  if (searchTerm.length === 0) return true;
  return trimmedAndLowercased(
    JSON.stringify(
      values(
        pick(
          functionInfo,
          'name',
          'category',
          'description',
          'shortName',
          'algorithm',
          'tolerance',
        ),
      ),
    ),
  ).includes(trimmedAndLowercased(searchTerm));
}

function getFunctionInfoByFunctionNameThenFunctionCategory(
  functionInfos: FunctionInfo[],
): FunctionInfoByFunctionNameThenFunctionCategory {
  const functionInfoByFunctionNameThenFunctionCategory: FunctionInfoByFunctionNameThenFunctionCategory =
    {};

  for (const functionInfo of functionInfos) {
    const { category, name } = functionInfo;
    functionInfoByFunctionNameThenFunctionCategory[category] ||= {};
    functionInfoByFunctionNameThenFunctionCategory[category][name] =
      functionInfo;
  }
  return functionInfoByFunctionNameThenFunctionCategory;
}

export function getFilteredFunctionInfoByFunctionNameThenFunctionCategory({
  functionInfos,
  searchTerm,
  blockedCategories,
  blockedNames,
}: {
  functionInfos: Array<FunctionInfo>;
  searchTerm: string;
  blockedCategories: Array<FunctionCategory>;
  blockedNames?: Array<FunctionName>;
}): FunctionInfoByFunctionNameThenFunctionCategory {
  const filteredFunctionInfos = functionInfos.filter(
    (functionInfo) =>
      !blockedCategories.includes(functionInfo.category) &&
      !blockedNames?.includes(functionInfo.name) &&
      doesFunctionInfoMatchSearchTerm(functionInfo, searchTerm),
  );

  return getFunctionInfoByFunctionNameThenFunctionCategory(
    filteredFunctionInfos,
  );
}
