import anomalyLeft from '../../../images/anomalyLeft.png';
import anomalyRight from '../../../images/anomalyRight.png';
import forecastLeft from '../../../images/forecastLeft.png';
import forecastRight from '../../../images/forecastRight.png';
import outlierLeft from '../../../images/outlierLeft.png';
import outlierRight from '../../../images/outlierRight.png';
import metricLeft from '../../../images/metricLeft.png';
import metricRight from '../../../images/metricRight.png';
import logsAlertLeftImage from '../../../images/logsAlertLeftImage.png';
import LogsAlertRightImage from '../../../images/LogsAlertRightImage.png';

export const createAlertsData: Record<
  string,
  {
    heading: string;
    leftImageUrl: string;
    rightImageUrl: string;
    description: string;
  }
> = {
  Metric: {
    heading: 'Compare values of a metric with a user-defined threshold',
    leftImageUrl: metricLeft,
    rightImageUrl: metricRight,
    description:
      'Metric monitors are useful for a continuous stream of data. Metrics collected via the Kloudfuse or the API can be alerted upon if they cross a threshold over a given period of time.',
  },
  Anomaly: {
    heading:
      'An anomaly alert uses past behavior to detect when a metric is behaving abnormally.',
    leftImageUrl: anomalyLeft,
    rightImageUrl: anomalyRight,
    description:
      'Anomaly monitors detect when a metric is behaving differently than it has in the past, taking into account trends and seasonal day-of-week and time-of-day patterns.',
  },
  Change: {
    heading:
      'A change alert evaluates the difference between a value N minutes ago and now.',
    leftImageUrl: outlierLeft,
    rightImageUrl: outlierRight,
    description:
      'On each alert evaluation It will calculate the raw difference (not absolute value) between the series now and N minutes ago then compute the average/minimum/maximum/sum over the selected period. An alert is triggered when this computed series crosses the threshold.',
  },
  Forecast: {
    heading:
      'A forecast alert predicts the future behavior of a metric and compares it to a static threshold.',
    leftImageUrl: forecastLeft,
    rightImageUrl: forecastRight,
    description:
      'Forecast monitors can be used to predict how a metric will behave in the future. By alerting before a threshold is breached, you can be sure you have the necessary time to address the underlying issues.',
  },
  Outlier: {
    heading:
      'Outlier monitors detect when a member of a group (e.g., hosts, availability zones, partitions) is behaving unusually compared to the rest.',
    leftImageUrl: outlierLeft,
    rightImageUrl: outlierRight,
    description:
      'On each alert evaluation, It will check whether or not all groups are clustered together, exhibiting the same behavior. An alert is triggered whenever at least one group diverges from the rest of the groups.',
  },
  Threshold: {
    heading: 'An alert is triggered whenever a metric crosses a threshold.',
    leftImageUrl: metricLeft,
    rightImageUrl: metricRight,
    description:
      'On each alert evaluation It will calculate the average/minimum/maximum/sum over the selected period and check if it is above/below the threshold. This is the standard alert case where you know what sorts of values are unexpected.',
  },
  Logs: {
    heading: 'Monitor logs gathered by Kloudfuse.',
    leftImageUrl: logsAlertLeftImage,
    rightImageUrl: LogsAlertRightImage,
    description:
      'Log monitors alert when a specified type of log exceeds a user-defined threshold over a given period of time. Common use cases for log monitors include code exception errors or build job notifications.',
  },
  APM: {
    heading: 'Compare an APM metric against a custom-set user threshold.',
    leftImageUrl: metricLeft,
    rightImageUrl: metricRight,
    description:
      'These function similarly to standard metric monitors, but they are specifically designed with features suited for APM metrics. They enable you to set alerts at the service level for metrics such as the number of hits, errors, and various latency indicators.',
  },
};

export const NO_DATA_DESCRIPTION =
  'Notifications for missing data are very useful if you expect this metric to be always reporting data under normal circumstances. For instance if a host with the Agent must be up around the clock, you can expect the metric system.cpu.idle to always report data. You should enable notifications for missing data. On the other hand, if you are monitoring a metric over an auto-scaling group of hosts that may come and go at any time, you will get a lot of notifications when hosts are shut down deliberately. In that case you should not enable notifications for missing data.';
export const EXECUTION_ERROR_DESCRIPTION =
  "Notifications for execution errors or timeouts can be valuable in certain scenarios. If your application's execution should always complete without errors or timeouts, enabling notifications can help you identify potential issues. However, if you're monitoring a distributed system with dynamic scalability, such as an auto-scaling group, you may receive numerous notifications due to expected errors or timeouts during scaling events. In such cases, it might be more suitable to disable notifications for execution errors or timeouts. Consider your application's characteristics and requirements to determine the most effective approach for configuring these notifications.";
export const ANOMALY_OPTION_UNSELECTED_ERROR_MESSAGE = `The chart visualization is only available when the Anomaly algorithm option is selected in the "Set Condition" section. Please ensure you've selected this option to view the chart.`;

export const SLO_ALERT_EXPRESSION_DESCRIPTION =
  'This alert fires only once you’ve consumed 2% of the budget in an hour or 5% of budget in 6h. The alert stops firing in 5m (if it does not consume 2% of the budget) or 30m (if it does not consumer 5% of the budget)';

export const FORECAST_OPTION_UNSELECTED_ERROR_MESSAGE =
  'The chart visualization is only available when the Forecast algorithm option is selected in the "Set Condition" section. Please ensure you have selected this option to view the chart.';
