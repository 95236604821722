import { HeatmapRenderer, Loader } from 'components';
import { getLegacyFiltersFromFiltersState, useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { getHeatmapData } from 'requests';
import { DashboardPanelComponentProps } from 'types';
import {
  getHeatmapCountFills,
  buildHeatmapPath,
  heatmapColorPalette,
  heatmapDataTransformer,
} from 'utils';

let NUM_X_AXIS_SPLITS = 300;
let NUM_Y_AXIS_SPLITS = 100;

const DashboardPanelHeatmap = ({
  dashboardState,
  panel,
  panelSize,
}: DashboardPanelComponentProps): ReactElement => {
  const { date } = dashboardState;
  const heatmapDataRequest = useRequest(getHeatmapData, true, true);

  const fetchData = () => {
    try {
      const parsedExpr = JSON.parse(panel.targets[0].expr);
      const {
        numTimeBuckets,
        numAttributeBuckets,
        customerFilter,
        facetRegex,
        spanFilter,
        traceIdSearch,
        ParentSpanIdFilter,
        query,
      } = parsedExpr;

      NUM_X_AXIS_SPLITS = numTimeBuckets;
      NUM_Y_AXIS_SPLITS = numAttributeBuckets;

      const filters = getLegacyFiltersFromFiltersState(
        query.searchBarState?.filters || [],
      );

      heatmapDataRequest.call({
        attribute: query.measure,
        date,
        numTimeBuckets,
        numAttributeBuckets,
        customerFilter,
        facetRegex,
        keyExists: filters.keyExists,
        selectedFacetValuesByName: filters.selectedFacetValuesByName,
        selectedHcFacetValuesByName: filters.selectedHcFacetValuesByName,
        spanFilter,
        ParentSpanIdFilter,
        traceIdSearch,
        transformer: [
          { id: 'heatmapDataTransformer', func: heatmapDataTransformer },
        ],
      });
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const chartData = useMemo(() => {
    if (!heatmapDataRequest.result) return null;
    return {
      data: [null, heatmapDataRequest.result.data || []],
      series: [
        {
          label: 'Latency',
          paths: buildHeatmapPath({
            disp: {
              fill: {
                lookup: heatmapColorPalette,
                values: getHeatmapCountFills,
              },
            },
          }),
          facets: [
            { scale: 'x', auto: true, sorted: 1 },
            { scale: 'y', auto: true },
          ],
        },
      ],
      minValue: heatmapDataRequest.result.minValue,
      maxValue: heatmapDataRequest.result.maxValue,
      uniqueTimestamps: heatmapDataRequest.result.uniqueTimestamps,
    };
  }, [heatmapDataRequest.result]);

  return (
    <Loader isLoading={heatmapDataRequest.isLoading} sizes="small">
      {chartData ? (
        <HeatmapRenderer
          chartData={chartData}
          size={{ width: panelSize.width, height: panelSize.heightContainer }}
          unit={heatmapDataRequest.result.unit}
          numXAxisSplits={NUM_X_AXIS_SPLITS}
          numYAxisSplits={NUM_Y_AXIS_SPLITS}
          layoutType="dashboard"
        />
      ) : (
        <div></div>
      )}
    </Loader>
  );
};

export default DashboardPanelHeatmap;
