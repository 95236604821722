import { Multiselect, SelectV2 } from 'components';
import { SelectOption } from 'components/SelectV2';
import { useSearch } from 'hooks';
import {
  logsRollupOptionsByRange,
  logsTopBottomCountOptions,
} from 'kfuse-constants';
import React, { useMemo } from 'react';
import { DateSelection, LimitTo, Operation, TracesTab } from 'types';
import { getRollupByVisualization, getRollupToSecond } from 'utils';

const getNextGroupBys = (nextValue: string[]) => {
  if (nextValue.length > 1) {
    if (nextValue[nextValue.length - 1] === '*') {
      return ['*'];
    }

    return nextValue.filter((value) => value !== '*');
  }

  if (nextValue.length === 0) {
    return ['*'];
  }

  return nextValue;
};

const limitToOptions = Object.keys(LimitTo).map((limitTo) => ({
  label: limitTo,
  value: limitTo,
}));

const operationOptions: SelectOption[] = Object.values(Operation)
  .filter((operation) => operation !== Operation.distinctcount)
  .map((operation) => ({
    label: operation,
    value: operation,
  }));

type Props = {
  date: DateSelection;
  groupByOptions: SelectOption[];
  measureOptions: SelectOption[];
  search: ReturnType<typeof useSearch>;
  searchesCount: number;
  searchIndex: number;
  tracesTab: TracesTab;
};

const TracesSearchGrouper = ({
  date,
  groupByOptions,
  measureOptions,
  search,
  tracesTab,
}: Props) => {
  const {
    changeActive,
    changeGroupBys,
    changeLimitTo,
    changeLimitToValue,
    changeMeasure,
    changeOperation,
    changeRollUpInSeconds,
    groupBys,
    limitTo,
    limitToValue,
    measure,
    operation,
    rollUpInSeconds,
  } = search;

  const traceRollupOptionsAuto = useMemo(() => {
    if (tracesTab === TracesTab.list) {
      return [];
    }
    const rollUpOptions = logsRollupOptionsByRange(date, '1s');
    const autoRollUpInSeconds = getRollupByVisualization(date, 'bar');
    const isAutoExistIndex = rollUpOptions.findIndex(
      (item) => getRollupToSecond(item.value) === autoRollUpInSeconds,
    );
    if (isAutoExistIndex > -1) {
      rollUpOptions[isAutoExistIndex] = {
        ...rollUpOptions[isAutoExistIndex],
        label: `${rollUpOptions[isAutoExistIndex].label} (auto)`,
        value: null,
      };
    }

    return rollUpOptions;
  }, [date, tracesTab]);

  const isGroupByEveryThing = useMemo(() => {
    return groupBys.length === 1 && groupBys[0] === '*';
  }, [groupBys]);

  if (tracesTab === TracesTab.list) {
    return null;
  }

  const operationPlaceholder =
    measure === null ? 'Count of' : 'Count unique of';

  const changeGroupBysHandler = (nextValue: string[]) => {
    changeGroupBys(getNextGroupBys(nextValue));
  };

  return (
    <div className="traces-search__grouper">
      <div className="traces-search__button-group">
        <div className="button-group">
          <div className="button-group__item  button-group__item--label">
            Show
          </div>
          <div className="button-group__item button-group__item--value">
            {measure === 'duration_ns' ? (
              <SelectV2.Select
                isAutocompleteEnabled
                onChange={changeOperation}
                options={operationOptions}
                value={operation}
              />
            ) : (
              operationPlaceholder
            )}
          </div>
          <div className="button-group__item">
            <SelectV2.Select
              isAutocompleteEnabled
              onChange={changeMeasure}
              options={measureOptions}
              value={measure}
            />
          </div>
        </div>
        <div className="traces-search__button-group__divider">
          <div />
        </div>
        <div className="button-group button-group--multiselect">
          <div className="button-group__item  button-group__item--label button-group__item--autoHeight">
            by
          </div>
          <div className="button-group__item button-group__item--multiselect">
            <Multiselect
              onChange={changeGroupBysHandler}
              options={groupByOptions}
              placeholder={isGroupByEveryThing ? 'Everything' : null}
              hideValue={isGroupByEveryThing}
              value={groupBys}
            />
          </div>
        </div>
        <div className="traces-search__button-group__divider">
          <div />
        </div>
        <div className="button-group">
          <div className="button-group__item  button-group__item--label">
            limit to
          </div>
          <div className="button-group__item">
            <SelectV2.Select
              onChange={changeLimitTo}
              options={limitToOptions}
              value={limitTo}
            />
          </div>
          <div className="button-group__item">
            <SelectV2.Select
              onChange={changeLimitToValue}
              options={logsTopBottomCountOptions}
              value={limitToValue}
            />
          </div>
        </div>
        <div className="traces-search__button-group__divider">
          <div />
        </div>
        <div className="button-group">
          <div className="button-group__item  button-group__item--label">
            roll up every
          </div>
          <div className="button-group__item">
            <SelectV2.Select
              onChange={changeRollUpInSeconds}
              options={traceRollupOptionsAuto}
              value={rollUpInSeconds}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TracesSearchGrouper;
