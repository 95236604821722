import { GrafanaDatasourceProps } from 'types/Grafana';

export const datasourceNames = [
  { name: 'KfuseLogsDatasource', type: 'loki' },
  { name: 'KfuseDatasource', type: 'prometheus' },
  { name: 'KfuseQLDatasource', type: 'kfuseql' },
];

export const getKfuseDatasource = (
  datasources: GrafanaDatasourceProps[],
  datasourceType: string,
): GrafanaDatasourceProps => {
  const datasourceName = datasourceNames.find(
    (ds) => ds.type === datasourceType,
  );
  const datasource = datasources.find((ds) => ds.name === datasourceName?.name);
  return datasource;
};
