import { useRequest } from 'hooks';
import React, { useEffect } from 'react';

import { getUserList } from './requests';
import UserManagementTable from './UserManagementTable';

const UserManagement = () => {
  const userListRequest = useRequest(getUserList);

  useEffect(() => {
    userListRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto max-w-[1200px] py-4">
      <div className="alerts__contacts__list__header">
        <h2 className="text-lg font-semibold">User Management</h2>
      </div>
      <UserManagementTable userListRequest={userListRequest} />
    </div>
  );
};

export default UserManagement;
