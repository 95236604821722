import {
  TimeseriesRenderer,
  useCursorContextState,
  useThemeContext,
} from 'components';
import React, { ReactElement, useMemo } from 'react';
import {
  mergeTwoTimeSeries,
  setDateRangeOnChartZoom,
  combineRangeQueryData,
} from 'utils';
import { DashboardPanelProps, DashboardPanelType } from 'types';

import { DashboardPanelNoData } from '../../components';
import { useDashboardState, useDashboardDataLoader } from '../../hooks';
import { DashboardPanelComponentProps } from '../../types';
import {
  checkIfDataNotAvailable,
  getPanelStyles,
  mapTimeseriesLegendMode,
} from '../../utils';

const defaultChartTypes = ['Line'];

const Forecast = ({
  dashboardState,
  dashboardDataLoader,
  panel,
  panelSize,
}: {
  dashboardState: ReturnType<typeof useDashboardState>;
  dashboardDataLoader: ReturnType<typeof useDashboardDataLoader>;
  panel: DashboardPanelProps;
  panelSize: DashboardPanelComponentProps['panelSize'];
}): ReactElement => {
  const { cursorState, setCursorState } = useCursorContextState();

  const { date, onDateChange } = dashboardState;
  const { startTimeUnix, endTimeUnix } = date;
  const { options } = panel;
  const { darkModeEnabled } = useThemeContext();

  const mergedEvalAndHistoricalData = useMemo(() => {
    if (!dashboardDataLoader.result) return null;
    if (dashboardDataLoader.result?.length === 0) return null;

    const historicalData = dashboardDataLoader.result?.[0];
    const evaluationData = dashboardDataLoader.result?.[1];
    const forecastTimestampShift = endTimeUnix - startTimeUnix;

    const evaluationTimestamp = evaluationData.data[0];
    const shiftedEvaluationTimestamp = evaluationTimestamp.map(
      (timestamp: number) => timestamp + forecastTimestampShift,
    );
    evaluationData.data[0] = shiftedEvaluationTimestamp;

    if (!evaluationData || !historicalData) return null;
    const mergedData = mergeTwoTimeSeries({
      timeSeries1: evaluationData,
      timeSeries2: historicalData,
    });

    return mergedData;
  }, [dashboardDataLoader.result, endTimeUnix, startTimeUnix]);

  const panelStyles = useMemo(
    () => getPanelStyles(panel.fieldConfig?.defaults),
    [panel],
  );

  const evalCombinedData = useMemo(() => {
    return combineRangeQueryData({
      formulas: [],
      queries: [{ queryKey: 'a', isActive: true }],
      queryData: {
        ['query_a_forecast']: {
          isLoading: false,
          range: mergedEvalAndHistoricalData,
          meta: {
            refId: 'query_a',
            step: 1,
            type: DashboardPanelType.TIMESERIES,
          },
        },
      },
      darkModeEnabled,
    });
  }, [mergedEvalAndHistoricalData, darkModeEnabled]);

  const hooks = useMemo(
    () => [
      {
        hook: (u) => setDateRangeOnChartZoom(u, onDateChange),
        type: 'setSelect',
      },
      ...evalCombinedData.hooks,
    ],
    [onDateChange, evalCombinedData],
  );

  return (
    <>
      {!checkIfDataNotAvailable(mergedEvalAndHistoricalData) ? (
        <TimeseriesRenderer
          chartData={evalCombinedData || { data: [], series: [] }}
          chartTypes={defaultChartTypes}
          cursorState={cursorState}
          date={date || null}
          isLoading={dashboardDataLoader.isLoading}
          hooks={hooks}
          layoutType="dashboard"
          legend={{
            legendHeight: panelSize.height * 0.25,
            legendType: mapTimeseriesLegendMode(options.legend.displayMode),
          }}
          onCursorStateChange={setCursorState}
          size={{
            width: panelSize.width,
            height:
              options.legend.displayMode === 'list'
                ? panelSize.heightContainer - panelSize.heightContainer * 0.25
                : panelSize.heightContainer,
          }}
          styles={panelStyles}
          tooltipType={'compact'}
          unit={panel.fieldConfig.defaults.unit || 'number'}
        />
      ) : (
        <DashboardPanelNoData panelSize={panelSize} />
      )}
    </>
  );
};

export default Forecast;
