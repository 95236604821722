import { onPromiseError } from 'utils';

import fetchJson from './fetchJson';

type Args = {
  evalMs: number;
  labels?: string;
  logQlExpr: string;
  metricType: string;
  name: string;
};

const saveLogsLogQLMetrics = (data: Args): any => {
  const body = {
    name: data.name,
    log_ql_expr: data.logQlExpr,
    metric_type: data.metricType,
    eval_duration_ms: 60000,
  };

  if (data.labels) {
    body.labels = data.labels;
  }

  return fetchJson('/logmetrics', {
    method: 'POST',
    body: JSON.stringify(body),
  }).then((result) => result || {}, onPromiseError);
};

export default saveLogsLogQLMetrics;
