import { Separator } from 'components/shadcn';
import { Datepicker } from 'composite';
import { generateLogPeriodOptions } from 'composite/utils';
import React, { useEffect } from 'react';
import { IoWarningOutline } from 'react-icons/io5';

import LogsFacetExplorerSourceFacets from './LogsFacetExplorerSourceFacets';
import LogsFacetExplorerSources from './LogsFacetExplorerSources';
import useLogsFacetExplorerState from './useLogsFacetExplorerState';
import LogsFacetExplorerMain from './LogsFacetExplorerMain';

const LogsFacetExplorer = () => {
  const logsFacetExplorerState = useLogsFacetExplorerState();
  const {
    date,
    getLogsSourcesRequest,
    selectedSource,
    selectedFacet,
    onDateChange,
  } = logsFacetExplorerState;

  useEffect(() => {
    getLogsSourcesRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto max-w-[1440px] px-4 py-2">
      <div className="flex items-center justify-between pb-2">
        <h2 className="text-lg font-semibold">Facet Explorer</h2>
        {selectedSource && (
          <Datepicker
            value={date}
            onChange={onDateChange}
            quickRangeOptions={generateLogPeriodOptions('now-1d')}
          />
        )}
      </div>
      <LogsFacetExplorerSources
        logsFacetExplorerState={logsFacetExplorerState}
      />
      <Separator orientation="horizontal" className="mt-2" />
      {!selectedSource && (
        <div className="flex h-96 min-h-96 items-center justify-center ">
          <div className="flex flex-col items-center gap-2">
            <IoWarningOutline size={60} className="text-text-secondary" />
            <div className="text-lg font-bold text-text-secondary">
              No source selected, please select a source to view the facets
            </div>
          </div>
        </div>
      )}
      {selectedSource && (
        <div className="metrics-summary__body">
          <LogsFacetExplorerSourceFacets
            logsFacetExplorerState={logsFacetExplorerState}
          />
          {selectedFacet && (
            <LogsFacetExplorerMain
              logsFacetExplorerState={logsFacetExplorerState}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default LogsFacetExplorer;
