import {
  DataFrameMeta,
  DataTransformerConfig,
} from 'utils/DataTransformer/types';
import fetchJson from './fetchJson';
import onPromiseError from 'utils/onPromiseError';
import { DateSelection } from 'types/DateSelection';
import { getAdjustedStartAndEndTimeUnix } from 'utils/getAdjustedStartAndEndTimeUnix';
import { PrometheusDatasetResponse } from 'types/prometheus';

const promqlQueryRangeV3 = <T = PrometheusDatasetResponse>({
  allowFutureTime = false,
  date,
  meta,
  promqlQuery,
  addEncoding,
  transformer,
  useCeilInAdjustingTime = false,
}: {
  allowFutureTime?: boolean;
  date: DateSelection;
  meta: DataFrameMeta;
  promqlQuery: string;
  addEncoding?: boolean;
  transformer: DataTransformerConfig[];
  useCeilInAdjustingTime?: boolean;
}): Promise<T> => {
  const encodedPromqlQuery = addEncoding
    ? encodeURIComponent(promqlQuery)
    : promqlQuery;
  const adjusted = getAdjustedStartAndEndTimeUnix({
    date,
    step: `${meta.step}s`,
    allowFutureTime,
    useCeilInAdjustingTime,
  });
  const { startTimeUnix, endTimeUnix } = adjusted;
  const url = `api/v1/query_range?query=${encodedPromqlQuery}&start=${startTimeUnix}&end=${endTimeUnix}&step=${meta.step}s`;
  meta.executedQueryStr = encodedPromqlQuery;
  meta.executedDate = adjusted;

  return fetchJson(url).then((result: PrometheusDatasetResponse) => {
    if (result?.data?.result) {
      meta.resultType = result.data.resultType;
      const initialData = {
        datasets: result.data.result,
        meta,
      };
      const transformed = transformer.reduce(
        (prevData, item) => item.func(prevData),
        initialData,
      );
      return transformed as unknown as T;
    } else {
      return [] as unknown as T;
    }
  }, onPromiseError);
};

export default promqlQueryRangeV3;
