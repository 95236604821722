import { Loader, Table, useTableSort } from 'components';
import React, { ReactElement, useMemo } from 'react';

const DashboardEditGraphTable = ({
  dataFormatter,
}: {
  dataFormatter: () => any;
}): ReactElement => {
  const tableQueryData = useMemo(() => dataFormatter(), [dataFormatter]);

  const columns = tableQueryData?.columns || [];
  const tableSort = useTableSort({ columns, rows: tableQueryData?.data || [] });

  return (
    <Loader isLoading={tableQueryData?.isLoading}>
      <div className="dashboard-edit__metric__body__top-list">
        {tableQueryData?.data ? (
          <Table
            className="table--bordered table--bordered-cells dashboard__panel__table__table"
            columns={tableQueryData.columns}
            externalTableSort={tableSort}
            isSortingEnabled={true}
            rows={tableSort.sortedRows}
          />
        ) : null}
      </div>
    </Loader>
  );
};

export default DashboardEditGraphTable;
