// https://docs.google.com/spreadsheets/d/15essC10IdyWLPpJBQm5PafBCgjhb9K6dtQslh0ZiSD0/edit?gid=0#gid=0
const sidebarFacets = [
  {
    category: 'core',
    name: 'Service',
    field: 'service',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'core',
    name: 'Env',
    field: 'env',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'core',
    name: 'SDK Version',
    field: 'sdk_version',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'core',
    name: 'Session Plan',
    field: 'session.plan',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'core',
    name: 'Source',
    field: 'source',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'core',
    name: 'Version',
    field: 'version',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view',
    name: 'View Id',
    field: 'view.id',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view',
    name: 'View Name',
    field: 'view.name',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view',
    name: 'View Error Count',
    field: 'view.error.count',
    session_facet: true,
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'view',
    name: 'View Action Count',
    field: 'view.action.count',
    session_facet: true,
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'view',
    name: 'Long Task Count',
    field: 'view.long_task.count',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'view',
    name: 'View Crash Count',
    field: 'view.crash.count',
    session_facet: true,
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'view',
    name: 'In foreground',
    field: 'view.in_foreground',
    session_facet: '',
    view_facet: '',
    action_facet: true,
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'view',
    name: 'View Is Active',
    field: 'view.is_active',
    session_facet: true,
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'view',
    name: 'Loading Type',
    field: 'view.loading_type',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'view',
    name: 'Resource Count',
    field: 'view.resource.count',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'view url',
    name: 'View Path',
    field: 'view.url_path',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view url',
    name: 'View Path Group',
    field: 'view.url_path_group',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view url',
    name: 'View Hash',
    field: 'view.url_hash',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view url',
    name: 'View Host',
    field: 'view.url_host',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view url',
    name: 'View Referrer',
    field: 'view.referrer',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view url',
    name: 'View Referrer Hash',
    field: 'view.referrer_url.url_hash',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view url',
    name: 'View Referrer Host',
    field: 'view.referrer_url.url_host',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view url',
    name: 'View Referrer Path',
    field: 'view.referrer_url.url_path',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view url',
    name: 'View Referrer Path Group',
    field: 'view.referrer_url.url_path_group',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view url',
    name: 'View Referrer Scheme',
    field: 'view.referrer_url.url_scheme',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view url',
    name: 'View Scheme',
    field: 'view.url_scheme',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view url',
    name: 'View URL',
    field: 'view.url',
    session_facet: '',
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'device',
    name: 'Device Type',
    field: 'device.type',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'device',
    name: 'Device Architecture',
    field: 'device.architecture',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'device',
    name: 'Device Brand',
    field: 'device.brand',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'device',
    name: 'Device Model',
    field: 'device.model',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'device',
    name: 'Device Name',
    field: 'device.name',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'geo',
    name: 'Country',
    field: 'geo.country',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'geo',
    name: 'Country Subdivision',
    field: 'geo.country_subdivision',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'geo',
    name: 'Country ISO Code',
    field: 'geo.country_iso_code',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'geo',
    name: 'City',
    field: 'geo.city',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'geo',
    name: 'Continent',
    field: 'geo.continent',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'view timings',
    name: 'Load Event',
    field: 'view.load_event',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'view timings',
    name: 'First Contentful Paint',
    field: 'view.first_contentful_paint',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'view timings',
    name: 'DOM Interactive',
    field: 'view.dom_interactive',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'view timings',
    name: 'DOM Content Loaded',
    field: 'view.dom_content_loaded',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'view timings',
    name: 'DOM Complete',
    field: 'view.dom_complete',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'view timings',
    name: 'First Input Delay',
    field: 'view.first_input_delay',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'view timings',
    name: 'First Input Delay Target Selector',
    field: 'view.first_input_target_selector',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'view timings',
    name: 'Loading Time',
    field: 'view.loading_time',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'view timings',
    name: 'Time To First Byte',
    field: 'view.first_byte',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'view timings',
    name: 'View Time Spent',
    field: 'view.time_spent',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'scroll',
    name: 'Max Scroll Depth',
    field: 'display.scroll.max_depth',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'scroll',
    name: 'Max Scroll Depth (%)',
    field: 'display.scroll.max_depth_pct',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'scroll',
    name: 'Max Scroll Depth Top',
    field: 'display.scroll.max_depth_scroll_top',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'scroll',
    name: 'Max Scroll Height',
    field: 'display.scroll.max_scroll_height',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'scroll',
    name: 'Max Scroll Height Time',
    field: 'display.scroll.max_scroll_height_time',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'core web vitals',
    name: 'Cumulative Layout Shift',
    field: 'view.cumulative_layout_shift',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'core web vitals',
    name: 'Cumulative Layout Shift Target Selector',
    field: 'view.cumulative_layout_shift_target_selector',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'core web vitals',
    name: 'Interaction To Next Paint',
    field: 'view.interaction_to_next_paint',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'core web vitals',
    name: 'Interaction To Next Paint Target Selector',
    field: 'view.interaction_to_next_paint_target_selector',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'core web vitals',
    name: 'Largest Contentful Paint',
    field: 'view.largest_contentful_paint',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'core web vitals',
    name: 'Largest Contentful Paint Target Selector',
    field: 'view.largest_contentful_paint_target_selector',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'frustration',
    name: 'Action Frustration Type',
    field: 'action.frustration.type',
    session_facet: '',
    view_facet: '',
    action_facet: true,
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'frustration',
    name: 'Session Frustration Count',
    field: 'session.frustration.count',
    session_facet: true,
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'frustration',
    name: 'View Frustration Count',
    field: 'view.frustration.count',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'privacy',
    name: 'Replay Privacy Level',
    field: 'privacy.replay_level',
    session_facet: '',
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'session',
    name: 'Session Action Count',
    field: 'session.action.count',
    session_facet: true,
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'session',
    name: 'Session Crash Count',
    field: 'session.crash.count',
    session_facet: true,
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'session',
    name: 'Session Error Count',
    field: 'session.error.count',
    session_facet: true,
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'session',
    name: 'Has Replay',
    field: 'session.has_replay',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'session',
    name: 'Initial View Name',
    field: 'session.initial_view.name',
    session_facet: true,
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'session',
    name: 'Initial View Path',
    field: 'session.initial_view.url_path',
    session_facet: true,
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'session',
    name: 'Initial View Path Group',
    field: 'session.initial_view.url_path_group',
    session_facet: true,
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'session',
    name: 'Session Is Active',
    field: 'session.is_active',
    session_facet: true,
    view_facet: true,
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'session',
    name: 'Last View Name',
    field: 'session.last_view.name',
    session_facet: true,
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'session',
    name: 'Last View Path',
    field: 'session.last_view.url_path',
    session_facet: true,
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'session',
    name: 'Last View Path Group',
    field: 'session.last_view.url_path_group',
    session_facet: true,
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'session',
    name: 'Session Id',
    field: 'session.id',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'session',
    name: 'Session Referrer',
    field: 'session.referrer',
    session_facet: true,
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'session',
    name: 'Session Type',
    field: 'session.type',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'session',
    name: 'Session Time Spent',
    field: 'session.time_spent',
    session_facet: true,
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'session',
    name: 'View Count',
    field: 'session.view.count',
    session_facet: true,
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'browser',
    name: 'Browser Name',
    field: 'browser.name',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'browser',
    name: 'Browser Main Version',
    field: 'browser.version_major',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'browser',
    name: 'Browser Version',
    field: 'browser.version',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'os',
    name: 'OS Name',
    field: 'os.name',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'os',
    name: 'OS Main Version',
    field: 'os.version_major',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'os',
    name: 'OS Version',
    field: 'os.version',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'user',
    name: 'User Email',
    field: 'usr.email',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'user',
    name: 'User Id',
    field: 'usr.id',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'user',
    name: 'User Name',
    field: 'usr.name',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'connectivity',
    name: 'Effective Type',
    field: 'connectivity.effective_type',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'connectivity',
    name: 'Interfaces',
    field: 'connectivity.interfaces',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'connectivity',
    name: 'Status',
    field: 'connectivity.status',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'viewport',
    name: 'Display Viewport Height',
    field: 'display.viewport.height',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: true,
  },
  {
    category: 'viewport',
    name: 'Display Viewport Width',
    field: 'display.viewport.width',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: true,
  },
  {
    category: 'action',
    name: 'Action Name',
    field: 'action.name',
    session_facet: '',
    view_facet: '',
    action_facet: true,
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'action',
    name: 'Action Error Count',
    field: 'action.error.count',
    session_facet: '',
    view_facet: '',
    action_facet: true,
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'action',
    name: 'Action Id',
    field: 'action.id',
    session_facet: '',
    view_facet: '',
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
  {
    category: 'action',
    name: 'Action Loading Time',
    field: 'action.loading_time',
    session_facet: '',
    view_facet: '',
    action_facet: true,
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'action',
    name: 'Action Long Task Count',
    field: 'action.long_task.count',
    session_facet: '',
    view_facet: '',
    action_facet: true,
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'action',
    name: 'Action Resource Count',
    field: 'action.resource.count',
    session_facet: '',
    view_facet: '',
    action_facet: true,
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'action',
    name: 'Action Target',
    field: 'action.target.name',
    session_facet: '',
    view_facet: '',
    action_facet: true,
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'action',
    name: 'Action Type',
    field: 'action.type',
    session_facet: '',
    view_facet: '',
    action_facet: true,
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'resource',
    name: 'Resource Type',
    field: 'resource.type',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'resource',
    name: 'Resource Status',
    field: 'resource.status_code',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'resource',
    name: 'Resource Url',
    field: 'resource.url',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'resource',
    name: 'Resource Size',
    field: 'resource.size',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'resource',
    name: 'Resource Id',
    field: 'resource.id',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'resource',
    name: 'Resource Method',
    field: 'resource.method',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'resource timings',
    name: 'Initial Connection Duration',
    field: 'resource.connect.duration',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'resource timings',
    name: 'DNS Lookup Duration',
    field: 'resource.dns.duration',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'resource timings',
    name: 'Content Download Duration',
    field: 'resource.download.duration',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'resource timings',
    name: 'Time To First Byte Duration',
    field: 'resource.first_byte.duration',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'resource timings',
    name: 'Redirect Duration',
    field: 'resource.redirect.duration',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'resource timings',
    name: 'Secure Connection Duration',
    field: 'resource.ssl.duration',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'resource timings',
    name: 'Content Download Start',
    field: 'resource.download.start',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'resource timings',
    name: 'DNS Lookup Start',
    field: 'resource.dns.start',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'resource timings',
    name: 'Initial Connection Start',
    field: 'resource.connect.start',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'resource timings',
    name: 'Redirect Start',
    field: 'resource.redirect.start',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'resource timings',
    name: 'Resource Duration',
    field: 'resource.duration',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'resource timings',
    name: 'Secure Connection Start',
    field: 'resource.ssl.start',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'resource timings',
    name: 'Time To First Byte Start',
    field: 'resource.first_byte.start',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'error',
    name: 'Error Message',
    field: 'error.message',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error',
    name: 'Error Type',
    field: 'error.type',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error',
    name: 'Error Source',
    field: 'error.source',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error',
    name: 'ANRs & Hangs Duration',
    field: 'freeze.duration',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: true,
  },
  {
    category: 'error',
    name: 'Duration Since Application Start',
    field: 'error.time_since_app_start',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: true,
  },
  {
    category: 'error',
    name: 'Error Category',
    field: 'error.category',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'error',
    name: 'Error File',
    field: 'error.file',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error',
    name: 'Error Handling',
    field: 'error.handling',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error',
    name: 'Error Stack',
    field: 'error.stack',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error',
    name: 'Is Crash',
    field: 'error.is_crash',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'error',
    name: 'Error Id',
    field: 'error.id',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'long task',
    name: 'Long Task Duration',
    field: 'long_task.duration',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: true,
    error_facet: '',
    measure: true,
  },
  {
    category: 'long task',
    name: 'Long Task Id',
    field: 'long_task.id',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: true,
    error_facet: '',
    measure: '',
  },
  {
    category: 'error resource',
    name: 'Error Resource Method',
    field: 'error.resource.method',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error resource',
    name: 'Error Resource Provider',
    field: 'error.resource.provider.name',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error resource',
    name: 'Error Resource Provider Category',
    field: 'error.resource.provider.type',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error resource',
    name: 'Error Resource Provider Domain',
    field: 'error.resource.provider.domain',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error resource',
    name: 'Error Resource Status',
    field: 'error.resource.status_code',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error resource',
    name: 'Error Resource Url',
    field: 'error.resource.url',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error resource',
    name: 'Error Resource Url Host',
    field: 'error.resource.url_host',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error resource',
    name: 'Error Resource Url Path',
    field: 'error.resource.url_path',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'error resource',
    name: 'Error Resource Url Scheme',
    field: 'error.resource.url_scheme',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'issue',
    name: 'Issue Age',
    field: 'issue.age',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: true,
  },
  {
    category: 'issue',
    name: 'Issue First Seen',
    field: 'issue.first_seen',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'issue',
    name: 'Issue First Seen Version',
    field: 'issue.first_seen_version',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'issue',
    name: 'Issue ID',
    field: 'issue.id',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: true,
    measure: '',
  },
  {
    category: 'resource url',
    name: 'Resource Url Host',
    field: 'resource.url_host',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'resource url',
    name: 'Resource Url Path',
    field: 'resource.url_path',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'resource url',
    name: 'Resource Url Path Group',
    field: 'resource.url_path_group',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'resource url',
    name: 'Resource Url Scheme',
    field: 'resource.url_scheme',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'resource provider',
    name: 'Resource Provider',
    field: 'resource.provider.name',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'resource provider',
    name: 'Resource Provider Category',
    field: 'resource.provider.type',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'resource provider',
    name: 'Resource Provider Domain',
    field: 'resource.provider.domain',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: true,
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'vital',
    name: 'Vital Description',
    field: 'vital.description',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'vital',
    name: 'Vital Duration',
    field: 'vital.duration',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: true,
  },
  {
    category: 'vital',
    name: 'Vital Name',
    field: 'vital.name',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'vital',
    name: 'Vital Type',
    field: 'vital.type',
    session_facet: '',
    view_facet: '',
    action_facet: '',
    resource_facet: '',
    long_task_facet: '',
    error_facet: '',
    measure: '',
  },
  {
    category: 'Others',
    name: 'Synthetics Injected',
    field: 'synthetics.injected',
    session_facet: true,
    view_facet: true,
    action_facet: true,
    resource_facet: true,
    long_task_facet: true,
    error_facet: true,
    measure: '',
  },
];

export default sidebarFacets;
