import { themeColorDark, themeColorLight } from 'utils/colors';
import {
  AreaSelectionProps,
  TimeseriesRenderProps,
  UplotExtended,
} from 'types';
import { addEventOverlayDom, clearCanvasByContext } from 'utils';

import {
  defaultAreaSelection,
  onMouseDownForAreaSelection,
  onMouseMoveForAreaSelection,
  onMouseUpForAreaSelection,
} from '../utils/areaSelection';

import { UPlotConfig } from '../types';
import {
  convertToReadableTime,
  heatmapTooltip,
  formatYAxis,
  getRawDataBySeriesIdx,
  getChartYAxisWidth,
} from '../utils';

const getXAixsModulo = (splitNumber: number) => {
  if (splitNumber >= 300) {
    return 30;
  } else if (splitNumber >= 200) {
    return 20;
  } else if (splitNumber >= 100) {
    return 10;
  } else if (splitNumber >= 60) {
    return 5;
  } else {
    return 2;
  }
};

const getYAxisModulo = (splitNumber: number) => {
  if (splitNumber >= 300) {
    return 15;
  } else if (splitNumber >= 200) {
    return 10;
  } else if (splitNumber >= 100) {
    return 5;
  } else if (splitNumber >= 60) {
    return 5;
  } else {
    return 2;
  }
};

const baseHeatmapConfig = ({
  darkModeEnabled,
  utcTimeEnabled,
  unit,
  minValue,
  maxValue,
  numXAxisSplits,
  numYAxisSplits,
  uniqueTimestamps,
}: {
  darkModeEnabled: boolean;
  utcTimeEnabled: boolean;
  unit: string;
  minValue: number;
  maxValue: number;
  numXAxisSplits: number;
  numYAxisSplits: number;
  uniqueTimestamps: number[];
}): UPlotConfig => {
  const { dark06, dark12 } = themeColorDark;
  const { light05, light11 } = themeColorLight;
  const config: UPlotConfig = {
    width: 1000,
    height: 400,
    axes: [
      {
        size: 36,
        stroke: darkModeEnabled ? dark12 : light11,
        grid: {
          stroke: darkModeEnabled ? dark06 : light05,
          width: 0.2,
        },
        ticks: {
          stroke: darkModeEnabled ? dark06 : light05,
          width: 0.2,
        },
        time: true,
        values: (u, vals, space) => {
          const xAxisModulo = getXAixsModulo(numXAxisSplits);
          const adjustedVals = vals.map((val, idx) => {
            if (idx % xAxisModulo === 0 || idx === vals.length - 1) {
              return parseInt(val.toString());
            }
            return null;
          });
          const readableVals = convertToReadableTime({
            vals: adjustedVals,
            utcTimeEnabled,
            allowOddTimeWithSeconds: true,
          });
          return readableVals;
        },
        splits: () => [...uniqueTimestamps],
      },
      {
        size: 60,
        stroke: darkModeEnabled ? dark12 : light11,
        grid: {
          stroke: darkModeEnabled ? dark06 : light05,
          width: 0.2,
        },
        ticks: {
          stroke: darkModeEnabled ? dark06 : light05,
          width: 0.2,
        },
        values: (u, vals, space) => {
          const yAxisModulo = getYAxisModulo(numYAxisSplits);
          const adjustedVals = vals.map((val, idx) => {
            if (idx % yAxisModulo === 0 || idx === vals.length - 1) {
              return parseInt(val.toString());
            }
            return null;
          });

          return formatYAxis(adjustedVals, unit, 2);
        },
        splits: () => {
          const yAxisSplits = [];
          const yAxisSplitIncr = (maxValue - minValue) / numYAxisSplits;
          for (let i = 0; i < numYAxisSplits; i++) {
            yAxisSplits.push(minValue + i * yAxisSplitIncr);
          }
          yAxisSplits.push(
            yAxisSplits[yAxisSplits.length - 1] + yAxisSplitIncr,
          );
          return yAxisSplits;
        },
      },
    ],
    mode: 2,
    ms: 1,
    scales: {
      x: { time: true },
      y: { range: [minValue, maxValue] },
    },
    cursor: {
      focus: { prox: 5 },
      drag: { x: false, y: false, setScale: false },
      x: false,
      y: false,
    },
    legend: { show: false },
    hooks: {},
  };

  config.addHook = (type, hook) => {
    if (!config.hooks[type]) {
      config.hooks[type] = [];
    }
    config.hooks[type]?.push(hook as any);
  };

  return config;
};

export const heatmapConfigBuilder = ({
  chartData,
  darkModeEnabled,
  layoutType,
  utcTimeEnabled,
  unit,
  numXAxisSplits,
  numYAxisSplits,
  width,
  height,
  hooks,
  onAreaSelectionStart,
  onAreaSelectionChange,
}: {
  chartData: TimeseriesRenderProps['chartData'] & {
    uniqueTimestamps: number[];
  };
  darkModeEnabled: boolean;
  layoutType: TimeseriesRenderProps['layoutType'];
  numXAxisSplits: number;
  numYAxisSplits: number;
  utcTimeEnabled: boolean;
  unit: string;
  width: number;
  height: number;
  hooks: TimeseriesRenderProps['hooks'];
  onAreaSelectionStart: VoidFunction;
  onAreaSelectionChange: (values: AreaSelectionProps) => void;
}): UPlotConfig => {
  const newConfig = baseHeatmapConfig({
    darkModeEnabled,
    utcTimeEnabled,
    unit,
    minValue: chartData.minValue,
    maxValue: chartData.maxValue,
    numXAxisSplits,
    numYAxisSplits,
    uniqueTimestamps: chartData.uniqueTimestamps,
  });

  newConfig.width = width;
  newConfig.height = height;
  newConfig.series = [{}, ...chartData.series];

  newConfig.axes[1].size = getChartYAxisWidth({
    maxValue: chartData.maxValue,
    minValue: chartData.minValue,
    unit,
    chartHeight: height,
    type: 'Heatmap',
    scaleDistribution: { type: 'linear' },
    offsetWidth: 16 * window.devicePixelRatio,
  });

  if (hooks) {
    hooks.forEach(({ type, hook }) => {
      newConfig.addHook(type, hook);
    });
  }

  const { eventOverlayCtx, eventOverlayDiv } = addEventOverlayDom(
    width,
    height,
  );

  newConfig.addHook('init', (u: UplotExtended) => {
    u.darkModeEnabled = darkModeEnabled;
    u.layoutType = layoutType;
    u.chartType = 'Line';
    u.getRawData = () => chartData.data;
    u.getRawDataBySeriesIdx = (seriesIndex: number, pointIndex: number) =>
      getRawDataBySeriesIdx(u, seriesIndex, pointIndex);
    u.utcTimeEnabled = utcTimeEnabled;
    u.clearCanvasByContext = clearCanvasByContext;
    u.getTooltipData = heatmapTooltip;

    u.root.childNodes[0].appendChild(eventOverlayDiv);
    u.eventOverlayCtx = eventOverlayCtx;
    u.areaSelection = defaultAreaSelection();

    if (layoutType === 'explore') {
      u.root.addEventListener(
        'mousedown',
        (e) => {
          if (onAreaSelectionStart) {
            onAreaSelectionStart();
          }
          onMouseDownForAreaSelection({ e, u });
        },
        false,
      );
      u.root.addEventListener(
        'mousemove',
        (e) => onMouseMoveForAreaSelection({ e, u }),
        false,
      );
      u.root.addEventListener(
        'mouseup',
        (e) => onMouseUpForAreaSelection({ e, u, onAreaSelectionChange }),
        false,
      );
    }
  });

  return newConfig;
};
