import { ConfirmationModal } from 'components/Modals';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import { DashboardPanelProps, DashboardPanelType } from 'types';

import { DashboardEdit } from '../DashboardEdit';
import { DashboardFullscreenPanel } from '../Fullscreen';
import { useDashboardState, useDashboardTemplateState } from '../hooks';
import {
  getPanelStreamType,
  getTemplateValueWithAll,
  getTemplateValueWithRepeated,
  SUPPORTED_EDIT_PANELS,
  transformTitle,
} from '../utils';

const DashboardPanelHeader = ({
  baseHeight,
  baseWidth,
  className,
  dashboardState,
  dashboardTemplateState,
  disableEditPanel,
  disableDeletePanel,
  nestedIndex,
  onClickHeader,
  onDelete,
  panel,
  panelIndex,
}: {
  baseHeight?: number;
  baseWidth?: number;
  className?: string;
  dashboardState?: ReturnType<typeof useDashboardState>;
  dashboardTemplateState?: ReturnType<typeof useDashboardTemplateState>;
  disableEditPanel?: boolean;
  disableDeletePanel?: boolean;
  nestedIndex?: string;
  onClickHeader: () => void;
  onDelete: () => void;
  onEdit: () => void;
  panel: DashboardPanelProps;
  panelIndex?: number;
}): ReactElement => {
  if (!panel.title) {
    return <></>;
  }

  const { type } = panel;
  const { date, panelSetupModal, updateEditedPanel } = dashboardState;
  const { templateValues, templateOptions, templating } =
    dashboardTemplateState;

  const onEdit = () => {
    const { gridPos } = panel;
    const templateValuesWithAll = getTemplateValueWithAll({
      templateValues,
      templateOptions: templateOptions,
      templating,
    });
    panelSetupModal.push(
      <DashboardEdit
        close={() => panelSetupModal.pop()}
        date={date}
        itemLayout={gridPos}
        panelType={panel.type}
        panel={panel}
        nestedIndex={nestedIndex}
        panelIndex={panelIndex}
        templateValues={templateValuesWithAll}
        streamType={getPanelStreamType(panel)}
        updateEditedPanel={updateEditedPanel}
      />,
    );
  };

  const onViewFullscreen = () => {
    if (panel.type === DashboardPanelType.TIMESERIES) {
      panelSetupModal.push(
        <DashboardFullscreenPanel
          baseHeight={baseHeight}
          baseWidth={baseWidth}
          close={() => panelSetupModal.pop()}
          date={date}
          panel={panel}
          templateValues={templateValues}
        />,
      );
    }
  };

  const onDashboardDelete = () => {
    panelSetupModal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={
          <span>
            Are you sure you want to delete &quot;
            <b className="text--red">{panel.title}</b>&quot; panel? Once
            deleted, it cannot be recovered.
          </span>
        }
        onCancel={() => panelSetupModal.pop()}
        onConfirm={() => {
          onDelete();
          panelSetupModal.pop();
        }}
        title={`Delete Panel`}
      />,
    );
  };

  return (
    <div
      className={classNames({
        'dashboard-panel__draggable-handle': true,
        'dashboard-panel__header': true,
        'dashboard-panel__header--group':
          panel.type === DashboardPanelType.GROUP,
        [className]: className,
      })}
      onClick={onClickHeader}
      data-testid="dashboard-panel-header"
    >
      <div
        className={classNames({
          'dashboard-panel__header__title': true,
          'dashboard-panel__header__info--active': panel.description,
        })}
      >
        {transformTitle({
          title: panel.title || '',
          templateValues: getTemplateValueWithRepeated(templateValues, panel),
          templating,
        })}
      </div>
      <div className="dashboard-panel__header__actions">
        {!disableEditPanel &&
          SUPPORTED_EDIT_PANELS.includes(type) &&
          (!panel.repeatedRowId || panel.repeatedRowId === 0) && (
            <div className="dashboard-panel__header__actions__icon--edit">
              <MdModeEdit onClick={onEdit} />
            </div>
          )}
        {disableDeletePanel ? null : (
          <div className="dashboard-panel__header__actions__icon--delete">
            <MdDelete onClick={onDashboardDelete} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardPanelHeader;
