import { TooltipTrigger } from 'components';
import { Switch } from 'components/shadcn';
import { useLocalStorageToggle } from 'hooks';
import React, { ReactElement, useMemo, useState } from 'react';
import { MdBarChart } from 'react-icons/md';
import { IoIosWarning } from 'react-icons/io';
import { DashboardPanelType, LogsMetricQueryProps, QueryLangType } from 'types';
import { hasQueryAdvanceFunctionMultiple } from 'utils/MetricsLogsQueryBuilder';

import LogsTabs from './LogsTabs';

type Props = {
  isAnalyticsView: boolean;
  showTimelineToggle: ReturnType<typeof useLocalStorageToggle>;
  error: {
    getFacetNames: { message: string };
  };
  queries: LogsMetricQueryProps[];
  queryLangTypeState: ReturnType<typeof useState<string>>;
  tab: string;
};

const LogsTabsContainer = ({
  isAnalyticsView,
  showTimelineToggle,
  error,
  queries,
  queryLangTypeState,
  tab,
}: Props): ReactElement => {
  const [queryLangType, setQueryLangType] = queryLangTypeState;
  const hasAdvanceFunction = useMemo(() => {
    return hasQueryAdvanceFunctionMultiple(queries, queryLangType);
  }, [queries, queryLangType]);

  return (
    <div className="logs__search__bottom">
      <LogsTabs hasAdvanceFunction={hasAdvanceFunction} />
      <div className="logs__search__bottom__right">
        {!showTimelineToggle.value && !isAnalyticsView ? (
          <div className="logs__search__bottom__right__item">
            <button
              className="button button--short"
              onClick={showTimelineToggle.on}
            >
              <div className="button__icon">
                <MdBarChart size={16} />
              </div>
              <div className="button__text">Show Timeline</div>
            </button>
          </div>
        ) : null}
      </div>
      {(tab === DashboardPanelType.TIMESERIES ||
        tab === DashboardPanelType.TOP_LIST ||
        tab === DashboardPanelType.TABLE ||
        tab === DashboardPanelType.PIECHART) && (
        <div className="flex items-center gap-2 pr-4">
          <TooltipTrigger tooltip="FuseQL is developed by Kloudfuse to improve the query experience for logs">
            <label className="text-xs font-semibold text-text-secondary">
              Use FuseQL
            </label>
          </TooltipTrigger>
          <Switch
            size="3"
            checked={queryLangType === QueryLangType.KFUSEQL}
            onCheckedChange={() =>
              setQueryLangType((prev) =>
                prev === QueryLangType.LOGQL
                  ? QueryLangType.KFUSEQL
                  : QueryLangType.LOGQL,
              )
            }
          />
        </div>
      )}
      {error?.getFacetNames && (
        <div className="flex w-full justify-end gap-[4px] pr-[14px]">
          <IoIosWarning
            className="overlay-message__icon-and-message__icon"
            size={16}
          />
          <div className="text-red-500">{error?.getFacetNames?.message}</div>
        </div>
      )}
    </div>
  );
};

export default LogsTabsContainer;
