import {
  RumActionColumnKey,
  RumErrorColumnKey,
  RumLongTaskColumnKey,
  RumResourceColumnKey,
  RumSessionColumnKey,
  RumViewColumnKey,
} from '../RumTableConstants';
import { RumCommonColumnKey } from '../contants';

export const viewDefaultColumns = {
  [RumCommonColumnKey.hasReplay]: 1,
  [RumViewColumnKey.date]: 1,
  ['view.url_path']: 1,
  ['view.loading_type']: 1,
  ['view.largest_contentful_paint']: 1,
  ['geo.country']: 1,
  ['env']: 1,
  ['service']: 1,
  ['browser.name']: 1,
};

export const actionDefaultColumns = {
  [RumCommonColumnKey.hasReplay]: 1,
  [RumActionColumnKey.date]: 1,
  ['application.id']: 1,
  ['view.id']: 1,
  ['view.name']: 1,
  ['action.id']: 1,
  ['action.type']: 1,
  ['action.loading_time']: 1,
  ['action.frustration.type']: 1,
};

export const longTaskDefaultColumns = {
  [RumCommonColumnKey.hasReplay]: 1,
  [RumLongTaskColumnKey.date]: 1,
  ['long_task.id']: 1,
  ['long_task.duration']: 1,
  ['application.id']: 1,
  ['service']: 1,
  ['device.type']: 1,
  ['device.model']: 1,
  ['device.brand']: 1,
  ['session.id']: 1,
};

export const resourcesDefaultColumns = {
  [RumCommonColumnKey.hasReplay]: 1,
  [RumResourceColumnKey.date]: 1,
  ['resource.id']: 1,
  ['resource.duration']: 1,
  ['application.id']: 1,
  ['service']: 1,
  ['device.type']: 1,
  ['device.model']: 1,
  ['device.brand']: 1,
  ['session.id']: 1,
};

export const errorDefaultColumns = {
  [RumCommonColumnKey.hasReplay]: 1,
  ['device.type']: 1,
  [RumErrorColumnKey.date]: 1,
  ['view.url_path']: 1,
  ['error.message']: 1,
  ['error.source']: 1,
  ['error.type']: 1,
  ['error.id']: 1,
  ['application.id']: 1,
  ['version']: 1,
  ['error.service']: 1,
};

export const sessionDefaultColumns = {
  [RumSessionColumnKey.date]: 1,
  ['session.type']: 1,
  ['session.time_spent']: 1,
  ['session.view.count']: 1,
  ['session.error.count']: 1,
  ['session.action.count']: 1,
  ['session.frustration.count']: 1,
  ['session.initial_view.url_path']: 1,
  ['session.last_view.url_path']: 1,
};

export const sortingEnabledColumns = {
  'action.crash_count': 1,
  'action.error.count': 1,
  'action.frustration.count': 1,
  'action.id': 1,
  'action.ids': 1,
  'action.loading_time': 1,
  'action.long_task.count': 1,
  'action.name': 1,
  'action.resource.count': 1,
  'action.target.name': 1,
  'action.type': 1,
  'application.id': 1,
  'browser.name': 1,
  'browser.version': 1,
  'browser.version_major': 1,
  'device.type': 1,
  'display.scroll.max_depth': 1,
  'display.scroll.max_depth_scroll_top': 1,
  'display.scroll.max_scroll_height': 1,
  'display.scroll.max_scroll_height_time': 1,
  'display.viewport.height': 1,
  'display.viewport.width': 1,
  env: 1,
  'error.category': 1,
  'error.fingerprint': 1,
  'error.handling': 1,
  'error.handling_stack': 1,
  'error.id': 1,
  'error.is_crash': 1,
  'error.source': 1,
  'error.source_type': 1,
  'error.time_since_app_start': 1,
  'error.type': 1,
  'freeze.duration': 1,
  'geo.city': 1,
  'geo.continent': 1,
  'geo.country': 1,
  'geo.country_iso_code': 1,
  'geo.country_subdivision': 1,
  'long_task.duration': 1,
  'long_task.id': 1,
  'os.name': 1,
  'os.version': 1,
  'os.version_major': 1,
  'resource.connect.duration': 1,
  'resource.connect.start': 1,
  'resource.dns.duration': 1,
  'resource.dns.start': 1,
  'resource.download.duration': 1,
  'resource.download.start': 1,
  'resource.duration': 1,
  'resource.first_byte.duration': 1,
  'resource.first_byte.start': 1,
  'resource.id': 1,
  'resource.redirect.duration': 1,
  'resource.redirect.start': 1,
  'resource.size': 1,
  'resource.ssl.duration': 1,
  'resource.ssl.start': 1,
  'resource.status_code': 1,
  'resource.transfer_size': 1,
  'resource.type': 1,
  'resource.url': 1,
  service: 1,
  'session.action.count': 1,
  'session.error.count': 1,
  'session.frustration.count': 1,
  'session.has_replay': 1,
  'session.id': 1,
  'session.initial_view_url': 1,
  'session.last_view_url': 1,
  'session.long_task.count': 1,
  'session.resource.count': 1,
  'session.time_spent': 1,
  'session.type': 1,
  'session.view.count': 1,
  source: 1,
  time: 1,
  'usr.email': 1,
  'usr.id': 1,
  'usr.name': 1,
  version: 1,
  'view.action.count': 1,
  'view.crash_count': 1,
  'view.cumulative_layout_shift': 1,
  'view.dom_complete': 1,
  'view.dom_content_loaded': 1,
  'view.dom_interactive': 1,
  'view.error.count': 1,
  'view.first_byte': 1,
  'view.first_contentful_paint': 1,
  'view.first_input_delay': 1,
  'view.frustration.count': 1,
  'view.id': 1,
  'view.interaction_to_next_paint': 1,
  'view.is_active': 1,
  'view.largest_contentful_paint': 1,
  'view.load_event': 1,
  'view.loading_time': 1,
  'view.loading_type': 1,
  'view.long_task.count': 1,
  'view.name': 1,
  'view.referrer': 1,
  'view.referrer_url.url_hash': 1,
  'view.referrer_url.url_host': 1,
  'view.referrer_url.url_path': 1,
  'view.referrer_url.url_scheme': 1,
  'view.resource.count': 1,
  'view.time_spent': 1,
  'view.url': 1,
  'view.url_hash': 1,
  'view.url_host': 1,
  'view.url_path': 1,
  'view.url_scheme': 1,
};

export const isSortingDisabledForRumColumn = (columnKey: string) => {
  return !sortingEnabledColumns[
    columnKey as keyof typeof sortingEnabledColumns
  ];
};
