import {
  AnalyticsChartFullscreenProps,
  AnalyticsChartQueryProps,
  DashboardExport,
  TimeseriesExplorer,
  useAnalyticsChart,
  useModalsContext,
} from 'components';
import {
  FormulaState,
  getLegacyFiltersFromFiltersState,
  SearchState,
  useRequest,
  useSearches,
  useSearchFormulas,
  useTracesState,
} from 'hooks';
import React, { useMemo } from 'react';
import { aggregateTimeSeriesMultipleV2, getSavedTraceMetrics } from 'requests';
import { DashboardPanelType, QueryDataProps, Service, SpanFilter } from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import { getUsedQueryKeysFromFormula } from 'utils';

import {
  DataFrame,
  replaceServiceHashWithLabel,
  traceDataFrameToQueryData,
  traceDataTransformer,
} from 'utils/DataTransformer';
import TracesSaveMetricsModal from './TracesSaveMetricsModal';
import { getTraceDashboardExportPanel } from './utils';

type Props = {
  customerFilter: { key: string; value: string };
  searches: ReturnType<typeof useSearches>;
  searchesFormulas?: ReturnType<typeof useSearchFormulas>;
  serviceByHash: Record<string, Service>;
  tracesState: ReturnType<typeof useTracesState>;
  tracesTab: string;
};

const useTracesTimeseries = ({
  customerFilter,
  searches,
  searchesFormulas,
  serviceByHash,
  tracesState,
  tracesTab,
}: Props) => {
  const {
    dateState,
    keyExistsState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    selectedHcFacetValuesByNameState,
    spanFilters,
    traceIdSearch,
  } = tracesState;
  const modals = useModalsContext();
  const { formulas } = searchesFormulas;

  const analyticsChart = useAnalyticsChart({
    date: dateState[0],
    forcedActiveVisualization: tracesTab as DashboardPanelType,
    hideVisualizeToolBar: true,
    supportedVisualizations: [
      DashboardPanelType.TIMESERIES,
      DashboardPanelType.TOP_LIST,
      DashboardPanelType.TABLE,
      DashboardPanelType.PIECHART,
    ],
  });
  const { activeVisualization } = analyticsChart;

  const getSavedTraceMetricsRequest = useRequest(getSavedTraceMetrics);

  const aggregateTimeSeriesMultipleV2Request = useRequest(
    (args) =>
      aggregateTimeSeriesMultipleV2(args).then(traceDataFrameToQueryData),
    true,
    true,
  );
  const [date, setDate] = dateState;
  const keyExists = keyExistsState.state;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
  const selectedHcFacetValuesByName = selectedHcFacetValuesByNameState.state;
  const { spanFilter } = spanFilters;
  const queries = searches.map((s) => s.state);

  const filter = useMemo(() => {
    return {
      keyExists,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
      selectedHcFacetValuesByName: {},
      spanFilter,
    };
  }, [
    keyExists,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
    spanFilter,
  ]);

  const rangeKey = activeVisualization === 'timeseries' ? 'range' : 'instant';
  const openExportToDashboardModal = ({
    drawStyle,
    index,
    type,
  }: AnalyticsChartQueryProps & { drawStyle: string }) => {
    const queries = searches.map((s) => s.state);
    const formulaStates = formulas.map((f) => f.state);

    const outputQuery: {
      customerFilter?: { key: string; value: string };
      query?: SearchState;
      formula?: FormulaState;
      queries?: SearchState[];
      spanFilter?: SpanFilter;
    } = {
      spanFilter,
    };
    if (type === 'query') {
      outputQuery.query = queries[index];
    } else {
      const formula = formulaStates[index];
      const usedQueryKeys = getUsedQueryKeysFromFormula(formula.expression);
      const filteredUsedQueries = queries.filter((q) =>
        usedQueryKeys.includes(q.queryKey),
      );
      outputQuery.formula = formula;
      outputQuery.queries = filteredUsedQueries;
    }
    if (customerFilter) {
      outputQuery.customerFilter = customerFilter;
    }

    const expr = JSON.stringify(outputQuery);
    modals.push(
      <DashboardExport
        closeModal={modals.pop}
        date={date}
        drawStyle={drawStyle}
        panel={getTraceDashboardExportPanel(expr)}
      />,
    );
  };

  const openSaveMetricModal = ({ index }: AnalyticsChartQueryProps) => {
    const search = searches[index];
    modals.push(
      <TracesSaveMetricsModal
        customerFilter={customerFilter}
        getSavedTraceMetricsRequest={getSavedTraceMetricsRequest}
        keyExists={keyExists}
        selectedFacetRangeByName={selectedFacetRangeByName}
        selectedFacetValuesByName={selectedFacetValuesByName}
        selectedHcFacetValuesByName={selectedHcFacetValuesByName}
        spanFilter={spanFilter}
        search={search}
        traceIdSearch={traceIdSearch}
      />,
    );
  };

  const onViewFullscreen = ({
    activeChart,
    chartQueries,
    chartFormulas,
    prevChartData,
    unit,
  }: AnalyticsChartFullscreenProps) => {
    modals.push(
      <TimeseriesExplorer
        activeQueries={[...chartQueries, ...chartFormulas]}
        activeChartType={activeChart}
        chartData={prevChartData}
        date={date}
        queryType="trace"
        onClose={modals.pop}
        unit={unit}
      />,
    );
  };

  const analyticsData = useMemo(() => {
    if (!aggregateTimeSeriesMultipleV2Request.result) {
      return {
        chartData: {
          ['query_a']: {
            isLoading: aggregateTimeSeriesMultipleV2Request.isLoading,
            meta: null,
          },
        },
        chartQueries: [{ queryKey: 'a', isActive: true }],
        formulaQueries: [],
      };
    }

    if (aggregateTimeSeriesMultipleV2Request.error) {
      return { chartData: {}, chartQueries: [], formulaQueries: [] };
    }

    const datasets = aggregateTimeSeriesMultipleV2Request.result;
    const isLoading = aggregateTimeSeriesMultipleV2Request.isLoading;
    const chartData: QueryDataProps = {};
    const chartQueries: AnalyticsChartQueryProps[] = [];
    const formulaQueries: AnalyticsChartQueryProps[] = [];

    searches.forEach((search, idx) => {
      const queryId = `query_${search.queryKey}`;
      const dataset = datasets[queryId];

      if (!dataset) {
        chartData[queryId] = { [rangeKey]: null, isLoading, meta: null };
        return;
      }

      if (isLoading) {
        chartData[queryId] = { [rangeKey]: null, isLoading, meta: null };
        return;
      }

      if (activeVisualization !== dataset.meta?.type) {
        return;
      }

      if (dataset.error) {
        chartData[queryId] = {
          [rangeKey]: null,
          isLoading,
          error: dataset.error,
          meta: dataset.meta,
        };
        return;
      }

      const isTimeseries = dataset.meta?.type === 'timeseries';
      chartData[queryId] = {
        [rangeKey]: isTimeseries ? dataset.data : dataset,
        isLoading,
        meta: dataset.meta,
      };
    });

    searches.forEach((search, idx) => {
      const query = search.state;
      const { filters } = query.searchBarState;
      const { keyExists, selectedFacetRangeByName, selectedFacetValuesByName } =
        getLegacyFiltersFromFiltersState(filters);
      const filter = {
        customerFilter,
        keyExists,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        spanFilter,
      };
      chartQueries.push({
        direction: query.limitTo,
        filter,
        index: idx,
        isActive: query.isActive,
        type: 'query',
        query,
        queryKey: query.queryKey,
        steps: query.rollUpInSeconds,
        vectorAggregate:
          query.operation === 'distinctcount' ? 'count' : query.operation,
        unit: query.measure === 'duration_ns' ? 'ns' : 'number',
      });
    });

    const query1 = searches[0].state;
    formulas.forEach((f, idx) => {
      const formula = f.state;
      const formulaId = `formula_${formula.queryKey}`;
      const dataset = datasets[formulaId];

      if (!dataset) {
        chartData[formulaId] = { [rangeKey]: null, isLoading, meta: null };
        return;
      }

      if (isLoading) {
        chartData[formulaId] = { [rangeKey]: null, isLoading, meta: null };
        return;
      }

      if (activeVisualization !== dataset.meta?.type) {
        return;
      }

      if (dataset.error) {
        chartData[formulaId] = {
          [rangeKey]: null,
          isLoading,
          error: dataset.error,
          meta: dataset.meta,
        };
        return;
      }

      const isTimeseries = dataset.meta?.type === 'timeseries';
      chartData[formulaId] = {
        [rangeKey]: isTimeseries ? dataset.data : dataset,
        isLoading,
        meta: dataset.meta,
      };
    });

    formulas.forEach((formula, idx) => {
      formulaQueries.push({
        direction: 'top',
        filter: {
          ...filter,
          customerFilter,
        },
        index: idx,
        formula,
        isActive: formula.isActive,
        isExportDisabled: false,
        isSaveDisabled: true,
        type: 'formula',
        queries: searches.map((s) => s.state),
        queryKey: formula.queryKey,
        steps: query1.rollUpInSeconds,
        unit: 'number',
      });
    });

    return { chartData, chartQueries, formulaQueries };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    aggregateTimeSeriesMultipleV2Request.result,
    aggregateTimeSeriesMultipleV2Request.isLoading,
    customerFilter,
    searches,
    serviceByHash,
    formulas,
    rangeKey,
    filter,
    activeVisualization,
  ]);

  const groupBysForTable = useMemo(() => {
    const res: string[] = [];
    queries
      .filter((item) => item.isActive)
      .map((q) => {
        const gr: string[] = [];
        q.groupBys.map((g) => {
          g !== '*' && gr.push(g.replace(/\./g, '_'));
        });
        const isLabel = res.find((g) => g === 'label');
        if (!isLabel && gr.length === 0) {
          gr.push('label');
        }

        if (gr.includes('service_hash')) {
          gr[gr.indexOf('service_hash')] = 'service';
        }

        res.push(...gr);
      });

    return res;
  }, [queries]);

  const loadTraceTimeseries = () => {
    const instant = activeVisualization !== 'timeseries';
    const baseTransformers = traceDataTransformer(instant);
    baseTransformers.splice(1, 0, {
      id: 'replaceServiceHashWithLabel',
      func: (dataFrame: DataFrame) =>
        replaceServiceHashWithLabel(dataFrame, serviceByHash),
    });

    const preparedQueries = searches
      .map((s) => (s.searchBarState ? s.state : undefined))
      .filter(Boolean);

    aggregateTimeSeriesMultipleV2Request.call({
      customerFilter,
      date,
      dataFormat: activeVisualization,
      formulas: formulas.map((f) => f.state),
      instant,
      queries: preparedQueries,
      spanFilter,
      transformer: baseTransformers,
    });
  };

  useDebouncedEffect(
    () => {
      loadTraceTimeseries();
    },
    100,
    [
      activeVisualization,
      customerFilter,
      date,
      keyExists,
      searches,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
      selectedHcFacetValuesByName,
      spanFilter,
    ],
  );

  useDebouncedEffect(
    () => {
      // if there are no formulas, no need to check if they are valid as formulas.every will return true
      if (formulas.length === 0) return;
      const isAllValid = formulas.every((f) => f.isValid === true);
      if (!isAllValid) return;
      loadTraceTimeseries();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    100,
    [formulas],
  );

  return {
    activeVisualization,
    analyticsChart,
    analyticsData,
    getSavedTraceMetricsRequest,
    groupBysForTable,
    aggregateTimeSeriesMultipleV2Request,
    openExportToDashboardModal,
    openSaveMetricModal,
    onViewFullscreen,
  };
};

export default useTracesTimeseries;
