import { TooltipTrigger, useToaster } from 'components';
import React, { MouseEvent, ReactElement, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MdDelete as DeleteIcon,
  MdModeEdit as EditIcon,
  MdNotificationsOff as MuteIcon,
} from 'react-icons/md';
import { RiFileCopyFill as CopyIcon } from 'react-icons/ri';

import {
  editLogAlert,
  editMetricAlert,
  editSLOAlert,
  editAPMAlert,
} from './utils';
import { DeleteRuleProps, RuleProps, RuleType } from './types';

const AlertsListActions = ({
  onDeleteAlertsRule,
  onMuteClick,
  rule,
}: {
  onDeleteAlertsRule: (val: DeleteRuleProps) => void;
  onMuteClick: (
    row: RuleProps,
    muteRef: React.RefObject<HTMLSpanElement>,
  ) => void;
  rule: RuleProps;
}): ReactElement => {
  const navigate = useNavigate();
  const { addToast } = useToaster();
  const isNotSloAlert = rule.ruleData.length > 2;
  const muteRef = useRef(null);

  const onEditAlert = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    let editURL = '';

    switch (rule.annotations?.ruleType) {
      case RuleType.SLO:
        editURL = editSLOAlert(rule);
        break;
      case RuleType.METRICS:
        editURL = editMetricAlert(rule, addToast);
        break;
      case RuleType.LOGS:
        editURL = editLogAlert(rule, addToast);
        break;
      case RuleType.APM:
        editURL = editAPMAlert(rule, addToast);
        break;
      default:
        editURL = editMetricAlert(rule, addToast);
    }

    navigate(editURL, { state: rule });
  };

  const onCopyAlert = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    let copyURL = '';

    if (rule.annotations?.ruleType === RuleType.LOGS) {
      copyURL = editLogAlert(rule, addToast);
    }

    if (rule.annotations?.ruleType === RuleType.APM) {
      copyURL = editAPMAlert(rule, addToast);
    }

    if (!copyURL) {
      copyURL = editMetricAlert(rule, addToast);
    }

    copyURL += '&copy=true';
    const copyRule = { ...rule, uid: '', name: `Copy of ${rule.name}` };
    navigate(copyURL, { state: copyRule });
  };

  return (
    <div
      className="table__row__actions"
      style={{ '--table-actions-width': isNotSloAlert ? '142px' : '74px' }}
    >
      <div className="table__row__actions--hidden">
        <div className="table__row__actions__slider">
          <div className="alerts__contacts__table__actions ">
            <div
              className="table__cell__actions__item--blue"
              data-testid="action-mute"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onMuteClick(rule, muteRef);
              }}
            >
              <TooltipTrigger
                tooltip={rule.mute === 'Muted' ? 'Unmute Alert' : 'Mute Alert'}
              >
                <span ref={muteRef}>
                  <MuteIcon
                    className="alerts__contacts__table__actions__icon--edit"
                    size={18}
                    style={
                      rule.mute === 'Muted' ? { opacity: 0.5 } : { opacity: 1 }
                    }
                  />
                </span>
              </TooltipTrigger>
            </div>
            {isNotSloAlert && (
              <div
                className="table__cell__actions__item--blue"
                data-testid="action-copy"
                onClick={onCopyAlert}
              >
                <TooltipTrigger tooltip="Copy">
                  <CopyIcon
                    className="alerts__contacts__table__actions__icon--edit"
                    size={18}
                  />
                </TooltipTrigger>
              </div>
            )}
            {isNotSloAlert && (
              <div
                className="table__cell__actions__item--blue"
                data-testid="action-edit"
                onClick={onEditAlert}
              >
                <TooltipTrigger tooltip="Edit">
                  <EditIcon
                    className="alerts__contacts__table__actions__icon--edit"
                    size={18}
                  />
                </TooltipTrigger>
              </div>
            )}
            {isNotSloAlert && (
              <div
                className="table__cell__actions__item--red"
                data-testid="action-delete"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDeleteAlertsRule({
                    folderName: rule.groupFile,
                    folderUid: rule.folderUid,
                    groupName: rule.group,
                    uid: rule.uid,
                  });
                }}
              >
                <TooltipTrigger tooltip="Delete">
                  <DeleteIcon
                    className="alerts__contacts__table__actions__icon--delete"
                    size={18}
                  />
                </TooltipTrigger>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertsListActions;
