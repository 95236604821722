import { DateFormatter, Icon } from 'components';
import { dateTimeFormatWithMilliseconds } from 'kfuse-constants';
import React from 'react';
import { RumCommonColumnKey, RumActionWithErrorsColumnKey } from './contants';
import {
  RumActionEvent,
  RUMActionsWithErrorsEvent,
  RumViewEvent,
  RumErrorEvent,
  RumLongTaskEvent,
  RumResourceEvent,
  RumSessionEvent,
  RumEventWithTabToOpen,
} from './types';
import { isNullOrUndefined, formatDurationNs, formatFileSize } from 'utils';
import {
  RumViewColumnKey,
  rumDynamicActionTableColumns,
  rumDynamicErrorTableColumns,
  rumDynamicLongTaskTableColumns,
  rumDynamicResourceTableColumns,
  rumDynamicSessionTableColumns,
  rumDynamicViewTableColumns,
} from './RumTableConstants';

type RenderCellPropsOfView = {
  row: RumViewEvent;
  value: any;
};

type RenderCellPropsOfAction = {
  row: RumActionEvent;
  value: any;
};

type RenderCellPropsOfLongTask = {
  row: RumLongTaskEvent;
  value: any;
};

type RenderCellPropsOfResource = {
  row: RumResourceEvent;
  value: any;
};

type RenderCellPropsOfError = {
  row: RumErrorEvent;
  value: any;
};

type RenderCellPropsOfSession = {
  row: RumSessionEvent;
  value: any;
};

type RenderCellPropsOfActionsWithErrors = {
  row: RUMActionsWithErrorsEvent;
  value: any;
};

const hasReplayColumn = (
  setActiveRumEvent?: (rumEvent: RumEventWithTabToOpen) => void,
) => ({
  key: RumCommonColumnKey.hasReplay,
  label: 'Has Replay',
  renderCell: ({ row }: RenderCellPropsOfAction) => {
    const value =
      row.data[RumCommonColumnKey.hasReplay as keyof typeof row.data] ===
      'true';
    return value ? (
      <div
        onClick={(e) => {
          if (!setActiveRumEvent) {
            return;
          }
          e.preventDefault();
          e.stopPropagation();
          setActiveRumEvent({
            ...row,
            openReplay: true,
          });
        }}
        className="cursor-pointer flex size-full items-center justify-center"
      >
        <Icon icon="play" />
      </div>
    ) : null;
  },
});

export const rumActionTableColumns = (
  setActiveRumEvent?: (rumEvent?: RumEventWithTabToOpen) => void,
) => [hasReplayColumn(setActiveRumEvent), ...rumDynamicActionTableColumns()];

export const rumViewTableColumns = (
  setActiveRumEvent?: (rumEvent?: RumEventWithTabToOpen) => void,
) => [hasReplayColumn(setActiveRumEvent), ...rumDynamicViewTableColumns()];

export const rumPerformanceTableColumns = () => [
  {
    key: RumViewColumnKey.date,
    label: 'Date',
    renderCell: ({ value }: RenderCellPropsOfView) => (
      <DateFormatter
        formatString={dateTimeFormatWithMilliseconds}
        unixTimestamp={value}
      />
    ),
  },
  {
    key: 'view.url_path',
    label: 'URL Path',
    renderCell: ({ row }: RenderCellPropsOfView) =>
      row.data['view.url_path' as keyof typeof row.data],
  },
  {
    key: 'view.loading_type',
    label: 'Loading Type',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['view.loading_type' as keyof typeof row.data],
  },
  {
    key: 'geo.country',
    label: 'Country',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['geo.country' as keyof typeof row.data],
  },
  {
    key: 'env',
    label: 'Environment',
  },
  {
    key: 'service',
    label: 'Service',
  },
  {
    key: 'version',
    label: 'Version',
  },
  {
    key: 'session.type',
    label: 'Session Type',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['session.type' as keyof typeof row.data],
  },
  {
    key: 'usr.id',
    label: 'User ID',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['usr.id' as keyof typeof row.data],
  },
  {
    key: 'usr.name',
    label: 'User Name',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['usr.name' as keyof typeof row.data],
  },
  {
    key: 'usr.email',
    label: 'User Email',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['usr.email' as keyof typeof row.data],
  },
  {
    key: 'geo.city',
    label: 'City',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['geo.city' as keyof typeof row.data],
  },
  {
    key: 'device.type',
    label: 'Device Type',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['device.type' as keyof typeof row.data],
  },
  {
    key: 'browser.name',
    label: 'Browser Name',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['browser.name' as keyof typeof row.data],
  },
  {
    key: 'os.name',
    label: 'OS Name',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['os.name' as keyof typeof row.data],
  },
  {
    key: 'view.loading_time',
    label: 'Loading Time',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['view.loading_time' as keyof typeof row.data],
  },
  {
    key: 'view.largest_contentful_paint',
    label: 'Largest Contentful Paint',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['view.largest_contentful_paint' as keyof typeof row.data],
  },
  {
    key: 'view.cumulative_layout_shift',
    label: 'Cumulative Layout Shift',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['view.cumulative_layout_shift' as keyof typeof row.data],
  },
  {
    key: 'view.first_input_delay',
    label: 'First Input Delay',
    value: ({ row }: RenderCellPropsOfView) => {
      const value = row.data['view.first_input_delay' as keyof typeof row.data];
      const convertedValue = Number(value);
      return isNaN(convertedValue) || isNullOrUndefined(convertedValue)
        ? '-'
        : formatDurationNs(convertedValue, 1, 2);
    },
  },
  {
    key: 'view.interaction_to_next_paint',
    label: 'Interaction to Next Paint',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['view.interaction_to_next_paint' as keyof typeof row.data],
  },
  {
    key: 'view.time_spent',
    label: 'Time Spent',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data['view.time_spent' as keyof typeof row.data],
  },
];

export const rumLongTaskTableColumns = (
  setActiveRumEvent?: (rumEvent: RumEventWithTabToOpen) => void,
) => [hasReplayColumn(setActiveRumEvent), ...rumDynamicLongTaskTableColumns()];

export const rumResourceTableColumns = (
  setActiveRumEvent?: (rumEvent: RumEventWithTabToOpen) => void,
) => [hasReplayColumn(setActiveRumEvent), ...rumDynamicResourceTableColumns()];

export const rumErrorTableColumns = (
  setActiveRumEvent?: (rumEvent: RumEventWithTabToOpen) => void,
) => [hasReplayColumn(setActiveRumEvent), ...rumDynamicErrorTableColumns()];

export const rumSessionTableColumns = () => [
  ...rumDynamicSessionTableColumns(),
];

export const rumActionWithErrorsTableColumns = () => [
  {
    key: RumActionWithErrorsColumnKey.actionName,
    label: 'Action Name',
    value: ({ row }: RenderCellPropsOfActionsWithErrors) =>
      `${row.dimensions[RumActionWithErrorsColumnKey.actionName]}`,
  },
  {
    key: RumActionWithErrorsColumnKey.viewName,
    label: 'View Name',
    value: ({ row }: RenderCellPropsOfActionsWithErrors) =>
      `${row.dimensions[RumActionWithErrorsColumnKey.viewName]}`,
  },
  {
    key: RumActionWithErrorsColumnKey.sessionId,
    label: 'Affected Session Count',
    value: ({ row }: RenderCellPropsOfActionsWithErrors) => row.aggregates[0],
    type: 'number',
  },
  {
    key: RumActionWithErrorsColumnKey.actionErrorCount,
    label: 'Total Errors',
    value: ({ row }: RenderCellPropsOfActionsWithErrors) => row.aggregates[1],
    type: 'number',
  },
];
