import { DashboardPanelProps, DashboardPanelType } from 'types';

export const getLogQLDashboardExportPanel = ({
  logql,
  type,
}: {
  logql: string;
  type: DashboardPanelType;
}): DashboardPanelProps => {
  return {
    options: {
      legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
      tooltip: { mode: 'single', sort: 'none' },
    },
    targets: [
      {
        datasource: { type: 'loki', uid: '' },
        editorMode: 'code',
        expr: logql,
        key: 'Q-09b106c9-2f31-4591-b382-480e5236903f-0',
        queryType: 'range',
        refId: 'A',
      },
    ],
    type: type,
  };
};
