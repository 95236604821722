import { RightSidebar } from 'components';
import useRequest from 'hooks/useRequest';
import React, { useEffect, useMemo } from 'react';
import getServerlessMetrics from 'requests/getServerlessMetrics';
import { DateSelection } from 'types/DateSelection';
import { FilterMethodByType } from 'types/Filter';
import ServerlessRightSidebar from './ServerlessRightSidebar';

type ServerlessRightSidebarWrapperProps = {
  activeServerlessFunction: Record<string, string>;
  colorsByFunctionName: Record<string, string>;
  date: DateSelection;
  sidebarFilterState: FilterMethodByType<'map'>;
  setActiveServerlessFunction: (row: any) => void;
  getServerlessFunctionsRequest: any;
};

const ServerlessRightSidebarWrapper = ({
  activeServerlessFunction,
  colorsByFunctionName,
  date,
  sidebarFilterState,
  setActiveServerlessFunction,
  getServerlessFunctionsRequest,
}: ServerlessRightSidebarWrapperProps) => {
  const getServerlessMetricsRequest = useRequest(getServerlessMetrics);

  useEffect(() => {
    getServerlessMetricsRequest.call(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const serverlessMetrics = useMemo(
    () => getServerlessMetricsRequest.result || [],
    [getServerlessMetricsRequest.result],
  );

  if (activeServerlessFunction)
    return (
      <RightSidebar
        className="serverless__right-sidebar"
        close={() => setActiveServerlessFunction(null)}
        title={activeServerlessFunction?.functionArn}
      >
        <ServerlessRightSidebar
          colorsByFunctionName={colorsByFunctionName}
          configurationByFunctionName={
            getServerlessFunctionsRequest.result || {}
          }
          date={date}
          activeServerlessFunction={activeServerlessFunction}
          sidebarFilterState={sidebarFilterState}
          serverlessMetrics={serverlessMetrics}
        />
      </RightSidebar>
    );

  return null;
};

export default ServerlessRightSidebarWrapper;
