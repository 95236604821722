import { useToaster } from 'components';
import { useMetricsCardinalityPageStateContext } from 'context/PageStateProvider';
import { useLabelValuePicker, useRequest } from 'hooks';
import { useEffect, useState } from 'react';
import {
  getMetricsList,
  metricsCardinality,
  metricsLabelValueCardinality,
  metricsSeriesCardinality,
} from 'requests';
import { DateSelection } from 'types';

import { CardinalityQueryProps, CardinalityRowProps } from './types';
import {
  cardinalityMatcher,
  convertSeriesLabelsMapToOptions,
  sortCardinalityRows,
} from './utils';

const useMetricsCardinality = () => {
  const { addToast } = useToaster();
  const {
    cardinalityQueryState,
    dateState,
    dependenciesForWriteStateToUrl,
    writeStateToUrl,
  } = useMetricsCardinalityPageStateContext();

  const [date, setDate] = dateState;
  const [cardinalityQuery, setCardinalityQuery] = cardinalityQueryState;
  const [cardinalityRows, setCardinalityRows] = useState<CardinalityRowProps[]>(
    [],
  );

  const labelValuePicker = useLabelValuePicker();
  const { setLabelListMap } = labelValuePicker;
  const metricsCardinalityRequest = useRequest(metricsCardinality);
  const labelCardinalityRequest = useRequest(metricsSeriesCardinality);

  const metricSeriesTimeseriesRequest = useRequest(metricsCardinality);
  const labelSeriesTimeseriesRequest = useRequest(metricsSeriesCardinality);

  const labelValueCountRequest = useRequest(metricsLabelValueCardinality);
  const labelValueTimeseriesRequest = useRequest(metricsLabelValueCardinality);
  const labelValueCountHourRequest = useRequest(metricsLabelValueCardinality);

  const getMetricsListRequest = useRequest((...args: any) =>
    getMetricsList(...args).then((res) => {
      if (!res) return [];
      return res.map((metric) => ({ label: metric, value: metric }));
    }),
  );

  const onDateChange = (nextDate: DateSelection) => {
    const { startTimeUnix, endTimeUnix } = nextDate;
    if (endTimeUnix - startTimeUnix > 300) {
      addToast({
        text: 'Please select a time range less than 5 minutes',
        status: 'error',
      });
      return;
    }
    setDate(nextDate);
  };

  const loadCardinalityLabel = async (
    newCardinalityQuery: CardinalityQueryProps,
  ) => {
    return new Promise((resolve, reject) => {
      const { limit } = newCardinalityQuery;
      const { labelMatcher, metricMatcher } =
        cardinalityMatcher(newCardinalityQuery);
      const payload = { date, matcher: metricMatcher };
      labelCardinalityRequest
        .call({ ...payload, instant: true })
        .then((res) => {
          if (!res) return;
          if (limit.byKey === 'valueCount') {
            loadAndSortByLabelValue(payload, newCardinalityQuery, res);
            resolve(res);
            return;
          }

          const { cardinalityRows, sortedKeys } = sortCardinalityRows({
            rows: res,
            cardinalityQuery: newCardinalityQuery,
          });

          setCardinalityRows(cardinalityRows);
          loadCardinalityLabelValue(payload, sortedKeys);
          loadCardinalityTimeSeries(payload, sortedKeys);
          getMetricsListRequest.call(date, labelMatcher);
          resolve(res);
        });
    });
  };

  const loadCardinalityMetric = async (newCardinalityQuery: any) => {
    return new Promise((resolve, reject) => {
      const { metricMatcher } = cardinalityMatcher(newCardinalityQuery);

      const payload = { date, matchers: [metricMatcher] };
      metricsCardinalityRequest
        .call({ ...payload, instant: true })
        .then((res) => {
          if (!res) return;
          const { cardinalityRows, sortedKeys } = sortCardinalityRows({
            rows: res,
            cardinalityQuery: newCardinalityQuery,
          });

          labelCardinalityRequest
            .call({ ...payload, instant: true, matcher: metricMatcher })
            .then((res) => {
              const options = convertSeriesLabelsMapToOptions(res);
              setLabelListMap((prev) => ({
                ...prev,
                [metricMatcher]: { data: options, isLoading: false },
              }));
            });
          setCardinalityRows(cardinalityRows);
          loadCardinalityMetricTimeSeries({ ...payload, matchers: sortedKeys });
          resolve(res);
        });
    });
  };

  const loadAndSortByLabelValue = async (
    payload: { date: DateSelection; matcher: string },
    newCardinalityQuery: CardinalityQueryProps,
    seriesCountRes: { labelNames: { [key: string]: number } },
  ) => {
    labelValueCountRequest.call({ ...payload, instant: true }).then((res) => {
      const { cardinalityRows, sortedKeys } = sortCardinalityRows({
        rows: res,
        cardinalityQuery: newCardinalityQuery,
      });

      cardinalityRows.forEach((row) => {
        row.seriesCount = seriesCountRes.labelNames[row.label];
      });
      setCardinalityRows(cardinalityRows);
      loadCardinalityLabelValueTimeSeries(payload, sortedKeys);
      loadCardinalityLabelValueHour(payload, sortedKeys);
      loadCardinalityTimeSeries(payload, sortedKeys);
    });
  };

  const loadCardinalityLabelValue = async (
    payload: { date: DateSelection; matcher: string },
    labels: string[],
  ) => {
    labelValueCountRequest
      .call({ ...payload, instant: true, labels: labels })
      .then((res) => {
        loadCardinalityLabelValueTimeSeries(payload, labels);
        loadCardinalityLabelValueHour(payload, labels);
        setCardinalityRows((prev) => {
          const newRows = [...prev];
          newRows.forEach((row) => {
            row.valueCount = res.labelNames[row.label];
          });
          return newRows;
        });
      });
  };

  const loadCardinalityLabelValueTimeSeries = async (
    payload: { date: DateSelection; matcher: string },
    labels: string[],
  ) => {
    labelValueTimeseriesRequest
      .call({
        ...payload,
        format: 'timeseries',
        labels,
        rollUpSeconds: 15,
        instant: false,
      })
      .then((res) => {
        setCardinalityRows((prev) => {
          const newRows = [...prev];
          newRows.forEach((row) => {
            row.valueSeries = res[row.label];
          });
          return newRows;
        });
      });
  };

  const loadCardinalityLabelValueHour = async (
    payload: { date: DateSelection; matcher: string },
    labels: string[],
  ) => {
    const hourAgoDate = { ...date };
    hourAgoDate.startTimeUnix = hourAgoDate.startTimeUnix - 3600;
    hourAgoDate.endTimeUnix = hourAgoDate.endTimeUnix - 300;
    labelValueCountHourRequest
      .call({
        ...payload,
        date: hourAgoDate,
        labels,
        instant: true,
        rollUpSeconds: 3600,
      })
      .then((res) => {
        setCardinalityRows((prev) => {
          const newRows = [...prev];
          newRows.forEach((row) => {
            row.valueCountHr = res.labelNames[row.label];
          });
          return newRows;
        });
      });
  };

  const loadCardinalityTimeSeries = async (
    payload: { date: DateSelection; matcher: string },
    labels: string[],
  ) => {
    labelSeriesTimeseriesRequest
      .call({
        ...payload,
        format: 'timeseries',
        labels,
        rollUpSeconds: 15,
        instant: false,
      })
      .then((res) => {
        setCardinalityRows((prev) => {
          const newRows = [...prev];
          newRows.forEach((row) => {
            row.timeSeries = res[row.label];
          });
          return newRows;
        });
      });
  };

  const loadCardinalityMetricTimeSeries = async (payload: {
    date: DateSelection;
    matchers: string[];
  }) => {
    metricSeriesTimeseriesRequest
      .call({ ...payload, format: 'timeseries', rollUpSeconds: 15 })
      .then((res) => {
        setCardinalityRows((prev) => {
          const newRows = [...prev];
          newRows.forEach((row) => {
            row.timeSeries = res[row.label];
          });
          return newRows;
        });
      });
  };

  const updateCardinalityQuery = (
    propertyKey: string,
    value: any,
    noLoad?: boolean,
  ) => {
    setCardinalityQuery((preQuery) => {
      const newQuery = { ...preQuery, [propertyKey]: value };
      if (propertyKey === 'type') {
        newQuery.metric = '';
        newQuery.labels = ['=""'];
        setCardinalityRows([]);

        if (value === 'metric') {
          newQuery.limit.byKey = 'seriesCount';
          getMetricsListRequest.call(date, '');
        }
      }

      if (propertyKey === 'limit') {
        if (newQuery.type === 'label') {
          loadCardinalityLabel(newQuery);
        } else {
          loadCardinalityMetric(newQuery);
        }
        return newQuery;
      }

      if (newQuery.type === 'label') {
        if (propertyKey === 'labels') {
          newQuery.metric = '';
        }
        if (!noLoad) {
          loadCardinalityLabel(newQuery);
        }
      } else {
        if (propertyKey === 'metric') {
          newQuery.labels = ['=""'];
        }
        if (!noLoad) {
          loadCardinalityMetric(newQuery);
        }
      }

      return newQuery;
    });
  };

  useEffect(() => {
    if (cardinalityQuery.type === 'label') {
      loadCardinalityLabel(cardinalityQuery).then(
        (res: { labelNames: { [key: string]: number } }) => {
          const options = convertSeriesLabelsMapToOptions(res);
          setLabelListMap((prev) => ({
            ...prev,
            '{}': { data: options, isLoading: false },
          }));
        },
      );
    } else {
      loadCardinalityMetric(cardinalityQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  return {
    cardinalityQuery,
    cardinalityRows,
    date,
    getMetricsListRequest,
    labelCardinalityRequest,
    labelSeriesTimeseriesRequest,
    labelValuePicker,
    labelValueCountHourRequest,
    labelValueTimeseriesRequest,
    metricSeriesTimeseriesRequest,
    updateCardinalityQuery,
    metricsCardinalityRequest,
    labelValueCountRequest,
    onDateChange,
    setDate,
    setCardinalityQuery,
  };
};

export default useMetricsCardinality;
