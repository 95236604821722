import { ChartToolbar, TimeseriesRenderer, useToaster } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Clipboard } from 'react-feather';
import { getLogMetricsTimeSeriesFacetExplorer } from 'requests';
import {
  DataFrame,
  findUnitCategoryFormatById,
  logsDataTransformer,
} from 'utils';
import { LegendTypes } from 'types';

import useLogsFacetExplorerState from './useLogsFacetExplorerState';
import {
  getAnalyticsLogQLWithMetaToLoad,
  getAnalyticsQuery,
  getFacetExplorerGraphqlQuery,
  getFacetExplorerLogStateFilter,
} from './utils';
import LogsFacetGroupFacetIcon from 'screens/Logs/LogsFacetGroupFacetIcon';

const LogsFacetExplorerSourceFacetsChart = ({
  logsFacetExplorerState,
  selectedValues,
  width,
}: {
  logsFacetExplorerState: ReturnType<typeof useLogsFacetExplorerState>;
  selectedValues: string[];
  width: number;
}): ReactElement => {
  const { addToast } = useToaster();
  const logsMetricsAnalyticsRequest = useRequest(
    getLogMetricsTimeSeriesFacetExplorer,
  );
  const [instantData, setInstantData] = useState<{ [key: string]: number }>({});

  const { selectedFacet, selectedSource, date } = logsFacetExplorerState;

  const loadInstantData = async () => {
    setInstantData({});
    const logsQueryForGraphQL = getAnalyticsLogQLWithMetaToLoad(
      selectedFacet,
      date,
      selectedValues,
    );
    const instantTransformer = logsDataTransformer(true);
    const logsState = getFacetExplorerLogStateFilter({
      facet: selectedFacet,
      selectedValues,
      date,
      selectedSource,
    });

    const datasets: DataFrame[] = await Promise.all(
      logsQueryForGraphQL.map((l) =>
        getLogMetricsTimeSeriesFacetExplorer({
          facetName: l.facetName,
          meta: l.meta,
          instant: true,
          date,
          logsState,
          normalizeFunction: l.normalizeFunction,
          transformer: instantTransformer,
          vectorAggregate: l.query.vectorAggregate,
          vectorAggregateGrouping: [],
          rangeAggregate: l.query.rangeAggregate,
        }),
      ),
    );

    const instantValues: { [key: string]: number } = {};
    datasets.forEach((dataset, index) => {
      const firstRow = dataset.data[0];
      const firstRowValue = firstRow?.values[0];
      const logQLWithMeta = logsQueryForGraphQL[index];
      const unit = logQLWithMeta.meta.unit;
      const formatValue = findUnitCategoryFormatById(unit);

      if (formatValue && firstRowValue) {
        const formattedValue = formatValue.fn(firstRowValue);
        instantValues[firstRow.displayLabel] = `${formattedValue.text}${
          formattedValue.suffix || ''
        }`;
      } else {
        instantValues[firstRow.displayLabel] = firstRow.values[0];
      }
    });

    setInstantData(instantValues);
  };

  const loadRangeData = async () => {
    if (!logQueryWithMeta) return;
    const transformer = logsDataTransformer(false);
    const { query, meta, facetName, normalizeFunction } = logQueryWithMeta;
    const logsState = getFacetExplorerLogStateFilter({
      facet: selectedFacet,
      selectedValues,
      date,
      selectedSource,
    });
    logsMetricsAnalyticsRequest.call({
      date,
      facetName,
      logsState,
      meta,
      normalizeFunction,
      rangeAggregate: query.rangeAggregate,
      transformer,
      vectorAggregate: query.vectorAggregate,
      vectorAggregateGrouping: [],
    });
  };

  const logQueryWithMeta = useMemo(() => {
    return getFacetExplorerGraphqlQuery({
      facet: selectedFacet,
      date,
      selectedValues,
    });
  }, [selectedFacet, selectedValues, date]);

  useEffect(() => {
    loadRangeData();
    loadInstantData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logQueryWithMeta, date]);

  const onOpenInLogsAnalytics = () => {
    const query = getAnalyticsQuery({
      facet: selectedFacet,
      selectedValues,
      type: 'new_tab',
    });
    const dateEncoded = encodeURIComponent(JSON.stringify(date));
    const queryEncoded = encodeURIComponent(JSON.stringify([query]));
    const queryUrl = `#/logs/timeseries?logsDate=${dateEncoded}&LogsMetricsQueries=${queryEncoded}`;
    window.open(queryUrl, '_blank');
  };

  const defaultChartType = useMemo(() => {
    return ['Stacked Bar'];
  }, []);

  return (
    <div className="flex flex-col gap-2 pl-2 pt-2">
      <div className="text-lg font-semibold text-text-secondary">
        Facet Analytics
      </div>
      <div>
        <div className="flex items-center justify-between">
          <div className="metrics-summary__body__subtitle">
            <LogsFacetGroupFacetIcon type={selectedFacet.type} />
            <span className="px-1">{selectedFacet.name}</span>
            <Clipboard
              className="metrics-summary__body__details__metadata__explorer__copy-icon cursor-pointer"
              onClick={() => {
                addToast({ status: 'success', text: 'Copied to clipboard' });
                navigator.clipboard.writeText(selectedFacet.name);
              }}
              size={16}
            />
          </div>
          <button
            className="button button--blue"
            onClick={onOpenInLogsAnalytics}
          >
            Open in Logs Analytics
          </button>
        </div>
      </div>
      <div className="flex flex-row gap-2">
        {Object.entries(instantData).map(([key, value]) => (
          <div key={key}>{`${key}: ${value}`}</div>
        ))}
      </div>
      <div className="uplot__chart-renderer__no-margin max-w-[720px] rounded-sm border">
        <TimeseriesRenderer
          chartData={
            logsMetricsAnalyticsRequest.result || { data: [], series: [] }
          }
          chartTypes={defaultChartType}
          date={date}
          isLoading={logsMetricsAnalyticsRequest.isLoading}
          legend={{
            legendType: LegendTypes.COMPACT_ONE_LINE,
            legendHeight: 120,
          }}
          renderToolbar={({
            activeChart,
            activeStroke,
            setActiveChart,
            setActiveStroke,
          }) => (
            <ChartToolbar
              chartTypes={['Line', 'Area', 'Stacked Bar', 'Points']}
              activeChart={activeChart}
              activeStroke={activeStroke}
              setActiveChart={setActiveChart}
              setActiveStroke={setActiveStroke}
              toolbar={{
                toolbarMenuType: 'dropdown',
              }}
            />
          )}
          size={{ width: width - 16, height: 260 }}
          tooltipType={'compact'}
          unit={logQueryWithMeta.unit}
        />
      </div>
    </div>
  );
};

export default LogsFacetExplorerSourceFacetsChart;
