import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { VariantProps } from 'class-variance-authority';
import { twMerge as merge } from 'tailwind-merge';

export function createPrimitiveComponent<T extends (...args: any[]) => any>(
  displayName: string,
  tag: string,
  variants: T,
) {
  const Component: React.FC<
    VariantProps<T> & { asChild?: true; className?: string }
  > = ({ asChild, children, className, ...variantProps }) => {
    const Component = asChild ? Slot : tag;

    return (
      <Component className={merge(variants(variantProps), className)}>
        {children}
      </Component>
    );
  };

  Component.displayName = displayName;

  return Component;
}

export function createArrayVariants(array: string[]): Record<string, string> {
  const record: Record<string, string> = {};

  array.forEach((item, index) => {
    record[index] = item;
  });

  return record;
}
