import { lowerCase } from 'lodash';
import {
  useDateState,
  useRequest,
  useSelectedFacetValuesByNameState,
} from 'hooks';
import { useState } from 'react';
import { deleteSlo, getSloList, promqlQuery } from 'requests';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { SLOProps, ValueCount } from 'types';
import { getStatusAndErrorBudgetInListView, transformSLOPromql } from 'utils';

import { getSLOServiceFacetValues } from '../utils';

const useSLOState = () => {
  const selectedFacetValuesByNameState = useSelectedFacetValuesByNameState();
  const [date, setDate] = useDateState(getDateFromRange('now-1h', 'now'));
  const [sloList, setSloList] = useState([]);
  const [sloFilterProperties, setSloFilterProperties] = useState<{
    [key: string]: ValueCount[];
  }>({
    status: [
      { value: 'OK', count: 0 },
      { value: 'Breached', count: 0 },
    ],
    servicehash: [],
    tags: [],
    telemetrytype: [
      { value: 'apm', count: 0 },
      { value: 'knight', count: 0 },
    ],
  });

  const sloListRequest = useRequest(getSloList, true, true);
  const promqlQueryRequest = useRequest(promqlQuery, false);
  const deleteSloRequest = useRequest(deleteSlo);

  const getSLOStateFacetValues = (facetName: string) => () => {
    return new Promise((resolve) => {
      resolve(sloFilterProperties[facetName.toLocaleLowerCase()]);
    });
  };

  const loadSLOList = async (queryTemplates: any) => {
    sloListRequest.call().then((response: SLOProps[]) => {
      const serviceFacetValues = getSLOServiceFacetValues(response);
      setSloFilterProperties({
        ...sloFilterProperties,
        servicehash: serviceFacetValues,
      });
      response.map(async (slo) => {
        const sloAvailabiltyType = lowerCase(slo.type);

        const budgetPromQl = transformSLOPromql({
          promql: queryTemplates[sloAvailabiltyType]?.sloBudgetRemaining,
          slo,
          window: '30d',
        });

        const errorRatioPromQl = transformSLOPromql({
          promql: queryTemplates[sloAvailabiltyType]?.sloStatusQuery,
          slo,
          window: '30d',
        });

        const dataset = await promqlQueryRequest.call({
          date,
          promqlQueries: [budgetPromQl, errorRatioPromQl],
        });

        const statusErrorBudget = getStatusAndErrorBudgetInListView(
          dataset,
          slo.objective,
        );

        if (statusErrorBudget) {
          slo.statusErrorBudget = statusErrorBudget;
          if (statusErrorBudget.statusColor === 'red') {
            sloFilterProperties.status[1].count += 1;
          } else {
            sloFilterProperties.status[0].count += 1;
          }
        }
        setSloList([...response]);
      });
      setSloList(response);
    });
  };

  return {
    deleteSloRequest,
    getSLOStateFacetValues,
    loadSLOList,
    selectedFacetValuesByNameState,
    setSloFilterProperties,
    sloList,
    sloListRequest,
  };
};

export default useSLOState;
