import * as yup from 'yup';

const saveLogsAnalyticsValidator = () => {
  return yup.object({
    name: yup
      .string()
      .required('Metric name is required')
      .min(3, 'Metric name must be at least 3 characters')
      .max(512, 'Metric name must be less than 512 characters')
      .matches(
        /^[a-zA-Z0-9_]+$/,
        'Metric name must be alphanumeric and underscore',
      ),
    metricType: yup.string().required('Metric type is required'),
    labels: yup.array(),
  });
};

export default saveLogsAnalyticsValidator;
