import { AutocompleteV2 } from 'components';
import React from 'react';
import useRumState from './hooks/useRumState';
import { RumEventType } from './types';
import { components } from 'react-select';
import { getChartColor } from './utils';

type Props = {
  rumState: ReturnType<typeof useRumState>;
};

const readableEventType = (eventType: RumEventType) => {
  switch (eventType) {
    case RumEventType.ACTION:
      return 'Actions';
    case RumEventType.ERROR:
      return 'Errors';
    case RumEventType.LONGTASK:
      return 'Long Tasks';
    case RumEventType.RESOURCE:
      return 'Resources';
    case RumEventType.SESSION:
      return 'Sessions';
    case RumEventType.VIEW:
      return 'Views';

    default:
      return eventType;
  }
};

const CustomOption = (props: any) => {
  const { data, innerRef, innerProps } = props;

  const marginLeftMap: { [key in RumEventType]: string } = {
    [RumEventType.ACTION]: '16px',
    [RumEventType.ERROR]: '16px',
    [RumEventType.LONGTASK]: '16px',
    [RumEventType.RESOURCE]: '16px',
    [RumEventType.VIEW]: '8px',
    [RumEventType.SESSION]: '0px',
  };

  const verticalLineClass: { [key in RumEventType]: string } = {
    [RumEventType.ACTION]:
      'event-filter__vertical-line event-filter__vertical--adjusted',
    [RumEventType.ERROR]:
      'event-filter__vertical-line event-filter__vertical--adjusted',
    [RumEventType.LONGTASK]: '',
    [RumEventType.RESOURCE]:
      'event-filter__vertical-line event-filter__vertical--adjusted',
    [RumEventType.VIEW]:
      'event-filter__vertical-line event-filter__vertical--moved',
    [RumEventType.SESSION]: 'event-filter__vertical-line',
  };

  const eventColor = getChartColor(data.value);

  const optionStyle = {
    marginLeft: marginLeftMap[data.value as RumEventType],
    backgroundColor: eventColor,
  };

  return (
    <components.Option {...props} ref={innerRef} {...innerProps}>
      <div className="event-filter__option">
        <div className="event-filter__color-marker" style={optionStyle}></div>
        <div className={verticalLineClass[data.value as RumEventType]}>
          {data.label}
        </div>
      </div>
    </components.Option>
  );
};

const EventTypeFilter = ({ rumState }: Props) => {
  const { eventType, setEventType } = rumState;
  return (
    <div className="flex flex-row items-center mr-14">
      <div className="event-filter__item">
        <AutocompleteV2
          onChange={(val: string) => setEventType(val as RumEventType)}
          options={Object.values(RumEventType).map((filter) => ({
            label: readableEventType(filter),
            value: filter,
          }))}
          value={eventType}
          components={{ Option: CustomOption }}
        />
      </div>
    </div>
  );
};

export default EventTypeFilter;
