import classNames from 'classnames';
import React, { ReactElement, useMemo } from 'react';

import { useDashboardEditState } from './hooks';
import { SupportedEditGraphList } from './utils';

const EMPTY_ARRAY: string[] = [];
const DashboardEditMetricsVisualType = ({
  dashboardEditState,
  hideVisualizationTypes = EMPTY_ARRAY,
}: {
  dashboardEditState: ReturnType<typeof useDashboardEditState>;
  hideVisualizationTypes?: string[];
}): ReactElement => {
  const { editPanel, setEditPanel } = dashboardEditState;

  const filteredSupportedEditGraphList = useMemo(() => {
    return SupportedEditGraphList.filter(
      ({ name }) => !hideVisualizationTypes?.includes(name),
    );
  }, [hideVisualizationTypes]);

  return (
    <div className="dashboard__widget__visualization">
      <div className="button-group mt-2 mb-2">
        {filteredSupportedEditGraphList.map(({ name, label, icon }) => {
          return (
            <div
              className={classNames({
                'button-group__item': true,
                'button-group__item--active': editPanel.type === name,
              })}
              key={name}
            >
              <button
                className="flex flex--align-items-center"
                onClick={() =>
                  setEditPanel((prev) => ({ ...prev, type: name }))
                }
              >
                <div className="svg--size-8">{icon}</div>
                <div className="pl-2">{label}</div>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardEditMetricsVisualType;
