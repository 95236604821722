import { useToaster } from 'components';
import { useLogsCardinalityPageStateContext } from 'context/PageStateProvider';
import { useLabelValuePicker, useRequest } from 'hooks';
import { useEffect, useState } from 'react';
import { getLabelNames, getLabelValues } from 'requests';
import { DateSelection } from 'types';
import {
  getLabelWithDelimiterAndType,
  groupLabels,
  parseFacetKey,
} from 'utils';

import { LabelCardinalityQueryProps } from './types';
import {
  getLogsLabelToSelectedFacetValuesAndFilterByFacets,
  formatLogsCardinalityChartData,
  sortLogsCardinalityRows,
} from './utils';
import { labelCardinality } from './requests';

const useLogsCardinalityState = () => {
  const { addToast } = useToaster();
  const {
    logsCardinalityQueryState,
    dateState,
    dependenciesForWriteStateToUrl,
    writeStateToUrl,
  } = useLogsCardinalityPageStateContext();

  const [date, setDate] = dateState;
  const [labelCardinalityQuery, setLabelCardinalityQuery] =
    logsCardinalityQueryState;
  const [labelCardinalityRows, setLabelCardinalityRows] = useState<any[]>([]);

  const labelCardinalityRequest = useRequest(labelCardinality);
  const labelCardinalityOneHourRequest = useRequest(labelCardinality);
  const labelCardinalityChartRequest = useRequest(labelCardinality);
  const labelCardinalityOverallRequest = useRequest((args) =>
    labelCardinality(args).then((data) => data[0]?.points?.[0]?.value || ''),
  );

  const labelNamesRequest = useRequest((args) =>
    getLabelNames(args).then((labels) => {
      const groupedLabels = groupLabels(labels);
      return getLabelWithDelimiterAndType(groupedLabels);
    }),
  );
  const labelValuesRequest = useRequest((args) =>
    getLabelValues(args).then((values) =>
      values.map((val) => ({ label: val.value, value: val.value })),
    ),
  );

  const labelValuePicker = useLabelValuePicker();
  const { setLabelListMap, setLabelValueListMap } = labelValuePicker;

  const onDateChange = (nextDate: DateSelection) => {
    const { startTimeUnix, endTimeUnix } = nextDate;
    if (endTimeUnix - startTimeUnix > 300) {
      addToast({
        text: 'Please select a time range less than 5 minutes',
        status: 'error',
      });
      return;
    }
    setDate(nextDate);
  };

  const loadLabelValues = (label: string) => {
    setLabelValueListMap({ [label]: { data: [], isLoading: true } });
    const facet = parseFacetKey(label);
    labelValuesRequest.call({ logsState: { date }, facet }).then((data) => {
      setLabelValueListMap({ [label]: { data, isLoading: false } });
    });
  };

  const loadLogsLabelCardinality = (
    newCardinalityQuery: LabelCardinalityQueryProps,
  ) => {
    const { labels } = newCardinalityQuery;
    const { selectedFacetValues, filterByFacets } =
      getLogsLabelToSelectedFacetValuesAndFilterByFacets(
        labels,
        labels.length - 1,
      );
    labelCardinalityRequest
      .call({
        date,
        selectedFacetValues,
        filterByFacets,
      })
      .then((data) => {
        const { cardinalityRows, sortedKeys } = sortLogsCardinalityRows({
          rows: data,
          cardinalityQuery: newCardinalityQuery,
        });

        setLabelCardinalityRows(cardinalityRows);
        loadLogsLabelCardinalityChart(newCardinalityQuery, sortedKeys);
        loadLogsLabelCardinalityOneHour(newCardinalityQuery);
        loadLogsLabelCardinalityOverall(newCardinalityQuery);
      });
  };

  const loadLogsLabelCardinalityOverall = (
    newCardinalityQuery: LabelCardinalityQueryProps,
  ) => {
    const { labels } = newCardinalityQuery;
    const { selectedFacetValues, filterByFacets } =
      getLogsLabelToSelectedFacetValuesAndFilterByFacets(
        labels,
        labels.length - 1,
      );
    labelCardinalityOverallRequest.call({
      date,
      selectedFacetValues,
      filterByFacets,
      cardinalityType: 'All',
    });
  };

  const loadLogsLabelCardinalityOneHour = (
    newCardinalityQuery: LabelCardinalityQueryProps,
  ) => {
    const { labels } = newCardinalityQuery;
    const { selectedFacetValues, filterByFacets } =
      getLogsLabelToSelectedFacetValuesAndFilterByFacets(
        labels,
        labels.length - 1,
      );
    const hourAgoDate = { ...date };
    hourAgoDate.startTimeUnix = hourAgoDate.endTimeUnix - 3600;
    labelCardinalityOneHourRequest
      .call({
        date: hourAgoDate,
        selectedFacetValues,
        filterByFacets,
      })
      .then((data) => {
        setLabelCardinalityRows((prev) => {
          const newState = [...prev];
          newState.forEach((row) => {
            const rowOneHour = data.find(
              (d) => d.tags['key'] === row.tags['key'],
            );
            row.valueOneHour = rowOneHour.points[0]?.value;
          });
          return newState;
        });
      });
  };

  const loadLogsLabelCardinalityChart = (
    newCardinalityQuery: LabelCardinalityQueryProps,
    sortedKeys: string[],
  ) => {
    const { labels } = newCardinalityQuery;
    const { selectedFacetValues, filterByFacets } =
      getLogsLabelToSelectedFacetValuesAndFilterByFacets(
        labels,
        labels.length - 1,
      );

    labelCardinalityChartRequest
      .call({
        date,
        selectedFacetValues,
        filterByFacets,
        rollUpSeconds: 15,
        keys: sortedKeys,
      })
      .then((data) => {
        setLabelCardinalityRows((prev) => {
          const newState = [...prev];
          newState.forEach((row) => {
            const rowSeries = data.find(
              (d) => d.tags['key'] === row.tags['key'],
            );
            row.valueSeries = formatLogsCardinalityChartData(rowSeries.points);
          });
          return newState;
        });
      });
  };

  const loadLogsLabelCardinalityLimit = (
    newCardinalityQuery: LabelCardinalityQueryProps,
  ) => {
    const { cardinalityRows, sortedKeys } = sortLogsCardinalityRows({
      rows: labelCardinalityRequest.result || [],
      cardinalityQuery: newCardinalityQuery,
    });

    setLabelCardinalityRows(cardinalityRows);
    loadLogsLabelCardinalityChart(newCardinalityQuery, sortedKeys);
    loadLogsLabelCardinalityOneHour(newCardinalityQuery);
  };

  const updateCardinalityQuery = (
    key: keyof LabelCardinalityQueryProps,
    value: any,
    isReload = true,
  ) => {
    setLabelCardinalityQuery((prev) => {
      const newQuery = { ...prev, [key]: value };
      if (!isReload) return newQuery;
      if (key === 'limit') {
        loadLogsLabelCardinalityLimit(newQuery);
      } else {
        loadLogsLabelCardinality(newQuery);
      }
      return newQuery;
    });
  };

  useEffect(() => {
    labelNamesRequest.call({ logsState: { date } }).then((data) => {
      setLabelListMap({ '{}': { data, isLoading: false } });
    });
    loadLogsLabelCardinality(labelCardinalityQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  return {
    date,
    onDateChange,
    labelCardinalityQuery,
    labelCardinalityRows,
    labelCardinalityRequest,
    labelCardinalityOneHourRequest,
    labelCardinalityChartRequest,
    labelCardinalityOverallRequest,
    labelValuePicker,
    loadLabelValues,
    setLabelListMap,
    setLabelValueListMap,
    setDate,
    setLabelCardinalityQuery,
    updateCardinalityQuery,
  };
};

export default useLogsCardinalityState;
