import { getUsedQueriesLabelForFormula } from 'utils';

import filterLogqlFormulaMatchingLabels from './filterLogqlFormulaMatchingLabels';
import { DataFrame } from '../types';
import { timeseriesDataTransformer } from '../visualTransformer';

import { QueryDataProps } from 'types/QueryData';

const logqlDashboardDataTransformer = (dataFrames: DataFrame[]) => {
  if (dataFrames.length === 1) return dataFrames[0];

  // build temporary data and label keys for formula
  const tempLogsChartData: QueryDataProps = {};
  const tempLabelKeys: { [key: string]: string[] } = {};
  dataFrames.forEach((dataFrame) => {
    if (dataFrame.meta.queryType === 'formula') return;
    const tempData = timeseriesDataTransformer(dataFrame);
    const queryId = `query_${dataFrame.meta.refId}`;
    tempLogsChartData[queryId] = {
      range: tempData,
      meta: dataFrame.meta,
      isLoading: false,
    };
    tempLabelKeys[queryId] = dataFrame.meta.labels;
  });

  // get formula data frame from dataFrames
  const formulaDataFrame = dataFrames.find(
    (dataFrame) => dataFrame.meta.queryType === 'formula',
  );
  if (!formulaDataFrame) return dataFrames[0];

  // get label bitmap from formula data frame
  const labelBitmap = getUsedQueriesLabelForFormula({
    formulaExpression: formulaDataFrame?.meta.formulaExpression,
    tempLogsChartData,
    tempLabelKeys,
  });

  // if no label bitmap, return the formula data frame
  if (!labelBitmap) return formulaDataFrame;

  // filter data frames that match the label bitmap
  const filteredDataFrames = filterLogqlFormulaMatchingLabels({
    dataFrame: formulaDataFrame,
    labelBitmap,
  });
  if (filteredDataFrames) return filteredDataFrames;

  return dataFrames[0];
};

export default logqlDashboardDataTransformer;
