import {
  Accordion,
  Button,
  CopyButton,
  CursorStateProvider,
  RightSidebar,
  ServiceWithLabels,
  Tab,
  Tabs,
  TooltipTrigger,
  useTabs,
} from 'components';
import classnames from 'classnames';
import React, { ReactElement, useMemo } from 'react';
import { SLOProps } from 'types/SLO';

import SLODetailsAlertsTab from './SLODetailsAlertsTab';
import SLODetailsObjective from './SLODetailsObjective';
import SLODetailsHistoryChart from './SLODetailsHistoryChart';
import { Link, useNavigate } from 'react-router-dom';
import { MdModeEdit } from 'react-icons/md';
import SLOChartGrid from './SLOChartGrid';
import { SLOQueryTemplates } from '../types';
import { transformSLOPromql } from 'utils/slos';

type Props = {
  close: VoidFunction;
  sloData: SLOProps;
  title: string;
  queryTemplates: Record<string, SLOQueryTemplates>;
};

const SLODetails = ({
  close,
  sloData,
  title,
  queryTemplates,
}: Props): ReactElement => {
  const tabs = useTabs();
  const navigate = useNavigate();

  const serviceObjectToUse = sloData.service;

  const matchersArray = useMemo(() => {
    if (!sloData?.matchers) return [];
    const matchers = sloData?.matchers?.split(',') || [];
    return matchers;
  }, [sloData.matchers]);

  const sloType = sloData.type?.toLowerCase();

  const queryTemplatesForSLOType =
    queryTemplates[sloType] || ({} as SLOQueryTemplates as SLOQueryTemplates);

  const sliQuery = useMemo(() => {
    const query = `${queryTemplatesForSLOType.sliBadEventsPromQuery} / ${queryTemplatesForSLOType.sliTotalEventsPromQuery} * 100`;

    const transformedQuery = transformSLOPromql({
      promql: query,
      slo: sloData,
      window: '6h',
    });
    return transformedQuery;
  }, [
    queryTemplatesForSLOType.sliBadEventsPromQuery,
    queryTemplatesForSLOType.sliTotalEventsPromQuery,
    sloData,
  ]);

  return (
    <RightSidebar className="slo__right-sidebar" close={close} title={title}>
      <div className="slo__right-sidebar__main">
        <Tabs className="tabs--underline" tabs={tabs}>
          <Tab label="Status & History">
            <div className="slos__status__description">
              <div className="slos__status__description__text">
                <div className="slos__query__heading">Service:</div>
                <Link
                  className="link text--weight-medium services__table__link"
                  to={{
                    pathname: `/apm/services/${serviceObjectToUse?.hash}`,
                  }}
                >
                  <ServiceWithLabels
                    color=""
                    name={serviceObjectToUse?.name}
                    distinctLabels={serviceObjectToUse?.labels || {}}
                    labels={serviceObjectToUse?.labels || {}}
                  />
                </Link>
              </div>
              <div className="slos__status__edit-slo">
                <TooltipTrigger tooltip="Edit">
                  <MdModeEdit
                    onClick={() => {
                      navigate(
                        `/apm/slo/edit?sloType=${sloData.type?.toLowerCase()}&kf_source=apm&serviceHash=${serviceObjectToUse?.hash}`,
                        { state: sloData },
                      );
                    }}
                    size={18}
                  />
                </TooltipTrigger>
              </div>
            </div>
            <div className="slos__status__description">
              <div className="slos__status__description__text">
                <div className="slos__query__heading">Description:</div>
                <div>&nbsp; {sloData.description}</div>
              </div>
            </div>
            <div className="slos__status__description">
              <div className="slos__status__description__text">
                <div className="slos__query__heading">Type:</div>
                <div>&nbsp; {sloData.type}</div>
              </div>
            </div>
            <div className="slos__status__description">
              <div className="slos__status__description__text">
                <div className="slos__query__heading">Objective:</div>
                <div>&nbsp; {sloData.objective} %</div>
              </div>
            </div>
            {sloData.type === 'Latency' && (
              <div className="slos__status__description">
                <div className="slos__status__description__text">
                  <div className="slos__query__heading">Latency Threshold:</div>
                  <div>&nbsp; {sloData.latencyThreshold} ms</div>
                </div>
              </div>
            )}
            {sloData.contactPoints && sloData.contactPoints.length > 0 && (
              <div className="slos__query__contact_points">
                <div className="slos__query__heading">Contact Points:</div>
                <div className="slos__query__labels__chips">
                  {sloData.contactPoints &&
                    sloData.contactPoints.map((contact, index) => (
                      <div className="chip" key={index}>
                        {contact}
                      </div>
                    ))}
                </div>
              </div>
            )}
            <div className="slos__query__labels">
              <div className="slos__query__heading">Matchers:</div>
              <div className="slos__query__labels__chips">
                {matchersArray &&
                  matchersArray.map((label, index) => (
                    <div className="chip" key={index}>
                      {label}
                    </div>
                  ))}
              </div>
            </div>
            <div className="slos__query__container">
              <Accordion
                className="slos__query__accordion"
                renderContent={() => (
                  <div className="slos__query__box">
                    <div className="slos__query__details">{sliQuery}</div>
                    <div className="slos__query__copy__button">
                      <CopyButton text={sliQuery} />
                    </div>
                  </div>
                )}
                renderTrigger={() => <>SLI Query</>}
              />
            </div>
            <div className="slos__divider" />
            {/* {sloData.statusErrorBudget & */}
            {sloData.statusErrorBudget && (
              <div className="slos__target__box__main__container">
                <div className="slos__target__heading">Past 30 Days</div>
                <div className="slos__target__heading">
                  Objective (SLO)
                  <div className="slos__target__details">
                    {sloData.objective}%
                  </div>
                </div>
                <div className="slos__status__heading">
                  Status
                  <div
                    className="slos__status__details"
                    style={{ color: sloData.statusErrorBudget.statusColor }}
                  >
                    {sloData.statusErrorBudget.status}
                  </div>
                </div>
                <div className="slos__error__budget__heading">
                  Remaining Error Budget
                  <div
                    className="slos__error__budget__details__container"
                    style={{
                      color: sloData.statusErrorBudget.errorBudgetColor,
                    }}
                  >
                    <div className="slos__error__budget__details">
                      {sloData.statusErrorBudget.errorBudget}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="slos__tabs slos__buttons tabs__buttons--underline">
              <button
                className={classnames({
                  tabs__buttons__item: true,
                  'tabs__buttons__item--active': true,
                })}
              >
                Past 30 Days
              </button>
            </div>
            <CursorStateProvider>
              <SLOChartGrid
                queryTemplatesForSLOType={queryTemplatesForSLOType}
                sloData={sloData}
              />
            </CursorStateProvider>
            <div className="slos__divider" />
            <div className="slos__good__bad__query__container">
              <div className="slos__good__bad__query__title">
                Good/Bad queries
              </div>
            </div>
            <SLODetailsHistoryChart
              sloData={sloData}
              queryTemplatesForSLOType={queryTemplatesForSLOType}
            />
          </Tab>
          <Tab label="Alerts">
            <SLODetailsAlertsTab sloData={sloData} />
          </Tab>
        </Tabs>
      </div>
    </RightSidebar>
  );
};

export default SLODetails;
