import { DataFrame, DataFrameMeta } from '../types';
import { DashboardPanelType, RumTimeseriesProps } from 'types';
import { fillTimestampForWindow } from '../fillTimestampForWindow';

export const mergeFilledTimestampAndDatasetTimestamps = (
  datasets: RumTimeseriesProps[],
  timestampBitmap: { [key: number]: boolean },
): number[] => {
  datasets?.forEach((dataset) => {
    dataset.points.forEach((point) => {
      timestampBitmap[point.ts / 1000] = true;
    });
  });
  return Object.keys(timestampBitmap)
    .map(Number)
    .sort((a, b) => a - b);
};

export const rumqlDataTransformer = ({
  datasets,
  meta,
}: {
  datasets: RumTimeseriesProps[];
  meta: DataFrameMeta;
}): DataFrame => {
  const transformedData: DataFrame['data'] = [];
  let maxValue = -Infinity;
  let minValue = Infinity;

  const { executedDate } = meta;
  const { timestampBitmap } = fillTimestampForWindow({
    executedDate,
    step: meta.step,
  });
  const timestamps = mergeFilledTimestampAndDatasetTimestamps(
    datasets,
    timestampBitmap,
  );

  datasets.forEach(({ dimensions, points }) => {
    const valueByTimestamp: { [key: string]: number } = {};
    points.forEach((point) => {
      const timestampInSeconds = point.ts / 1000;
      valueByTimestamp[timestampInSeconds] = point.value;
      minValue = Math.min(minValue, point.value);
      maxValue = Math.max(maxValue, point.value);
    });

    const timeseriesData: (number | undefined)[] = timestamps.map(
      (ts) => valueByTimestamp[ts],
    );
    transformedData.push({ label: dimensions, values: timeseriesData });
  });

  return {
    data: transformedData,
    meta,
    minValue,
    maxValue,
    timestamps,
  };
};

export const rumqlDataTransformerInstant = ({
  datasets,
  meta,
}: {
  datasets: RumTimeseriesProps[];
  meta: DataFrame['meta'];
}): DataFrame => {
  const data: DataFrame['data'] = [];
  let maxValue = -Infinity;
  let minValue = Infinity;
  const timestampBitmap: { [key: number]: boolean } = {};

  if (!datasets || datasets.length === 0) {
    return { data, meta, minValue, maxValue, timestamps: [] };
  }

  datasets.forEach((dataset) => {
    const { points, dimensions, aggregates } = dataset;
    const valueByTimestamp: { [key: string]: number } = {};
    if (points) {
      points.forEach((point) => {
        const timestampInSeconds = point.ts / 1000;
        valueByTimestamp[timestampInSeconds] = point.value;
        minValue = Math.min(minValue, point.value);
        maxValue = Math.max(maxValue, point.value);

        if (Object.keys(dimensions).length === 0) {
          dimensions.label = meta.metricName;
        }

        timestampBitmap[timestampInSeconds] = true;
        data.push({
          label: dimensions,
          values: [point.value],
          timestamp: timestampInSeconds,
        });
      });
    } else if (aggregates) {
      const metricNames = meta.metricNames;
      const aggregateDimensions: Record<string, any> = aggregates.reduce(
        (acc, value, index) => {
          if (metricNames && metricNames[index]) {
            acc[metricNames[index] as string] = value;
          }
          return acc;
        },
        {} as Record<string, any>,
      );

      const aggregatesDimensions = {
        ...dimensions,
        ...(meta.type === DashboardPanelType.TABLE ? aggregateDimensions : {}),
      };
      const points =
        aggregates && aggregates.length > 0
          ? [
              {
                ts: 0,
                value: aggregates[0],
              },
            ]
          : [];
      points.forEach((point) => {
        const timestampInSeconds = point.ts / 1000;
        valueByTimestamp[timestampInSeconds] = point.value;
        minValue = Math.min(minValue, point.value);
        maxValue = Math.max(maxValue, point.value);

        if (Object.keys(aggregatesDimensions).length === 0) {
          aggregatesDimensions.label = meta.metricName;
        }

        timestampBitmap[timestampInSeconds] = true;
        data.push({
          label: aggregatesDimensions,
          values: [point.value],
          timestamp: timestampInSeconds,
        });
      });
    }
  });

  return {
    data,
    meta,
    minValue,
    maxValue,
    timestamps: Object.keys(timestampBitmap).map(Number),
  };
};
