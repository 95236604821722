import { useTableBulkActions } from 'components';
import { Separator } from 'components/shadcn';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import ResizeObserver from 'rc-resize-observer';
import { getLogFacetValuesCounts } from 'requests';

import LogsFacetExplorerSourceFacetsLogs from './LogsFacetExplorerSourceFacetsLogs';
import LogsFacetExplorerSourceFacetsValues from './LogsFacetExplorerSourceFacetsValues';
import LogsFacetExplorerSourceFacetsChart from './LogsFacetExplorerSourceFacetsChart';
import useLogsFacetExplorerState from './useLogsFacetExplorerState';

const emptyArray: string[] = [];

const LogsFacetExplorerMain = ({
  logsFacetExplorerState,
}: {
  logsFacetExplorerState: ReturnType<typeof useLogsFacetExplorerState>;
}): ReactElement => {
  const [width, setWidth] = useState(760);
  const getLogFacetValuesCountsRequest = useRequest(getLogFacetValuesCounts);

  const { date, selectedSource, selectedFacet } = logsFacetExplorerState;
  const valueTableBulkActions = useTableBulkActions({
    rows: getLogFacetValuesCountsRequest.result || [],
    uniqueIdKey: 'value',
  });

  useEffect(() => {
    if (!selectedFacet) return;
    getLogFacetValuesCountsRequest.call({
      facet: {
        ...selectedFacet,
        component: selectedSource === '*' ? '' : selectedSource,
      },
      logsState: { date },
      limit: 20,
    });
    valueTableBulkActions.clearSelectedRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacet, date]);

  const selectedValues = useMemo(() => {
    const selectedRows = Object.keys(valueTableBulkActions.selectedRows);
    if (selectedRows.length === 0) {
      return emptyArray;
    }
    return selectedRows;
  }, [valueTableBulkActions.selectedRows]);

  return (
    <>
      <Separator orientation="vertical" className="ml-2 h-screen" />
      <LogsFacetExplorerSourceFacetsValues
        getLogFacetValuesCountsRequest={getLogFacetValuesCountsRequest}
        logsFacetExplorerState={logsFacetExplorerState}
        tableBulkActions={valueTableBulkActions}
      />
      <Separator orientation="vertical" className="ml-2 h-screen" />
      <div>
        <ResizeObserver onResize={(size) => setWidth(size.width)}>
          <>
            <LogsFacetExplorerSourceFacetsChart
              logsFacetExplorerState={logsFacetExplorerState}
              selectedValues={selectedValues}
              width={width}
            />
            <LogsFacetExplorerSourceFacetsLogs
              logsFacetExplorerState={logsFacetExplorerState}
              selectedValues={selectedValues}
              width={width}
            />
          </>
        </ResizeObserver>
      </div>
    </>
  );
};

export default LogsFacetExplorerMain;
