import dayjs from 'dayjs';
import { onPromiseError, DataFrameMeta, DataTransformerConfig } from 'utils';
import queryRumService from './queryRumService';
import { RUMQueryServiceResponse, RumEvent, RumEventType } from '../types';
import {
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { buildRumFilter } from './utils';

type Args = {
  applicationFilter: string;
  errorGroupingKey?: string;
  eventType: RumEventType;
  facetRegex?: FacetRegexTerm[];
  date: DateSelection;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  rollupSeconds: number;
  meta?: DataFrameMeta;
  transformer?: DataTransformerConfig[];
};

const getRUMTopViewWithHighestError = async ({
  applicationFilter,
  errorGroupingKey,
  eventType,
  facetRegex,
  date,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  rollupSeconds,
  meta,
  transformer,
}: Args): Promise<{ data: RumEvent[]; cursor: string }> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const filterOptions = {
    applicationFilter,
    errorGroupingKey,
    facetRegex,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
  };

  if (meta) {
    meta.executedDate = date;
  }

  return queryRumService<RUMQueryServiceResponse, 'errorSeries'>(`
    query {
        errorSeries(
            eventType: ${eventType},
            timestamp: "${endTime.format()}",
            durationSecs: ${durationSecs},
            rollupSeconds: ${rollupSeconds},
            filter: ${buildRumFilter(filterOptions)},
            groupBy: [{ 
                limit: 100
                field: "view.name"
                sort: {
                field: "*"
                aggregation: "count"
                order: "top"
                }
            }
            ]
        ) {
            errorPercent
            dimensions
            points {
            ts
            value
            }
        }
        }
  `).then((data) => {
    // If 'meta' exists, transform 'data' for timeseries chart display
    if (meta) {
      const initialData = { datasets: data.errorSeries, meta };
      const transformed = transformer.reduce(
        (prevData, item) => item.func(prevData),
        initialData,
      );
      return transformed as unknown;
    }

    // Otherwise, return 'data' for top list display
    return data;
  }, onPromiseError);
};

export default getRUMTopViewWithHighestError;
