import { yupResolver } from '@hookform/resolvers/yup';
import { useToaster } from 'components';
import { startCase } from 'lodash';
import { useDateState, useRequest, useUrlState } from 'hooks';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { createSlo, editSlo, getGrafanaAlertManager } from 'requests';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { SLOFormProps } from 'types';

import { SLOCountValueThreshold, SLOType } from '../types';
import { getDefaultNameForSLOType, sloCreateInputSchema } from '../utils';
import { buildPromqlLabelMatcher } from 'utils';

const useCreateSLOState = (
  initialSloEditState?: any,
  activeDetection?: string,
) => {
  const navigate = useNavigate();
  const { addToast } = useToaster();
  const [date, setDate] = useDateState(getDateFromRange('now-1h', 'now'));
  const [isEditing, setIsEditing] = useState(false);

  const [dryRunPromQls, setDryRunPromQls] = useState<{ [key: string]: string }>(
    {},
  );

  const [sloCountThresold, setSloCountThresold] = useState<{
    denominator: SLOCountValueThreshold;
    numerator: SLOCountValueThreshold;
  }>({
    denominator: { count: 'count', operator: '<', threshold: '' },
    numerator: { count: 'count', operator: '<', threshold: '' },
  });

  const [sloResult, setSloResult] = useState<[]>(null);
  const [sloType, setSloType] = useUrlState<{
    value: SLOType;
  }>('sloType', { value: SLOType.AVAILABILITY });

  const [serviceName] = useUrlState('serviceName', '');

  const defaultSLOName = getDefaultNameForSLOType({
    type: activeDetection || '',
    serviceName,
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<SLOFormProps>({
    resolver: yupResolver(sloCreateInputSchema()),
    defaultValues: {
      sloName: initialSloEditState?.name || defaultSLOName,
      sloDescription: initialSloEditState?.description || '',
      sloLabels: [{ key: '', value: '' }],
      objective: undefined,
    },
  });

  const createSloRequest = useRequest(createSlo);
  const editSLORequest = useRequest(editSlo);
  const requestsGrafanaAlertManager = useRequest(getGrafanaAlertManager);

  const dryRunSLO = ({
    activeDetection,
    alertsCreateState,
    alertsCreateAPMState,
    latencyThreshold,
    objective,
    queryTemplates,
  }: {
    activeDetection: string;
    alertsCreateState: SLOFormProps;
    alertsCreateAPMState: any;
    latencyThreshold: number;
    isEditMode: boolean;
    objective: number;
    queryTemplates: any;
  }) => {
    const matcher = buildPromqlLabelMatcher({
      series: alertsCreateAPMState.apmAlertDetails.additionalLabels,
      seriesIndex: -1,
      includesAll: true,
    });
    const payload = {
      name: '',
      type: startCase(activeDetection),
      latencyThreshold: latencyThreshold,
      service: {
        name: alertsCreateAPMState.apmAlertDetails.serviceName,
        hash: alertsCreateAPMState.apmAlertDetails.serviceHash,
      },
      objective,
      contactPoints: alertsCreateState.contactPoints,
      matchers: matcher,
      sliBadEventsPromQuery:
        queryTemplates[activeDetection].sliBadEventsPromQuery,
      sliTotalEventsPromQuery:
        queryTemplates[activeDetection].sliTotalEventsPromQuery,
    };
    setDryRunPromQls(payload);
  };

  const createSLO = ({
    activeDetection,
    alertsCreateState,
    alertsCreateAPMState,
    latencyThreshold,
    isEditMode,
    objective,
    createSLOState,
    initialSloEditState,
  }: {
    activeDetection: string;
    alertsCreateState: SLOFormProps;
    alertsCreateAPMState: any;
    latencyThreshold: number;
    isEditMode: boolean;
    objective: number;
    createSLOState: any;
    initialSloEditState: any;
  }) => {
    const sloName =
      createSLOState?.getFormValue('sloName') ||
      `${alertsCreateAPMState.apmAlertDetails.serviceName} SLO`;

    const description = createSLOState?.getFormValue('sloDescription') || '';

    const matcher = buildPromqlLabelMatcher({
      series: alertsCreateAPMState.apmAlertDetails.additionalLabels,
      seriesIndex: -1,
      includesAll: true,
    });

    const payload = {
      name: sloName,
      description,
      type: startCase(activeDetection),
      latencyThreshold: latencyThreshold,
      service: {
        name: alertsCreateAPMState.apmAlertDetails.serviceName,
        hash: alertsCreateAPMState.apmAlertDetails.serviceHash,
      },
      objective,
      contactPoints: alertsCreateState.contactPoints,
      matcher,
    };

    if (isEditMode) {
      editSLORequest
        .call({
          ...payload,
          sloId: initialSloEditState.id,
        })
        .then((response: any) => {
          if (response) {
            addToast({
              text: `SLO updated successfully`,
              status: 'success',
            });
            navigate('/apm/slo');
          } else {
            addToast({ text: 'Failed to update SLO', status: 'error' });
          }
        });
    } else {
      createSloRequest.call(payload).then((response: any) => {
        if (response) {
          addToast({
            text: `SLO ${isEditMode ? 'updated' : 'created'} successfully`,
            status: 'success',
          });
          navigate('/apm/slo');
        } else {
          addToast({ text: 'Failed to create SLO', status: 'error' });
        }
      });
    }
  };

  return {
    control,
    createSLO,
    createSloRequest,
    dryRunSLO,
    dryRunPromQls,
    date,
    handleSubmit,
    isEditing,
    requestsGrafanaAlertManager,
    registerSLOInput: register,
    setDate,
    sloCountThresold,
    sloFormError: errors,
    sloResult,
    sloType,
    setIsEditing,
    setFormValue: setValue,
    getFormValue: getValues,
    setSloCountThresold,
    setSloType,
  };
};

export default useCreateSLOState;
